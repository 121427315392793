// @ts-nocheck
import { getActualFilterDate } from "../../../helpers/savedFilterDates/saved.date.formulas";
import humanDateFormat from "../../../helpers/humanDateFormat";

export const setDateFilters = (userFilter, dataSource) => {
  // createdAt
  const createdAtFilter = getActualFilterDate(
    userFilter.createdAt,
    userFilter.createdAtKDMD,
    userFilter.createdAtKDDP,
    userFilter.createdAtKDDZ
  );
  dataSource.setFilter("createdAt", createdAtFilter || userFilter.createdAt);

  // updatedAt
  const updatedAtFilter = getActualFilterDate(
    userFilter.updatedAt,
    userFilter.updatedAtKDMD,
    userFilter.updatedAtKDDP,
    userFilter.updatedAtKDDZ
  );
  dataSource.setFilter("updatedAt", updatedAtFilter || userFilter.updatedAt);

  // dateForce
  const dateForceFilter = getActualFilterDate(
    userFilter.dateForce,
    userFilter.dateForceKDMD,
    userFilter.dateForceKDDP,
    userFilter.dateForceKDDZ
  );
  dataSource.setFilter("dateForce", dateForceFilter || userFilter.dateForce);

  // dataSource.load();
};

export const setDefaultFilters = ({ dataSource, userId }) => {
  const storageData = localStorage.getItem("executiveDocsFilter");
  let userFilter = storageData && userId && JSON.parse(storageData)?.[userId];
  if (!userFilter || !Object.values(userFilter)) return;

  dataSource.setFilter("statusId", userFilter.statusId);
  dataSource.setFilter("execMyRole", userFilter.execMyRole);
  dataSource.setFilter(
    "collectorIssuanceType",
    userFilter.collectorIssuanceType
  );
  dataSource.setFilter("courtId", userFilter.courtId);
  dataSource.setFilter("paymentInfoSumm", userFilter.paymentInfoSumm);

  const changeFilterDate = userFilter.changeFilterDate;
  const currentDate = humanDateFormat(Date.now(), { format: "YYYY-MM-DD" });

  if (
    changeFilterDate?.split(" ")?.[0] &&
    changeFilterDate?.split(" ")?.[0] === currentDate?.split(" ")?.[0]
  ) {
    dataSource.setFilter("createdAt", userFilter.createdAt);
    dataSource.setFilter("dateForce", userFilter.dateForce);
    dataSource.setFilter("updatedAt", userFilter.updatedAt);
  } else {
    setDateFilters(userFilter, dataSource);
  }

  dataSource.setValue("withSearch", true);
  dataSource.setSearch(userFilter.searchValue);

  if (userFilter.sort) {
    dataSource.sort = JSON.parse(userFilter.sort);
  }

  // dataSource.load().then(() => {
  //   setTimeout(() => {
  //     setState({
  //       blockScreen: false,
  //       subLoading: false,
  //       loading: false,
  //     });
  //   });
  // });
};
