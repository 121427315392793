import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import styles from "../../../variables/styles/claimList";
import { Button } from "../../../components";
import { withStyles, Toolbar, Icon } from "@material-ui/core";
import DeleteClaimDialog from "../../../components/DeleteClaimDialog";
import { history } from "../../../store";
import { deleteClaim } from "../../../actions/claim";
import StandartPageLayout from "../../../components/StandartPageLayout";
import { CASE_WIKI_URL } from "../../../variables/wikiUrls";
import Filters from "./Filters";
import CaseListLayout from "./CaseListLayout";

const addPermissions = (list, permissionList, userId) =>
  Array.isArray(list)
    ? list.map((item) => {
        const owners = Array.isArray(permissionList)
          ? permissionList.filter((share) => share.allowedBy !== userId)
          : [];
        const ownerData = Array.isArray(owners)
          ? owners.find(
              (share) =>
                share.allowedBy === item.owner && share.userId === userId
            )
          : [];
        const isOwner = item.owner === userId;
        const haveEditPermission =
          (isOwner || (ownerData && ownerData.allowEdit === 1)) &&
          item.state === 1;
        const haveCommitPermission =
          isOwner || (ownerData && ownerData.allowCommit === 1);
        const haveDeletePermission =
          haveCommitPermission &&
          [-1, 1, 2, 5, 6, 9].some((i) => i === item.state);
        return {
          ...item,
          haveEditPermission,
          isOwner,
          ownerData,
          haveDeletePermission,
          haveCommitPermission,
        };
      })
    : [];

class MyDocLayout extends Component {
  state = { loading: false, checked: [], openDeleteClaimDialog: false };

  toggleDeleteDialog = () =>
    this.setState({
      openDeleteClaimDialog: !this.state.openDeleteClaimDialog,
    });

  handleClaimDelete = () => {
    const { dataSource } = this.props;
    const { checked } = this.state;
    this.setState({ loading: true });

    Promise.all(checked.map(deleteClaim)).then(() =>
      dataSource.load().then(() =>
        this.setState({
          loading: false,
          openDeleteClaimDialog: false,
          checked: [],
        })
      )
    );
  };

  load = (id) => {
    const { dataSource, docFilter, checked } = this.props;
    dataSource.setFilter(docFilter, id || checked);
    this.setState({ loading: true }, () =>
      dataSource.load().then(() => this.setState({ loading: false }))
    );
  };

  redirectToClaim =
    (
      id,
      {
        state,
        digitalDocumentStaticFile,
        haveEditPermission,
        haveCommitPermission,
      }
    ) =>
    () => {
      if (haveCommitPermission && (state === 2 || state === 18)) {
        history.push(`/cases_claims/${id}`);
      } else if (
        haveEditPermission &&
        !digitalDocumentStaticFile &&
        state <= 1
      ) {
        history.push(`/cases_claims/${id}`);
      } else if (digitalDocumentStaticFile || state > 1) {
        history.push(`/document/${id}`);
      }
    };

  onCheckItem = (id) => () => {
    const { checked } = this.state;
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
      return this.setState({ checked });
    }
    this.setState({ checked: [...checked, id] });
    return false;
  };

  onSelectAllClick = () => {
    const { list, owners, userId } = this.props;
    const listWithPermission = addPermissions(list, owners, userId);
    const { checked } = this.state;
    if (!checked.length && !!listWithPermission.length) {
      return this.setState({
        checked: listWithPermission
          .filter((claim) => claim.haveEditPermission)
          .map((item) => item.id),
      });
    }

    return this.setState({ checked: [] });
  };

  createSortHandler = (property) => () => {
    const { dataSource } = this.props;
    this.setState({ loading: true });
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }
    dataSource.sort = { [property]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    this.load();
  };

  pagination = (e, page) => {
    const { dataSource } = this.props;
    this.setState({ loading: true });
    const start = page * dataSource.count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    this.load();
  };

  changeCount = ({ target: { value } }) => {
    const { dataSource } = this.props;
    this.setState({ loading: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    this.load();
  };

  render() {
    const {
      t,
      classes,
      list,
      courts,
      statuses,
      memberRoles,
      userId,
      setId,
      courtTypes,
      justiceTypes,
      owners,
      title,
      dataSource,
      docDisplay,
      templates,
      globalCourts,
    } = this.props;

    const { loading, checked, openDeleteClaimDialog } = this.state;
    const listWithPermission = addPermissions(list, owners, userId);
    return (
      <StandartPageLayout
        loading={loading || !list}
        list={list}
        dataSource={dataSource}
        cardTitle={t("PROC_DOC")}
        cardSubtitle={title}
        setId={setId}
        wikiUrl={CASE_WIKI_URL}
        toolbar={
          <Toolbar
            className={classes.toolbar}
            disableGutters={true}
            id={setId("toolbar")}
          >
            <Button
              color="transparent"
              disabled={!checked.length}
              onClick={this.toggleDeleteDialog}
              className={!checked.length ? classes.button_disabled : ""}
              setId={(elmentName) => setId(`delete-${elmentName}`)}
              id={setId("delete-button")}
            >
              <Icon>delete</Icon>
              {`${t("DELETE")}  ${
                checked.length > 1 ? `(${checked.length})` : ""
              }`}
            </Button>
            <Filters
              dataSource={dataSource}
              setId={setId}
              statuses={statuses}
              loadData={this.load}
              display={docDisplay}
              courtTypes={courtTypes}
              justiceTypes={justiceTypes}
              courts={courts}
            />
          </Toolbar>
        }
        dialogs={
          <DeleteClaimDialog
            checked={checked}
            t={t}
            openDeleteClaimDialog={openDeleteClaimDialog}
            classes={classes}
            busy={false}
            toggleDeleteDialog={this.toggleDeleteDialog}
            handleClaimDelete={this.handleClaimDelete}
            trash={false}
            setId={(elmentName) => setId(`delete-dialog-${elmentName}`)}
          />
        }
      >
        <CaseListLayout
          classes={classes}
          dataSource={dataSource}
          t={t}
          list={listWithPermission}
          memberRoles={memberRoles}
          userId={userId}
          onCheckItem={this.redirectToClaim}
          createSortHandler={this.createSortHandler}
          display={docDisplay}
          onCheckboxClick={this.onCheckItem}
          setId={setId}
          pagination={this.pagination}
          changeCount={this.changeCount}
          checked={checked}
          onSelectAllClick={this.onSelectAllClick}
          templates={templates}
          page={"myDoc"}
          togglePermissionDialog={() => null}
          globalCourts={globalCourts}
          showCreateButton={false}
        />
      </StandartPageLayout>
    );
  }
}

MyDocLayout.propTypes = {
  userId: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  list: PropTypes.array,
  courts: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  memberRoles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  courtTypes: PropTypes.array,
  justiceTypes: PropTypes.array.isRequired,
  checked: PropTypes.string,
  owners: PropTypes.array.isRequired,
  title: PropTypes.string,
  dataSource: PropTypes.object.isRequired,
  docFilter: PropTypes.string.isRequired,
  docDisplay: PropTypes.string.isRequired,
  templates: PropTypes.array,
  globalCourts: PropTypes.array,
};

MyDocLayout.defaultProps = {
  courtTypes: [],
  list: null,
  checked: "",
  title: "",
  templates: [],
  globalCourts: [],
};

const styled = withStyles(styles)(MyDocLayout);
const translated = translate("CaseList")(styled);

export default translated;
