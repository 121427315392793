import React from "react";
import { connect } from "react-redux";
import { withStyles, createStyles, WithStyles } from "@material-ui/core/styles";
// import { withStyles } from "@material-ui/core";
import setComponentsId from "../../helpers/setComponentsId";
import VideoManual from "../videoManual/VideoManual";
import videoUrls from "../../variables/videoUrls";
import StatusChip from "../StatusChip";
import { ExecuteStatusChip } from "..";
import ReturnButton from "../ReturnButton";
import rawStyles from "../../variables/styles/pageCartTitle";
// import { CLAIM_WIKI_URL } from "../../variables/wikiUrls";
// import { IconButton, Icon } from "@material-ui/core";
const styles = createStyles(rawStyles as any);

export interface PageCardTitleProps extends WithStyles<typeof styles> {
  setId: (id: string) => string;
  status?: string | number;
  tableChip?: boolean;
  text?: React.ReactNode;
  statuses?: any;
  stepName?: string;
  statusText?: string;
  pdf?: string | any;
  videourl?: string;
  wikiUrl?: string;
  catStatuses?: any;
  catState?: number;
  documentStateInfo?: string;
  placement?: string;
  claim?: any;
  t: (key: string, params?: any[]) => string;
  noSpace?: boolean;
  noBackArrow?: boolean;
  loactionPath?: string;
  closeDialog?: () => string;
  history?: any;
  redirectTo?: any;
}

const PageCardTitle: React.FC<PageCardTitleProps> = (props) => {
  const {
    classes,
    tableChip = false,
    status = "",
    setId = setComponentsId("claim-status"),
    text = "",
    statuses = {},
    stepName = "",
    statusText = "",
    pdf = null,
    videourl = "",
    wikiUrl = "",
    catStatuses = null,
    catState = 0,
    documentStateInfo = "",
    placement = "bottom",
    claim = null,
    noSpace = false,
    t,
    noBackArrow = false,
    // loactionPath,
  } = props;
  const currentStatuses = catStatuses || statuses;

  const chipProps = {
    catState,
    status,
    classes,
    tableChip,
    statusText,
    t,
    setId,
    documentStateInfo,
    statuses,
    catStatuses,
    placement,
    noBackArrow,
  };

  if (tableChip && status) {
    return <StatusChip {...chipProps} />;
  }
  let urlName = status && status !== 1 ? currentStatuses[status] : stepName;
  if (pdf) urlName = "READY_TO_SEND_STATUS";
  const videoUrl =
    urlName === "READY_TO_SEND_STATUS"
      ? (videoUrls as any)[urlName]
      : videourl || (videoUrls as any)[urlName];

  return (
    <div className={classes.claimStatus} id={setId("wrap")}>
      {/* <div style={{ visibility: !noBackArrow ? "visible" : "hidden" }}> */}
      <ReturnButton t={t} />
      {/* </div> */}
      <span id={setId("chip-wrap")} className={classes.claimTitleTextWrapper}>
        {noSpace ? <>{text}</> : <>{text}&nbsp;</>}
        {((status && !claim) || (claim && claim.category !== 4)) && (
          <StatusChip {...chipProps} />
        )}
        {claim && claim.category === 4 && (
          <ExecuteStatusChip setId={setId} eddStatuses={claim.eddStatuses} />
        )}
      </span>
      {/* {loactionPath === "case" && stepName !== "caseList" && (
        <IconButton onClick={() => {}} style={{ color: "#fff" }}>
          <Icon>close</Icon>
        </IconButton>
      )} */}
      <VideoManual videoUrl={videoUrl} wikiUrl={wikiUrl} setId={setId} />
    </div>
  );
};

const styled = withStyles(styles)(PageCardTitle);

function mapStateToProps({ claim }: any) {
  return claim;
}

// decorate and export
export default connect(mapStateToProps)(styled);
