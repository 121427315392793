import React from "react";
import { useSelector } from "react-redux";
import {
  IReturnForms,
  IReturnObject,
  IReturnObjectProps,
  RETURN_OBJECT,
} from "./types";

type Props = {
  returnObject: IReturnObjectProps;
};

function ReturnAndLocateInTableHOC<T extends Props = Props>(
  WrappedComponent: React.ComponentType<T> | React.FC<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  const getAllReturnObjects = () => {
    let lsItem = sessionStorage.getItem(RETURN_OBJECT);
    try {
      return lsItem ? (JSON.parse(lsItem) as IReturnObject[]) : [];
    } catch {
      return [];
    }
  };

  const getReturnObject = (): IReturnObject | null => {
    let result = getAllReturnObjects();
    return result[result.length - 1] || null;
  };

  const setReturnObject = (data: IReturnObject) => {
    try {
      sessionStorage.setItem(RETURN_OBJECT, JSON.stringify([data]));
    } catch {}
  };

  const appendReturnObject = (data: IReturnObject) => {
    let result = getAllReturnObjects();
    result.push(data);
    try {
      sessionStorage.setItem(RETURN_OBJECT, JSON.stringify(result));
    } catch {}
  };

  const removeReturnObject = () => {
    sessionStorage.removeItem("return_object");
  };

  const popReturnObject = () => {
    let array = getAllReturnObjects();
    const obj = array.pop();
    try {
      sessionStorage.setItem(RETURN_OBJECT, JSON.stringify(array));
    } catch {}

    return obj || null;
  };

  const hasReturnObject = () => {
    return !!getReturnObject();
  };

  const isForm = (form: IReturnForms) =>
    hasReturnObject() && getReturnObject()!.form === form;

  const returnObject: IReturnObjectProps = {
    getReturnObject,
    setReturnObject,
    popReturnObject,
    removeReturnObject,
    hasReturnObject,
    appendReturnObject,
    isForm,
  };

  const ComponentReturnAndLocateInTable = (props: Omit<T, keyof Props>) => {
    // Отримуємо роль із Redux store
    const role = useSelector(
      (state: any) =>
        state.authorization?.roleInfo?.code || state.authorization?.role
    );

    // Оновлюємо props
    const updatedProps = {
      ...props,
      role: role,
    };

    return (
      <WrappedComponent
        {...(updatedProps as unknown as T)}
        returnObject={returnObject}
      />
    );
  };

  ComponentReturnAndLocateInTable.displayName = `ReturnAndLocateInTableHOC(${displayName})`;

  return ComponentReturnAndLocateInTable;
}

export default ReturnAndLocateInTableHOC;
