import store from "../store";

const REQUEST_PERMISSIONS_SUCCESS = "REQUEST_PERMISSIONS_SUCCESS";
const SET_LOADING_PERMISSIONS = "SET_LOADING_PERMISSIONS";

const initialState = {
  listWithCasesShares: null,
  list: null,
  meta: null,
  itemShareList: null,
  loadingPermissions: false,
};

const getChildren = (list, item, userId, parent = { children: [] }) => {
  const foundChild = list.filter((share) => share.parentId === item.id);
  const chainOfResharings = (item.chainOfResharings || []).sort((a, b) =>
    a.id < b.id ? -1 : 1
  );
  item.children = foundChild;
  foundChild.forEach((share) => {
    ({ parent: item } = getChildren(list, share, userId, item));
  });
  parent.children = parent.children.concat(item.children);
  item.isOwner = item.haveDeletePermission || userId === item.createdBy;
  item.isAlloweder = userId === item.allowedBy;
  item.chainOfResharings = chainOfResharings;
  item.reSharePermission = item.allowShare === 1 && item.userId === userId;
  item.addClaimPermission = item.allowCommit === 1 && item.userId === userId;
  item.childCount = item.children.length;
  parent.childCount = parent.children.length;
  item.parent = parent;
  item.cancelDate = !item.isActive ? item.updatedAt : null;
  return { item, parent };
};

const addChild = (list, parentList, userId) =>
  parentList.map((item) => {
    item.children = [];
    ({ item } = getChildren(list, item, userId));
    return item;
  });

const structureAnArr = (list) => {
  const { userId } = store.getState().authorization.info || {};
  const filteredList = list;
  // const filteredList = list.filter(item => !item.targetId);
  const itemShareList = list.filter((item) => !!item.targetId);
  const listWithCasesShares = list.filter(
    (item) => !item.targetId || item.targetType > 0
  );
  // const mainListWithCasesShares = listWithCasesShares.filter(item =>
  //     (item.userId === userId || item.createdBy === userId));
  // const mainList = filteredList.filter(item =>
  //     (item.userId === userId || item.createdBy === userId));
  // return {
  //     list: addChild(filteredList, mainList, userId),
  //     listWithCasesShares: addChild(listWithCasesShares, mainListWithCasesShares, userId),
  //     itemShareList
  // };
  return {
    list: addChild(filteredList, filteredList, userId),
    listWithCasesShares: addChild(
      listWithCasesShares,
      listWithCasesShares,
      userId
    ),
    itemShareList,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PERMISSIONS_SUCCESS:
    case "DATA_SOURCE/REQUEST_PERMISSIONS_SUCCESS":
    case "DATA_SOURCE/REQUEST_API_PERMISSIONS_SUCCESS":
      // console.log("DATA_SOURCE/REQUEST_API_PERMISSIONS_SUCCESS action.payload: ", action.payload);
      let meta = action.payload.meta;
      let newState = {};
      if (!Array.isArray(action.payload)) {
        let data = action.payload;
        delete data.meta;
        data = Object.entries(data);
        newState = {
          ...state,
          list: data,
          meta: meta,
          loadingPermissions: false,
        };
      } else {
        newState = {
          ...state,
          list: action.payload,
          ...structureAnArr([...action.payload]),
          meta: meta,
          loadingPermissions: false,
        };
      }
      return newState;

    case "REQUEST_PERMISSIONS_FALSE":
    case "REQUEST_PERMISSIONS_CHILDREN":
      return { ...state, permissionsChildren: action.payload };
    case "DATA_SOURCE/REQUEST_PERMISSIONS_FALSE":
    case "DATA_SOURCE/REQUEST_API_PERMISSIONS_FALSE":
      return { ...state, loadingPermissions: false };
    case SET_LOADING_PERMISSIONS:
      return { ...state, loadingPermissions: true };
    default:
      return state;
  }
};
