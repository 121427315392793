import React, { Component } from "react";
import PropTypes from "prop-types";
import { history } from "../../store";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import styles from "../../variables/styles/claimList";
import { Table, Button } from "../../components";
import StandartPageLayout from "../../components/StandartPageLayout";
import { requestUserMeta, addUserMeta } from "../../actions/usermeta";
import FavoritesToolbar from "./components/FavoritesToolbar";
import FavoritesDialog from "./components/FavoritesDialog";
import { historyPush } from "../../helpers/history";

const fields = {
  checkbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
  tableFields: [
    {
      key: "name",
      sort: false,
      title: "DOCUMENT_NAME",
      classNames: ["cell", "longCell"],
      grid: [2, 7, 1, 2],
    },
  ],
  selectAllCheckbox: {
    classNames: ["cell", "verticalAlign", "checkboxCell"],
    grid: [1, 2, 1, 2],
  },
};

class Favorites extends Component {
  state = {
    loading: false,
    checked: [],
    error: "",
    errorCase: null,
  };

  componentDidMount = async () => {
    const {
      location: { state },
    } = this.props;

    if (state?.message) {
      this.setState({
        error: state?.message,
        errorCase: state?.item,
        loading: false,
      });
    }
  };

  deleteFromFavorite = (checked) => {
    this.setState({ loading: true });
    const { list, userMetaData } = this.props;
    checked.forEach((id) => {
      const foundIndex = list.findIndex((item) => id === item.id);
      list.splice(foundIndex, 1);
    });

    const meta = {
      ...userMetaData.meta,
      list,
    };

    addUserMeta({ meta }).then(() =>
      requestUserMeta().then(() => this.setState({ loading: false }))
    );
  };

  deleteCheckedFromFavorite = () => {
    this.deleteFromFavorite(this.state.checked);
    this.setState({ checked: [] });
  };

  deleteErrorFromFavorite = () =>
    this.setState({ error: "" }, () =>
      this.deleteFromFavorite([this.state.errorCase])
    );

  closeDialog = () => this.setState({ error: "", errorCase: null });

  onCheckItem = (item) => () => {
    const { link } = item;
    const { history } = this.props;
    historyPush(history, link, { item });
  };

  onCheckboxClick = (id) => () => {
    const { checked } = this.state;
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
    } else {
      checked.push(id);
    }
    this.setState({ checked });
  };

  onSelectAllClick = () => {
    const { checked } = this.state;
    this.setState({
      checked: checked.length > 0 ? [] : this.props.list.map(({ id }) => id),
    });
  };

  redirect = (link) => () => history.push(link);

  render() {
    const { classes, setId, list, t, userMetaData } = this.props;
    const { loading, checked, error } = this.state;
    const getText = (item, key) => item[key];
    return (
      <StandartPageLayout
        list={list}
        blockScreen={loading || !userMetaData}
        emptyPageTitle={t("FAVORITES_EMPTY_PAGE_TITLE")}
        emptyPageDescription={t("FAVORITES_EMPTY_PAGE_DESCRIPTION")}
        cardTitle={t("FAVORITES")}
        stepName={"favorites"}
        setId={setId}
        toolbar={
          <FavoritesToolbar
            classes={classes}
            t={t}
            checked={checked}
            setId={setId}
            deleteFromFavorite={this.deleteCheckedFromFavorite}
          />
        }
        createButton={
          <Button
            onClick={this.redirect("/cases")}
            color="yellow"
            variant="contained"
          >
            {t("CASES_LINK")}
          </Button>
        }
      >
        <Table
          fields={fields}
          checked={checked}
          getText={getText}
          setId={setId}
          onCheckItem={this.onCheckItem}
          onCheckboxClick={this.onCheckboxClick}
          onSelectAllClick={this.onSelectAllClick}
          list={list}
          t={t}
          needFullData={true}
        />
        <FavoritesDialog
          t={t}
          error={error}
          closeDialog={this.closeDialog}
          deleteErrorFromFavorite={this.deleteErrorFromFavorite}
          classes={classes}
          setId={setId}
        />
      </StandartPageLayout>
    );
  }
}

const styled = withStyles(styles)(Favorites);
const translated = translate("CaseList")(styled);

Favorites.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userMetaData: PropTypes.object,
};

Favorites.defaultProps = {
  userMetaData: null,
  list: null,
};

// decorate and export
export default connect(({ usermeta: { userMetaData } }) => {
  let list = null;
  if (userMetaData) {
    if (userMetaData.meta && userMetaData.meta.favorites) {
      ({
        meta: { favorites: list },
      } = userMetaData);
    } else {
      list = [];
    }
  }
  return { list, userMetaData };
})(translated);
