import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { Typography } from "@material-ui/core";
import StandartPageLayout from "../../../components/StandartPageLayout";
import { CASE_WIKI_URL } from "../../../variables/wikiUrls";
import CaseListLayout from "./CaseListLayout";
import CaseDialogs from "./CaseDialogs";
import DocumentsView from "./DocumentsView";
import CaseToolbar from "./CaseToolbar";

const CaseMasterLayout = (props) => {
  const {
    t,
    classes,
    role,

    list,
    memberRoles,
    userId,
    error,
    hideError,
    checked,
    display,
    addToFavorites,
    deleteFromFavorite,
    viewDocument,
    handleDownload,
    scanDocumentId,
    setId,

    dataSource,
    createSortHandler,
    onCheckboxClick,
    toggleCaseDialog,
    onViewAttaches,
    courtTypes,

    pagination,
    changeCount,
    backToPrevDisplay,
    loadData,
    caseNotProcDoc,
    justiceTypes,
    toggleMarkDialog,
    togglePermissionDialog,
    blockScreen,
    closeScanDocument,
    page,
    title,
    subTitle,
    toggleDialog,
    changedShare,
    sharesChecked,
    statuses,
    procStatuses,
    permissions,
    favorites,

    courtsList,
    showCreateButton,
    currentShares,
    enableShareButton,
    showMyDocList,
    targetType,
    targetId,
    filterOwners,
    foundInMembers,
    currentChecked,
    currentList,
    onCheck,
    handleCheckboxClick,
    loading,
    init,
    history,
    activeProc,
    activeCase,
    procChecked,
    noShowAttachPreview,
  } = props;
  const favoriteIsChecked = !!favorites.find(({ id }) => checked === id);
  const currentStatuses = display === "caseList" ? statuses : procStatuses;
  const sortStatuses = Array.isArray(currentStatuses)
    ? currentStatuses.sort((status, nextStatus) =>
        status.name.toLowerCase() < nextStatus.name.toLowerCase() ? -1 : 1
      )
    : [];
  const docList = list || currentList || [];

  const toolbar = (
    <CaseToolbar
      classes={classes}
      dataSource={dataSource}
      t={t}
      checked={checked}
      toggleCaseDialog={toggleCaseDialog}
      setId={setId}
      display={display}
      backToPrevDisplay={backToPrevDisplay}
      statuses={sortStatuses}
      loadData={loadData}
      courtTypes={courtTypes}
      justiceTypes={justiceTypes}
      courts={courtsList}
      toggleMarkDialog={toggleMarkDialog}
      togglePermissionDialog={togglePermissionDialog}
      addToFavorites={addToFavorites}
      favoriteIsChecked={favoriteIsChecked}
      deleteFromFavorite={deleteFromFavorite}
      page={page}
      showCreateButton={showCreateButton}
      toggleDialog={toggleDialog}
      currentShares={currentShares}
      enableShareButton={enableShareButton}
      history={history}
      activeProc={activeProc}
      activeCase={activeCase}
      procChecked={procChecked}
    />
  );

  return (
    <StandartPageLayout
      loading={init || (loading && !docList.length)}
      list={list || currentList}
      blockScreen={blockScreen || loading}
      dataSource={dataSource}
      cardTitle={title}
      cardSubtitle={subTitle}
      stepName={display}
      setId={setId}
      error={error}
      hideError={hideError}
      isLastComponent={!showMyDocList}
      onlyDialog={display === "signing"}
      toolbar={display === "signing" ? "" : toolbar}
      wikiUrl={CASE_WIKI_URL}
      loactionPath={"case"}
      dialogs={
        <CaseDialogs
          {...props}
          currentList={list || currentList}
          changedShare={changedShare}
          sharesChecked={sharesChecked}
          targetType={targetType}
          targetId={targetId}
          showCreateButton={showCreateButton}
          filterOwners={filterOwners}
          currentShares={currentShares}
          foundInMembers={foundInMembers}
        />
      }
    >
      {display !== "documentView" && (
        <div className={classes.listWithSplitPaneWrap}>
          <CaseListLayout
            role={role}
            history={history}
            classes={classes}
            dataSource={dataSource}
            onViewAttaches={onViewAttaches}
            scanDocumentId={scanDocumentId}
            closeScanDocument={closeScanDocument}
            t={t}
            checked={currentChecked}
            list={currentList}
            statuses={sortStatuses}
            memberRoles={memberRoles}
            userId={userId}
            onCheckItem={onCheck}
            createSortHandler={createSortHandler}
            display={display}
            onCheckboxClick={handleCheckboxClick}
            setId={setId}
            pagination={pagination}
            permissions={permissions}
            changeCount={changeCount}
            favorites={favorites}
            handleDownload={handleDownload}
            viewDocument={viewDocument}
            page={page}
            togglePermissionDialog={togglePermissionDialog}
            activeProc={activeProc}
            showCreateButton={showCreateButton}
            noShowAttachPreview={noShowAttachPreview}
          />
          {display === "procList" && !!caseNotProcDoc.length && (
            <div className={classes.groupWrap} id={setId("not-proc-doc")}>
              <Typography component="h2" id={setId("not-proc-doc-title")}>
                {t("NOT_PROC_DOC")}
              </Typography>
              <CaseListLayout
                classes={classes}
                dataSource={dataSource}
                t={t}
                checked={checked}
                list={caseNotProcDoc}
                statuses={sortStatuses}
                memberRoles={memberRoles}
                userId={userId}
                onCheckItem={onCheck}
                createSortHandler={createSortHandler}
                display={"caseNotProcDoc"}
                onCheckboxClick={onCheckboxClick}
                setId={(elmentsName) => setId(`caseNotProcDoc-${elmentsName}`)}
                pagination={pagination}
                changeCount={changeCount}
                favorites={favorites}
                page={page}
                activeProc={activeProc}
                showCreateButton={showCreateButton}
                noShowAttachPreview={noShowAttachPreview}
              />
            </div>
          )}
        </div>
      )}
      {viewDocument && display === "documentView" && (
        <DocumentsView
          data={viewDocument}
          classes={classes}
          setId={setId}
          handleDownload={handleDownload}
          noShowAttachPreview={noShowAttachPreview}
          page={page}
          togglePermissionDialog={togglePermissionDialog}
          activeProc={activeProc || {}}
          showCreateButton={showCreateButton}
          permissions={permissions}
        />
      )}
    </StandartPageLayout>
  );
};

CaseMasterLayout.propTypes = {
  userId: PropTypes.string.isRequired,
  error: PropTypes.string,
  hideError: PropTypes.func,
  checked: PropTypes.string,
  display: PropTypes.string.isRequired,
  viewDocument: PropTypes.object,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  blockScreen: PropTypes.bool.isRequired,

  createSortHandler: PropTypes.func.isRequired,
  onCheckboxClick: PropTypes.func.isRequired,
  toggleCaseDialog: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,

  pagination: PropTypes.func.isRequired,
  changeCount: PropTypes.func.isRequired,
  backToPrevDisplay: PropTypes.func.isRequired,
  procStatuses: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  caseNotProcDoc: PropTypes.array.isRequired,

  list: PropTypes.array,
  courts: PropTypes.array.isRequired,
  statuses: PropTypes.array.isRequired,
  memberRoles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  courtTypes: PropTypes.array,
  justiceTypes: PropTypes.array.isRequired,
  toggleMarkDialog: PropTypes.func.isRequired,
  userMetaData: PropTypes.object,
  togglePermissionDialog: PropTypes.func.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  deleteFromFavorite: PropTypes.func.isRequired,
  scanDocumentId: PropTypes.string,
  onViewAttaches: PropTypes.func.isRequired,
  closeScanDocument: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  changedShare: PropTypes.object,
  sharesChecked: PropTypes.array.isRequired,
  courtsList: PropTypes.array,
  showCreateButton: PropTypes.bool.isRequired,
  currentShares: PropTypes.array.isRequired,
  enableShareButton: PropTypes.bool.isRequired,
  showMyDocList: PropTypes.bool.isRequired,
  targetType: PropTypes.number.isRequired,
  targetId: PropTypes.string.isRequired,
  filterOwners: PropTypes.array.isRequired,
  foundInMembers: PropTypes.object,
  currentChecked: PropTypes.string.isRequired,
  currentList: PropTypes.array.isRequired,
  onCheck: PropTypes.func.isRequired,
  handleCheckboxClick: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  init: PropTypes.bool.isRequired,
  history: PropTypes.array.isRequired,
  activeProc: PropTypes.object,
};

CaseMasterLayout.defaultProps = {
  courtsList: [],
  error: "",
  checked: "",
  viewDocument: null,
  foundInMembers: null,
  courtTypes: [],
  userMetaData: {},
  scanDocumentId: "",
  list: null,
  changedShare: null,
  loading: false,
  activeProc: null,
};

export default withRouter(CaseMasterLayout);
