import { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkLegalEntityAgentRole } from "../../../actions/appDialog";

import StandartPageLayout from "../../../components/StandartPageLayout";
import ShareDialog from "../../../components/ShareDialog";
import OrderDialog from "./OrderDialog";
import ShareDialogWrapper from "../../../components/ShareDialog/ShareDialogWrapper";
import ShareSubscribeDialog from "../../../components/ShareDialog/ShareSubscribeDialog";
import ShareDeleteDialog from "../../../components/ShareDialog/ShareDeleteDialog";
import { Button } from "../../../components";
import includeFieldsFromUserRole from "../../../helpers/includeFieldsFromUserRole";
import ShareToolbar from "./ShareToolbar";
import ShareTable from "./ShareTable";
import CreateButton from "./CreateButton";
import { Snackbar } from "@material-ui/core";
import CreateOrderButton from "./CreateOrderButton";
import ShareWarningDialog from "./ShareWarningDialog";
import EDRRecipeConfirmDialog from "./EDRRecipeConfirmDialog";
import EDRRecipeCreateDialog from "./EDRRecipeCreateDialog";
import CreateEDRRecipeButton from "./CreateEDRRecipeButton";

const ShareLayout = ({
  t,
  classes,
  checked,
  loading,
  subLoading,
  blockScreen,
  busy,
  toggleDialog,
  handleDelete,
  handleDelete_Edr_Recipe,
  createSortHandler,
  onCheckItem,
  handleClick,
  display,
  changedShare,
  currentUserId,
  ownerList,
  setId,
  showSignModal,
  pdf,
  doc,
  toggleSignModal,
  backToPrevDisplay,
  handleSelectKey,
  setPdf,
  error,
  title,
  signError,
  closeSnackbar,
  shareError,
  closeShareError,
  shareSubTitle,
  link,
  orderButtonVisible,
  onAddOrderClick,
  orderDialog,
  handleChangeFilter,
  showShareError,
  auth,
  list,
  meta,
  dataSource,
  pagination,
  changeCount,
  addEDRRecipeButtonVisible,
  noNeedAllCheckbox,
  handleDelete_Edr_Recipe_Without_Refresh,
  refreshAfterDeleteEdr,
  targetId,
  targetType,
  loactionPath,
  role,
}) => {
  const showOrderBtn = !!!targetId;
  const dialogs = (
    <Fragment>
      {/* Special WarningDialog for not private persons */}
      {display === "warningShare" ? (
        <ShareWarningDialog
          toggleDialog={toggleDialog}
          t={t}
          classes={classes}
          setId={setId}
          auth={auth}
        />
      ) : display === "createEDRRecipe" ? (
        <EDRRecipeConfirmDialog
          toggleDialog={toggleDialog}
          t={t}
          classes={classes}
          setId={setId}
          auth={auth}
        />
      ) : display === "confirmEDRRecipe" ? (
        <EDRRecipeConfirmDialog
          toggleDialog={toggleDialog}
          t={t}
          classes={classes}
          setId={setId}
          auth={auth}
        />
      ) : (
        display !== "order" && (
          <ShareDialogWrapper
            toggleDialog={toggleDialog}
            display={display}
            busy={busy}
            setId={setId}
          >
            <ShareDialog
              toggleDialog={toggleDialog}
              busy={busy}
              changedShare={changedShare}
              display={display}
              list={list}
              handleClick={handleClick}
              currentUserId={currentUserId}
              setId={setId}
              createSortHandler={createSortHandler}
              onCheckItem={onCheckItem}
              checked={[...checked]}
              pdf={pdf}
              doc={doc}
              setPdf={setPdf}
              error={error}
              description={
                includeFieldsFromUserRole("showCaseProcShare")
                  ? t("GLOBAL_SHARE_DESCRIPTION")
                  : ""
              }
              shareSubTitle={shareSubTitle}
              link={link}
              permissionsDataSource={dataSource}
              targetId={targetId}
              targetType={targetType}
            />
          </ShareDialogWrapper>
        )
      )}
      {/* Special window? hubi new user other style*/}
      {display === "signing" && (
        <div
          style={{
            position: "fixed", // "absolute", якщо потрібно центрування
            width: "100vw", // Зменшуємо, щоб не виходило за межі
            height: "100vh",
            background: "rgba(0, 0, 0, 0.5)",
            top: "0",
            left: "0",
            overflowY: "scroll",
            zIndex: "1",
          }}
        >
          <div
            style={{
              position: "absolute", // "absolute", якщо потрібно центрування
              top: "0", // Центруємо по вертикалі
              left: "50%", // Центруємо по горизонталі
              transform: "translate(-50%)", // Правильне центрування
              width: "90%", // Зменшуємо, щоб не виходило за межі
              background: "#fff",
            }}
          >
            <ShareSubscribeDialog
              showSignModal={showSignModal}
              pdf={pdf}
              backToPrevDisplay={backToPrevDisplay}
              toggleSignModal={toggleSignModal}
              handleSelectKey={handleSelectKey}
              doc={doc}
              setId={setId}
              loading={loading || busy}
              signError={signError}
              closeSnackbar={closeSnackbar}
              targetType={targetType}
            />
          </div>
        </div>
      )}
      {/* Create order of company representer dialog */}
      {display === "order" && (
        <OrderDialog
          t={t}
          readOnly={orderDialog.readOnly}
          defaultMeta={orderDialog.defaultMeta}
          closeDialog={orderDialog.onClose}
          createdAt={orderDialog.createdAt}
          cancelDate={orderDialog.cancelDate}
          documentId={orderDialog.documentId}
          id={orderDialog.id}
          allowCommit={1}
          setPdf={setPdf}
          showError={showShareError}
          pdf={pdf}
        />
      )}
    </Fragment>
  );
  return (
    <StandartPageLayout
      list={list}
      subLoading={subLoading}
      loading={loading}
      blockScreen={blockScreen || (busy && !display)}
      dataSource={dataSource}
      emptyPageTitle={t("SHARE_EMPTY_PAGE_TITLE")}
      emptyPageDescription={t("SHARE_EMPTY_PAGE_DESCRIPTION")}
      cardTitle={title ? t(title) : ""}
      cardSubtitle={title ? t(title + "2") : ""}
      stepName={"share"}
      loactionPath={loactionPath}
      setId={setId}
      onlyDialog={display === "signing"}
      toolbar={
        display !== "signing" && (
          <ShareToolbar
            t={t}
            classes={classes}
            checked={checked}
            busy={busy}
            toggleDialog={toggleDialog}
            setId={setId}
            orderButtonVisible={orderButtonVisible}
            onAddOrderClick={onAddOrderClick}
            dataSource={dataSource}
            handleChangeFilter={handleChangeFilter}
            auth={auth}
            addEDRRecipeButtonVisible={addEDRRecipeButtonVisible}
            changedShare={changedShare}
            showOrderBtn={showOrderBtn}
            role={role}
          />
        )
      }
      createButton={
        display !== "signing" ? (
          <>
            <CreateButton
              toggleDialog={toggleDialog}
              setId={setId}
              classes={classes}
              t={t}
            />
            {orderButtonVisible && (
              <CreateOrderButton
                classes={classes}
                t={t}
                onClick={onAddOrderClick}
                setId={setId}
              />
            )}
            {addEDRRecipeButtonVisible && (
              <CreateEDRRecipeButton
                toggleDialog={toggleDialog}
                classes={classes}
                t={t}
                setId={setId}
                disabled={!checked?.length || changedShare?.typeId !== 2}
              />
            )}
          </>
        ) : (
          ""
        )
      }
      createDialog={dialogs}
    >
      <div className={classes.scroll} id={setId("content")}>
        {display !== "signing" && list && (
          <ShareTable
            t={t}
            classes={classes}
            checked={checked}
            noNeedAllCheckbox={noNeedAllCheckbox}
            createSortHandler={createSortHandler}
            onCheckItem={onCheckItem}
            handleClick={handleClick}
            currentUserId={currentUserId}
            ownerList={ownerList}
            setId={setId}
            list={list}
            meta={meta}
            dataSource={dataSource}
            pagination={pagination}
            changeCount={changeCount}
          />
        )}
        {display !== "deleteEDRRecipe" && dialogs}
        <ShareDeleteDialog
          t={t}
          openDeleteDialog={display === "delete"}
          toggleDeleteDialog={toggleDialog("delete")}
          handleDelete={handleDelete}
          setId={setId}
        />
        <EDRRecipeConfirmDialog
          toggleDialog={toggleDialog}
          classes={classes}
          t={t}
          setId={setId}
          auth={auth}
          openDeleteDialog={display === "deleteEDRRecipe"}
          toggleDeleteDialog={toggleDialog("deleteEDRRecipe")}
          changedShare={changedShare}
          handleDelete_Edr_Recipe={handleDelete_Edr_Recipe}
        />
        <EDRRecipeCreateDialog
          toggleDialog={toggleDialog}
          classes={classes}
          t={t}
          setId={setId}
          auth={auth}
          openCreateDialog={display === "createEDRRecipe"}
          changedShare={changedShare}
          handleDelete_Edr_Recipe_Without_Refresh={
            handleDelete_Edr_Recipe_Without_Refresh
          }
          refreshAfterDeleteEdr={refreshAfterDeleteEdr}
        />
      </div>
      {shareError && (
        <Snackbar
          id={setId("error")}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={true}
          message={<span id="message-id">{shareError}</span>}
          action={[
            <Button
              key="close-error"
              color="yellow"
              size="small"
              onClick={closeShareError}
              className={classes.smallButton}
            >
              OK
            </Button>,
          ]}
        />
      )}
    </StandartPageLayout>
  );
};

ShareLayout.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  orderButtonVisible: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  orderDialog: PropTypes.shape({
    readOnly: PropTypes.bool,
    defaultMeta: PropTypes.object,
    onClose: PropTypes.func,
    createdAt: PropTypes.string,
    documentId: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  checked: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  busy: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  showShareError: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  changedShare: PropTypes.object,
  ownerList: PropTypes.any.isRequired,
  showSignModal: PropTypes.bool.isRequired,
  pdf: PropTypes.string,
  backToPrevDisplay: PropTypes.func.isRequired,
  toggleSignModal: PropTypes.func.isRequired,
  handleSelectKey: PropTypes.func.isRequired,
  doc: PropTypes.object,
  setPdf: PropTypes.func.isRequired,
  onAddOrderClick: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  shareError: PropTypes.string.isRequired,
  signError: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  closeShareError: PropTypes.func.isRequired,
  handleChangeFilter: PropTypes.func.isRequired,
  shareSubTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  targetType: PropTypes.any.isRequired,
};

ShareLayout.defaultProps = {
  changedShare: null,
  pdf: null,
  doc: null,
  list: null,
};

function mapStateToProps(state) {
  return {
    role: state?.authorization?.roleInfo?.code,
    auth: state.authorization,
  };
}

function MDTP(dispatch) {
  return {
    checkLegalEntityAgentRole: (role) =>
      dispatch(checkLegalEntityAgentRole(role)),
  };
}

// const translated = translate("Share")(ShareLayout);

// decorate and export
export default connect(mapStateToProps, MDTP)(ShareLayout);
