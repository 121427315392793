import store from "../../store";
import config from "../../config";

const LMK = 0;
const MMK = 1;

/**
 * @param {MouseEvent} e
 */
export const handleEDRSRClick = async (e) => {
  let target = "_blank";

  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
    target = "_blank";
  }

  e.preventDefault();

  const host = config().edrsrRedirectUrl;
  if (!host) return;
  const { token } = store.getState().authorization || {};
  if (!token) return;

  window.open(`${host}?token=${token}`, target);
};

/**
 * @param {MouseEvent} e
 */
export const handleEDRSRDEBUGClick = async (e) => {
  let target = "_blank";

  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
    target = "_blank";
  }

  e.preventDefault();

  const host = config().edrsrRedirectUrlDEBUG;
  if (!host) return;
  const { token } = store.getState().authorization || {};
  if (!token) return;

  window.open(`${host}?token=${token}`, target);
};

/**
 * @param {MouseEvent} e
 */
// export const handleEDRSRClick = async (e) => {
//   let target = "_blank";

//   if (e.button !== LMK && e.button !== MMK) {
//     return;
//   }

//   if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
//     target = "_blank";
//   }

//   e.preventDefault();

//   let host = config().edrsrRedirectUrl;
//   const { token } = store.getState().authorization || {};
//   if (!host || !token) return;
//   try {
//     const response = await fetch(host, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.ok) {
//       await response.json();
//       host = host.replace("/Account/Login", "");
//       window.open(host, target);
//       // try {
//       //   // await fetch(host + "/Provider/SetProvider", {
//       //   //   method: "POST",
//       //   //   headers: {
//       //   //     headers: {
//       //   //       key: responseData[0].key,
//       //   //       mes: responseData[0].mes,
//       //   //       IsAuthenticated: responseData[0].IsAuthenticated,
//       //   //       UserName: responseData[0].UserName,
//       //   //     },
//       //   //   },
//       //   // });
//       // } catch (error) {
//       //   console.error("Request error:", error);
//       // }
//     } else {
//       console.error("Failed to fetch data:", response.status);
//     }
//   } catch (error) {
//     console.error("Request error:", error);
//   }
// };

// export const handleEDRSRDEBUGClick = async (e) => {
//   let target = "_blank";

//   if (e.button !== LMK && e.button !== MMK) {
//     return;
//   }

//   if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
//     target = "_blank";
//   }

//   e.preventDefault();

//   let host = config().edrsrRedirectUrlDEBUG;
//   const { token } = store.getState().authorization || {};
//   if (!host || !token) return;
//   try {
//     const response = await fetch(host, {
//       method: "POST",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.ok) {
//       await response.json();
//       host = host.replace("/Account/Login", "");
//       window.open(host, target);
//       // try {
//       //   // await fetch(host + "/Provider/SetProvider", {
//       //   //   method: "POST",
//       //   //   headers: {
//       //   //     headers: {
//       //   //       key: responseData[0].key,
//       //   //       mes: responseData[0].mes,
//       //   //       IsAuthenticated: responseData[0].IsAuthenticated,
//       //   //       UserName: responseData[0].UserName,
//       //   //     },
//       //   //   },
//       //   // });
//       // } catch (error) {
//       //   console.error("Request error:", error);
//       // }
//     } else {
//       console.error("Failed to fetch data:", response.status);
//     }
//   } catch (error) {
//     console.error("Request error:", error);
//   }
// };
