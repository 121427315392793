export default {
  // Загальні тайтли для таблиці
  HEADER: "Виконавчі постанови",
  HEADER2: "Документи виконавчого провадження",
  DISPLAYED_CASES: "Відображати:",
  COUNT_CASES: "з {{from}} по {{to}} із {{total}}",

  // Назви колонок в таблиці Виконавчик постанов
  CREATED_AT: "Дата надходження документу до ЕС",
  DOC_NUM: "Номер постанови",
  DOC_ACCEPT_DATE: "Дата постанови",
  DOC_NAME: "Назва постанови",
  ORG_NAME: "Назва виконавця/ДВС",
  SUM_UAH: "Сума заборгованості в гривнях:",
  VD_STATEMENT_ID: "Id виконавчого документу",
  VP_ID: "Id виконавчого провадження",
  VP_NUM: "Номер виконавчого провадження",
  VP_STATE_ID: "Стан провадження",
  USER_ROLE: "Моя роль",
  EXECUTIVE_ID: "ВД",

  // Тайтли для фільтрів
  CREATED_AT_FILTER: "Дата надходження",
  DOC_ACCEPT_DATE_FILTER: "Дата постанови",
  VP_STATE_ID_FILTER: "Стан провадження",
  USER_ROLE_FILTER: "Роль",
  SUM_UAH_FILTER: "Сума заборгованості",

  // Для ролей
  DEBTOR: "Боржник",
  COLLECTOR: "Стягувач",
  PAYER: "Платник",
  REPRESENTATIVE: "Представник",

  EMPTY_FILE:
    "Документ в процесі завантаження. Спробуйте, будь ласка, пізніше.",
  NOT_FOUND_FILE: "Файл відсутній.",
  NEED_DOCUMENT_ACCESS:
    "Ви не маєте доступу до документу викоавчого провадження з поточною роллю.",

  INFO_ABOUT_DOC_CARD: "Постанова",
  DOC_ACCEPT_DATE_CARD: "Дата надходження до ЕС:",
  DOC_NUM_CANCELED_CARD: "Номер постанови, яку було скасовано:",
  UNKNOWN_STATE: "Не визначено",

  VP_TITLE_CARD: "Виконавче провадження",
  // VP_NUM: "№ в АСВП:",
  VP_STATE: "Стан:",
  VP_START_DATE: "Дата відкриття:",
  // ORG_NAME: "ДВС/Приватний виконавець:",
  EXECUTOR: "ПІБ:",
  EXECUTOR_PHONE: "Телефон виконавця:",

  VD_TITLE_CARD: "Виконавчий документ",
  VD_NUM: "№ в АСВП:",
  VD_DATE: "Від:",
  VD_STATE: "Стан:",
  VP_END_DATE: "Дата закриття:",
  VP_END_REASON: "Причина закриття ВП:",
  SUM: "Cума заборгованості:",
  SUM_CURRENCY: "Валюта боргу:",
  // SUM_UAH: "Cума заборгованості у гривні:",
  VD_CASE_NUMBER: "Номер справи:",

  CREDITOR: "Стягувач",

  NAME_LABEL: "ПІБ/назва:",
  PERSON_NAME_LABEL: "ПІБ:",
  ORG_NAME_LABEL: "Назва:",

  CODE_LABEL: "РНОКПП або паспорт/ЄДРПОУ:",
  PERSON_CODE_LABEL: "РНОКПП:",
  ORG_CODE_LABEL: "ЄДРПОУ:",

  BIRTH_DATE: "Дата народження:",
  REGISTRY_DATE: "Дата реєстрації:",

  PERSON_TYPE_NAME: "Тип особи:",
  IS_ACTIVE_LABEL: "Стан:",
  ACTIVE_STATE: "діючий",
  NOT_ACTIVE_STATE: "не діючий",
  ADDRESS_LABEL: "Адреса:",
  PHONE_LABEL: "Телефон:",
  ISSUANCE_TYPE_LABEL: "Форма видачі:",
  ELECTRONIC_FORM: "Електронна",
  PAPER_FORM: "Паперова",
  NOT_NOTICED_FORM: "Не вказано",
  ISSUED_AT_LABEL: "Дата видачі:",

  VP_NUM_LABEL: "№:",

  REDIRECT_TO_EXECUTIVE: "Перейти до ВД",
  CHECK_ECP: "Перевірка КЕП",
  PREVIEW_LINK: "Відкрити у новому вікні",
  SAVE_TO_DEVICE: "Зберегти файл та електронний підпис архівом",

  SHOW_ALL: "Показати всіх",
  HIDE_ALL: "Приховати всіх",

  CHECK_KEP_RESULT_TITLE: "Протокол перевірки підпису",
  NOT_VALID_KEP: "Некоректний КЕП.",
  ERROR: "Помилка",
};
