import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { withStyles } from "@material-ui/core";

import setComponentsId from "../../helpers/setComponentsId";
import dialogStyles from "../../variables/styles/permissionDialog";
import customInputStyle from "../../variables/styles/customInputStyle";
import PermissionsDialogLayout from "./components/PermissionsDialogLayout.jsx";
import { testFileName, normalizeFileName } from "../../helpers/testFile";
import { checkLicense } from "../../actions/advocate";
import { compose } from "redux";
import { withAdvocate } from "../withAdvocate";

const filterOwners = (owners) =>
  owners.reduce((acc, item) => {
    if (
      item.addClaimPermission &&
      !acc.find((share) => share.allowedBy === item.allowedBy)
    ) {
      acc.push(item);
    }
    return acc;
  }, []);

class PermissionsDialog extends React.Component {
  constructor(props) {
    super(props);
    const { allowAttachFile, owners } = this.props;
    this.state = {
      step: 0,
      claimentSelectMode:
        !allowAttachFile || filterOwners(owners || []).length > 0
          ? "select"
          : "upload",
      openedTooltip: "",
      error: "",
      allowAttachOrder: false,
    };
  }

  componentDidMount() {
    const {
      advocate: { certbytype, raid, certnum, certbyid },
      isAdvocate,
      allowAttachOrder,
    } = this.props;

    if (allowAttachOrder && isAdvocate) {
      checkLicense({
        ...this.props.user,
        certbytype,
        raid,
        certnum,
        certbyid,
      }).then((response) => {
        if (response && response.advocate && !response.occupation) {
          this.setState({ allowAttachOrder: true });
        }
      });
    }
  }

  toggleTooltip = (name) => () => this.setState({ openedTooltip: name });

  closeDialog = () => {
    const { closeDialog } = this.props;
    const { step } = this.state;

    if (step === 1) {
      return this.setState({ step: 0 });
    }

    return closeDialog();
  };

  toggleStep = () => {
    const {
      togglePermissionDialog,
      value: { claimant, representativeClaimant },
    } = this.props;

    const isOwner = claimant && !representativeClaimant;
    const { step } = this.state;

    if (step === 0) {
      return isOwner ? togglePermissionDialog() : this.setState({ step: 1 });
    }

    return togglePermissionDialog();
  };

  toggleMode = () => {
    const {
      onChange,
      value: { claimant, asIndividualEntrepreneur },
    } = this.props;

    onChange &&
      onChange({
        claimant: !claimant,
        representativeClaimant: !!claimant,
        claimentUserId: null,
        asIndividualEntrepreneur: !claimant && asIndividualEntrepreneur,
      });
  };

  toggleSelectMode = ({ target: { value } }) =>
    this.setState({ claimentSelectMode: value }, () => {
      const { onChange } = this.props;
      onChange &&
        onChange({
          ...this.props.value,
          claimentUserId: null,
          files: null,
          claimant: value === "select" || value === "order",
        });
    });

  toggleSelectInstance = ({ target: { value } }) => {
    const { onChange } = this.props;
    onChange &&
      onChange({
        ...this.props.value,
        toFirstInstance: value === "first",
      });
  };

  toggleIndividualEntrepreneur = ({ target: { checked } }) => {
    const { onChange } = this.props;
    onChange &&
      onChange({
        ...this.props.value,
        asIndividualEntrepreneur: checked,
      });
  };

  setOwnerId = ({ target: { value } }) => {
    const { onChange } = this.props;

    const data = {
      claimant: true,
      representativeClaimant: true,
      claimentUserId: value?.claimentUserId,
      permissionId: value?.permissionId,
    };

    onChange && onChange(data);
  };

  onDropAuthorization = (files, rejected) => {
    const { onChange, value, t } = this.props;

    if (!files.length) {
      return this.setState({ error: t("ERROR_TYPE_FILE") });
    }
    let error = "";
    const accepted = [];
    files.forEach((file) => {
      const localFileName = normalizeFileName(file.name);
      error = testFileName(localFileName, t) || "";
      if (error) {
        console.log(
          `UploadMultipleFilesDialog, error ${error} of filename: ${localFileName}`
        );
      }
      if (!error) {
        accepted.push(
          new File([file], localFileName, {
            type: file.type,
            lastModified: file.lastModified,
            lastModifiedDate: file.lastModifiedDate,
            path: file.path,
            // name: file.name
            size: file.size,
            webkitRelativePath: file.webkitRelativePath,
          })
        );
      }
    });

    rejected.forEach((file) => {
      const localFileName = normalizeFileName(file.name);
      error = testFileName(localFileName, t) || "";
      if (error) {
        console.log(
          `UploadMultipleFilesDialog, error ${error} of filename: ${localFileName}`
        );
      }
    });

    onChange &&
      onChange({
        ...value,
        files: (value.files || []).concat(accepted),
      });
    this.setState({ error });
  };

  handleDeleteFile = (index) => {
    const { onChange, value } = this.props;
    const { files } = value;
    files.splice(index, 1);
    onChange &&
      onChange({
        ...value,
        files,
      });
  };

  render() {
    return (
      <PermissionsDialogLayout
        {...this.props}
        {...this.state}
        closeOrToggleDialog={this.closeDialog}
        handleDeleteFile={this.handleDeleteFile}
        onDropAuthorization={this.onDropAuthorization}
        setOwnerId={this.setOwnerId}
        toggleIndividualEntrepreneur={this.toggleIndividualEntrepreneur}
        toggleMode={this.toggleMode}
        toggleSelectMode={this.toggleSelectMode}
        toggleSelectInstance={this.toggleSelectInstance}
        toggleTooltip={this.toggleTooltip}
        toggleStep={this.toggleStep}
      />
    );
  }
}

PermissionsDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
  togglePermissionDialog: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,

  closeDialog: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  owners: PropTypes.array,
  ownersFilter: PropTypes.func,
  open: PropTypes.bool,
  value: PropTypes.object,
  userInfo: PropTypes.object.isRequired,
  advocate: PropTypes.object,
  isAdvocate: PropTypes.bool,
  disabledPersonally: PropTypes.bool,

  allowAttachOrder: PropTypes.bool.isRequired,
  allowAttachFile: PropTypes.bool.isRequired,
  showSelectInstance: PropTypes.bool,
};

PermissionsDialog.defaultProps = {
  allowAttachOrder: true,
  allowAttachFile: true,
  owners: [],
  setId: setComponentsId("permissions-dialog"),
  open: false,
  value: {},
  disabledPersonally: false,
};

const styles = {
  ...dialogStyles,
  ...customInputStyle,
  tooltipRadio: {
    maxWidth: "50%",
    "&:last-child": {
      // eslint-disable-line no-useless-computed-key
      alignSelf: "flex-end",
      position: "absolute",
    },
    "@media (max-width:767px)": {
      // eslint-disable-line no-useless-computed-key
      maxWidth: "none",
      "&:last-child": {
        // eslint-disable-line no-useless-computed-key
        alignSelf: "auto",
        position: "static",
      },
    },
  },
};

const mapStateToProps = (state) => {
  const {
    authorization: {
      info: { userId, middle_name, last_name, first_name },
      info: userInfo,
      role: prevRole,
      roleInfo,
    },
  } = state;
  return {
    currentUserId: userId,
    role: roleInfo?.code || prevRole,
    userInfo,
    user: {
      userId,
      surname: last_name,
      firstname: first_name,
      middlename: middle_name,
    },
  };
};

export default compose(
  connect(mapStateToProps),
  translate("ClaimList"),
  withStyles(styles),
  withAdvocate
)(PermissionsDialog);
