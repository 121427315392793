import DataSource from "../../../pages/Case/decorators/dataSource";
import {
  PermissionTargetType,
  // PermissionType,
} from "../../Share/components/OrderDialog/types";

const { myProcDocDataSource, myCaseDocDataSource } = DataSource;

const getProperties = ({
  activeCase,
  activeProc,
  casesCourts,
  caseDocuments,
  changedShare,
  checked,
  display,
  isLegal,
  itemShareList,
  list,
  myCaseDocTitle,
  myCaseDocuments,
  myProcDocTitle,
  myProcDocuments,
  onCheckItem,
  onCheckProcItem,
  onCheckboxClick,
  onProcCheckboxClick,
  onViewDocument,
  owners,
  procChecked,
  procCourts,
  procDocuments,
  proceedingsList,
  userMetaData,
  userId,
}) => {
  const favorites =
    userMetaData &&
    userMetaData.meta &&
    userMetaData.meta.favorites &&
    Array.isArray(userMetaData.meta.favorites)
      ? userMetaData.meta.favorites
      : [];
  const itemShares = Array.isArray(itemShareList) ? itemShareList : [];
  const addOwner = (docList) => {
    if (!Array.isArray(docList)) return [];
    const arr = docList.map((item) => {
      const foundOwner = Array.isArray(owners)
        ? owners.find((owner) => owner.allowedBy === item.owner)
        : null;
      item.resharing = itemShares.some(
        (share) =>
          share.targetId === item.id &&
          userId === share.createdBy &&
          share.isActive
      );
      if (foundOwner) item.owner = foundOwner.allowedByName || item.owner;
      return item;
    });
    if (docList && docList.meta) arr.meta = docList.meta;
    return arr;
  };
  const proc =
    activeProc || proceedingsList.find(({ id }) => id === procChecked);
  let currentList = Array.isArray(list) ? addOwner(list) : [];
  let onCheck = onCheckItem;
  let targetType = PermissionTargetType.CAUSE;
  let targetId = checked || "";
  let currentShares = [];
  let handleCheckboxClick = onCheckboxClick;
  let currentChecked = checked;
  let showCreateButton = false;
  let enableShareButton = false;
  let filterOwners = [];
  let foundInMembers = null;
  let docList = null;
  let docDataSource = null;
  let showMyDocList = false;
  let docFilter = "case";
  let courtsList = casesCourts;
  let docDisplay = "myCaseDocuments";
  let docTitle = myCaseDocTitle;

  if (activeCase) {
    switch (display) {
      case "procList":
        courtsList = procCourts;
        currentChecked = procChecked;
        currentList = addOwner(proceedingsList);
        handleCheckboxClick = onProcCheckboxClick;
        onCheck = onCheckProcItem;
        targetType = 1;
        targetId = procChecked;
        break;
      case "documentProcList":
        courtsList = procCourts;
        currentList = addOwner(procDocuments);
        docDataSource = myProcDocDataSource;
        docDisplay = "myProcDocuments";
        docFilter = "proceeding";
        docList = myProcDocuments;
        docTitle = myProcDocTitle;
        onCheck = onViewDocument;
        targetId = procChecked;
        break;
      case "documentCaseList":
        currentList = addOwner(caseDocuments);
        docDataSource = myCaseDocDataSource;
        docList = myCaseDocuments;
        onCheck = onViewDocument;
        break;
      case "documentView":
        currentList = procDocuments;
        break;
      case "caseList":
      default:
        break;
    }
    const { caseMembers } = activeCase;

    foundInMembers = Array.isArray(caseMembers)
      ? caseMembers.some((item) => item.userId === userId)
      : null;

    if (
      (display === "documentProcList" || display === "documentCaseList") &&
      proc
    ) {
      filterOwners = (owners || []).filter((item) => {
        // общие доверенности и доверенности по текущему делу/производству
        const byTargetId =
          (item.targetType === PermissionTargetType.COMMON && !item.targetId) ||
          (item.targetType === PermissionTargetType.PROCEEDING &&
            item.targetId === proc.id) ||
          (item.targetType === PermissionTargetType.CAUSE &&
            item.targetId === checked) ||
          (item.targetType === PermissionTargetType.JUSTICE &&
            parseInt(item.targetId) === proc.jurisdictionTypeId);
        // есть право редактировать
        const allowEdit = !!item.allowEdit || !!item.allowCommit;

        return item.isActive && byTargetId && allowEdit;
      });
    }

    showCreateButton =
      !isLegal &&
      (foundInMembers || filterOwners.some((item) => !!item.allowCommit));

    currentShares = itemShares.reduce((acc, item) => {
      const itisDouble = !!acc.find((share) => (share || {}).id === item.id);
      if (
        !itisDouble &&
        item.targetId === targetId &&
        (userId === item.createdBy || item.userId === userId)
      ) {
        item.dialog =
          userId === item.createdBy ? "changePermission" : "addReshare";
        if ((item.userId === userId && item.allowShare === 0) || !item.isActive)
          item.dialog = "viewOnly";
        acc.push(item);
      }
      return acc;
    }, []);
    if (
      changedShare &&
      !currentShares.find((item) => item.id === changedShare.id)
    ) {
      currentShares.push(changedShare);
    }

    enableShareButton = !!targetId;

    showMyDocList = !!(
      (display === "documentProcList" || display === "documentCaseList") &&
      docDataSource &&
      Array.isArray(docList) &&
      (!!docList.length || docDataSource.withSearch)
    );
  }

  return {
    favorites,
    currentList,
    onCheck,
    targetType,
    handleCheckboxClick,
    currentChecked,
    showCreateButton,
    enableShareButton,
    showMyDocList,
    docFilter,
    courtsList,
    docDisplay,
    docTitle,
    currentShares,
    targetId,
    filterOwners,
    foundInMembers,
    addOwner,
    docDataSource,
    docList,
    activeProc: proc,
    activeCase,
  };
};

export default getProperties;
