import { createStyles, WithStyles } from "@material-ui/core/styles";
import rawControlStyles from "../../variables/styles/customInputStyle";
import rawSchemaFormStyles from "../../variables/styles/schemaForm";
import { ArraySchema, JsonSchema, ObjectSchema } from "./json-schema";

export interface SelectSheme {
  items?: {
    key?: string | number;
    label: string;
    value: string;
  }[];
  label?: string;
  order?: string;
  source?: string;
  value?: string;
}

export interface ControlSchema extends JsonSchema {
  control?: string;
  hidden?: boolean;
  hint?: string;
  mask?: string;
  readonly?: boolean;
  select?: SelectSheme;
  tabsIds?: number[];
  value?: any;
  code: string;
  name: string;
  wiki?: { href: string; title: string };
}

export interface TabSetItem {
  key: string;
  value: any;
}

export interface TabSchema {
  id: number;
  title?: string;
  // description?: string;
  set: TabSetItem[];
}

export interface FormSchema extends ObjectSchema, Omit<ArraySchema, "items"> {
  control?: string;
  checked?: boolean;
  items?: FormSchema;
  /*
  // Function of type (name, value, formValues) => {...} as string to eval
  */
  onChangeValue?: string;
  /*
  // Function of type (context, data) => {...} as string to eval
  */
  onDataUpdate?: string;
  onCheckErrors?: string;
  properties: {
    [key: string]: ControlSchema;
  };
  required?: string[];
  tabs?: TabSchema[];
  videourl?: string;
  value?: string;
}

export interface FormValues {
  [key: string]: any;
}

export type FormData = FormValues | FormValues[];

export interface FormContext {
  definitions?: FormValues;
  externals?: FormValues;
  properties?: FormValues;
  components?: {
    [key: string]: any;
  };
  [key: string]: any;
}

export type ChangeValueHandler = (path: string, value: any) => Promise<void>;

export const formControlStyle = createStyles(rawControlStyles);

export interface BaseControlProps<InputProps>
  extends WithStyles<typeof formControlStyle> {
  context: any;
  error?: boolean;
  errors?: string | any;
  disabled?: boolean;
  hidden?: boolean;
  inputProps?: Partial<InputProps>;
  name: string;
  onChange?: ChangeValueHandler;
  onCheckError?: ChangeValueHandler;
  onFinish?: ChangeValueHandler;
  path?: string;
  readOnly?: boolean;
  required?: boolean;
  schema: ControlSchema;
  theme?: any;
  setId: (s: string) => string;
  t: (key: string, params?: any[]) => string;
  tabId?: number;
  value: any;
}

export const schemaFormStyles = createStyles(rawSchemaFormStyles);

export interface SchemaFormProps extends WithStyles<typeof schemaFormStyles> {
  context: FormContext;
  data: FormData;
  dataSaving?: boolean;
  // errors?: Map<string, string>;
  name: string;
  noErrorCheck?: boolean;
  onClose?: (name: string, data: FormData) => Promise<void>;
  onDataCheck?: (
    name: string,
    data: FormData,
    errors: Map<string, string>
  ) => Promise<void>;
  onDataSave?: (name: string, data: FormData) => Promise<void>;
  onDataUpdate?: (
    name: string,
    data: FormData,
    errors?: any | string[]
  ) => Promise<void>;
  onError?: (error: string) => void;
  onValueChange?: (
    formName: string,
    valueKey: string,
    value: any
  ) => Promise<void>;
  onValueFinish?: (
    formName: string,
    valueKey: string,
    value: any
  ) => Promise<void>;
  setId: (s: string) => string;
  schema: FormSchema;
  // showErrors?: boolean;
  t: (key: string, params?: any[]) => string;
}

export const defaultT = (key: string, params?: any[]): string => key;
