import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { translate } from "react-translate";
import { connect } from "react-redux";
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import EDSForm from "../../components/EDSForm";

import { addEncodeKey, requestClaimTemplates } from "../../actions/claim";

import {
  requestPermissionsList,
  addPermission,
} from "../../actions/permissions";

import DataSource from "../../helpers/dataSource";

import customPassword from "../../helpers/customPassword";
import promiseChain from "../../helpers/promiseChain";
import personCat from "../../variables/inPersonOnlyTemplatesCategories";

import styles from "../../variables/styles/claimList";

import ClaimLayout from "./components/ClaimLayout";

const dataSource = DataSource("api/documents/for_sign");

class ClaimList extends Component {
  state = {
    blockScreen: false,
    openCreatePermissionDialog: false,
    openCreateClaimDialog: false,
    openDeleteClaimDialog: false,
    openPermissionDialog: false,
    checked: [],
    busy: false,
    ownership: {
      claimant: true,
    },
    pdf: null,
    doc: null,
    selectedTemplateId: null,
    showPdf: false,
    courtNotFound: false,
  };

  toggleDeleteDialog = () => {};

  toggleTemplateDialog = () => {};

  togglePermissionDialog = () => {
    const { busy } = this.state;
    const { filters, loadingPermissions } = this.props;
    const personOnly =
      filters && personCat.find((item) => item === filters.category);

    if (personOnly) {
      this.toggleTemplateDialog();
    } else if (!busy && !this.state.openPermissionDialog) {
      const openPermissionDialog = true;
      const states = {
        blockScreen: false,
        openCreateClaimDialog: !openPermissionDialog,
        openPermissionDialog,
      };
      if (!loadingPermissions) {
        this.setState({ blockScreen: true }, () =>
          requestPermissionsList("?filter[isActive]=true").then(() =>
            this.setState(states)
          )
        );
      } else {
        this.setState(states);
      }
    } else {
      this.setState({ openPermissionDialog: false }, this.toggleTemplateDialog);
    }
  };

  closeDialog = () =>
    this.setState({
      openCreateClaimDialog: false,
      openDeleteClaimDialog: false,
      openPermissionDialog: false,
    });

  getOwners = () => {
    const { userInfo, permissionList } = this.props;
    return Array.isArray(permissionList)
      ? permissionList.filter((item) => item.allowedBy !== userInfo.userId)
      : [];
  };

  getList = () => {
    const { userInfo } = this.props;
    const owners = this.getOwners();
    const list = Array.isArray(this.props.list) ? this.props.list : [];

    return list
      .map((item) => {
        const ownerData = owners.find(
          (share) =>
            share.allowedBy === item.owner && share.userId === userInfo.userId
        );
        const isOwner = item.owner === userInfo.userId;
        const haveEditPermission = true;
        const haveDeletePermission = false;
        return {
          ...item,
          haveEditPermission,
          isOwner,
          ownerData,
          haveDeletePermission,
        };
      })
      .filter(this.filter);
  };

  withMeta = (list) => {
    list.meta = (this.props.list || []).meta;
    return list;
  };

  UNSAFE_componentWillMount() {
    const { templates } = this.props;
    // requestPermissionsList();

    if (!templates) {
      requestClaimTemplates();
    }
    dataSource.clear();
    dataSource.setFilter("state", "2");
    dataSource.setValue("storeName", this.props.rootComponent + "List");
    dataSource.load();
  }

  UNSAFE_componentWillReceiveProps({ list }) {
    const { list: oldList } = this.props;
    if (
      list !== oldList ||
      (list && list[0] && oldList && oldList[0] && list[0].id !== oldList[0].id)
    ) {
      this.setState({ checked: [] });
    }
    if (dataSource.start === 0) {
      this.setState({ page: 0 });
    }
  }

  onSelectAllClick = () => {};
  onCheckItem = () => () => {};

  handleClick =
    ({
      category,
      state,
      id,
      digitalDocumentStaticFile,
      haveEditPermission,
      haveDeletePermission,
    }) =>
    () => {
      const { path, history, trash } = this.props;
      const notHavePermission = trash
        ? !haveDeletePermission
        : !haveEditPermission &&
          (state === 1 || !digitalDocumentStaticFile) &&
          category !== 4;
      if (notHavePermission) return null;
      if (trash) return this.onCheckItem(id)();
      return history.push(`${path}/${id}`);
    };

  changeOwnership = (ownership) => this.setState({ ownership });

  getTemplate = (templateId) =>
    this.props.templates
      .filter((template) => template.id === templateId)
      .shift() || {};

  handleClaimRecover = () => {};

  handleClaimDelete = () => {};

  handleSelectTemplate = () => () => {};

  handleClaimCreate = () => {};

  handleSelectKey = (cert, signer, resetPrivateKey) => {
    const { userInfo, rootComponent, t } = this.props;

    const passPhrase = customPassword();
    const {
      userId,
      services: {
        eds: {
          data: { encodeCert },
        },
      },
    } = userInfo;

    return promiseChain([
      () => signer.execute("SaveCertificate", encodeCert),
      () => signer.execute("ParseCertificate", encodeCert),
      (crt) =>
        signer.execute(
          "EnvelopDataEx",
          [crt.issuer],
          [crt.serial],
          false,
          passPhrase,
          true
        ),
      (targetKey) =>
        promiseChain([
          () => this.handleClaimCreate(),
          (claim) =>
            promiseChain([
              () => addEncodeKey(passPhrase, claim.id),
              () =>
                addPermission({
                  userId,
                  targetType: 0, // It's a magic
                  permissionType: "allowCommit",
                  targetId: claim.id,
                  targetKey,
                }),
              resetPrivateKey,
              () => this.props.history.push(`/${rootComponent}/${claim.id}`),
            ]).catch((e) =>
              this.setState({
                blockScreen: false,
                error: t(e.message || e.serverMessage),
              })
            ),
        ]).catch((e) =>
          this.setState({
            blockScreen: false,
            error: t(e.message || e.serverMessage),
          })
        ),
    ]).catch((e) =>
      this.setState({
        blockScreen: false,
        error: t(e.message || e.serverMessage),
      })
    );
  };

  createSortHandler = (property) => () => {
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }

    dataSource.sort = { [property]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
  };

  filter = ({ digitalDocumentTemplateId }) =>
    !!this.getTemplate(digitalDocumentTemplateId);

  pagination = (e, page) => {
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource.load();
  };

  changeCount = ({ target: { value } }) => {
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load();
  };

  render() {
    const { t, filters, list, templates } = this.props;
    const { openCreatePermissionDialog } = this.state;
    const { category: docCategory } = filters || {};

    return (
      <Fragment>
        <ClaimLayout
          {...this.props}
          {...this.state}
          docCategory={docCategory}
          list={list && templates ? this.withMeta(this.getList()) : null}
          dataSource={dataSource}
          toggleTemplateDialog={this.toggleTemplateDialog}
          toggleDeleteDialog={this.toggleDeleteDialog}
          handleClaimRecover={this.handleClaimRecover}
          onSelectAllClick={this.onSelectAllClick}
          createSortHandler={this.createSortHandler}
          getTemplate={this.getTemplate}
          onCheckItem={this.onCheckItem}
          handleClick={this.handleClick}
          handleSearch={this.handleSearch}
          handleClaimCreate={this.handleSelectTemplate}
          clearSearch={this.clearSearch}
          handleClaimDelete={this.handleClaimDelete}
          pagination={this.pagination}
          changeCount={this.changeCount}
          changeOwnership={this.changeOwnership}
          togglePermissionDialog={this.togglePermissionDialog}
          closeDialog={this.closeDialog}
          load={dataSource.load}
          owners={this.getOwners()}
          approval={true}
        />
        <Dialog
          open={openCreatePermissionDialog}
          maxWidth="md"
          onClose={() =>
            this.setState({
              openCreatePermissionDialog: false,
              selectedTemplateId: null,
            })
          }
        >
          <DialogTitle>{t("ENVELOP_USES")}</DialogTitle>
          <DialogContent>
            <EDSForm onSelectKey={this.handleSelectKey} />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

ClaimList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  userInfo: PropTypes.object.isRequired,
  permissionList: PropTypes.array,
  itemShareList: PropTypes.array,
  templates: PropTypes.array,
  filters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  categories: PropTypes.array,
  trash: PropTypes.bool,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  rootComponent: PropTypes.string.isRequired,
  loadingPermissions: PropTypes.bool.isRequired,
};

ClaimList.defaultProps = {
  list: null,
  permissionList: [],
  itemShareList: [],
  templates: [],
  filters: null,
  categories: null,
  trash: false,
  courts: null,
};

const styled = withStyles(styles)(ClaimList);
const translated = translate("ClaimList")(styled);

const mapStateToProps = ({
  claim: { templates, categories, statuses, loading, ...rest },
  permissions: { list: permissionList, itemShareList, loadingPermissions },
  authorization: { info: userInfo, role: prevRole, roleInfo },
  dictionary: { courts },
}) => ({
  list: dataSource.getList(rest),
  templates,
  categories,
  permissionList,
  statuses,
  loading,
  userInfo,
  itemShareList,
  courts,
  loadingPermissions,
  role: roleInfo?.code || prevRole,
});

// decorate and export
export default connect(mapStateToProps)(translated);
