import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import cx from "classnames";

import humanDateFormat from "../../helpers/humanDateFormat";
// import OpenInNewWindow from '../OpenInNewWindow';
import ShareDialogFromTo from "./ShareDialogFromTo";
import ShareDialogChoiseUser from "./ShareDialogChoiseUser";
import ShareDialogPermissions from "./ShareDialogPermissions";
import ShareDialogActions from "./ShareDialogActions";
import ShareDialogReshare from "./ShareDialogReshare";
import ShareDialogTitle from "./ShareDialogTitle";
import PreviewDialog from "../Attach/PreviewDialog";
import { DisplayPreviewDialog } from "./types";
import KeyPng from "../../assets/img/key.svg";
import { getPermText } from "../../helpers/permission";
// import { Icon } from "@material-ui/core";

// import {
//   requestOnePermission,
//   // requestPermissionsList,
// } from "../../actions/permissions";
import Share from "../../pages/Share";
import { requestPermissionsListChildren } from "../../actions/permissions";
import { renderUserBlock } from "../../components/ShareDialog/ShareDialogFromTo";

const ShareDialogLayout = ({
  pdf,
  t,
  toggleDialog,
  changedShare,
  display,
  classes,
  handleClick,
  reshare,
  dataSource,
  onSelectAllClick,
  createSortHandler,
  onCheckItem,
  checked,
  permission,
  INN,
  error,
  foundUser,
  duplicateShare,
  allowShare,
  allowAsvp,
  openChainOfResharingsDialog,
  busy,
  targetType,
  targetId,
  setId,
  onClose,
  toogleChainOfResharingsDialog,
  addShare,
  clearINN,
  onChangeINN,
  onBlurINN,
  caseNumIsChecked,
  handleChangeCaseNumIsChecked,
  courtStateChecked,
  handleChangeCourtStateCheckBox,
  onChangeCaseNumber,
  onBlurCaseNumber,
  onChangeJusticeCheckBox,
  changePermission,
  onChangePermission,
  toggleReshare,
  toggleAllowAsvp,
  shareSubTitle,
  description,
  link,
  doc,
  docBase64,
  previewDialog,
  togglePreviewDialog,
  openedPreviewDialog,
  isСhanged,
  id,
  location,
}) => {
  const [permissionChild, setPermissionChild] = useState();
  // let filters = [targetType, targetId];
  const open = !!display && display !== "delete" && display !== "signing";
  let title =
    !!changedShare && changedShare.typeId === 2 ? t("EDRRecipe") : t("SHARE");
  let subTitle = "";
  let subReshare = "";
  let showChoiseUser = false;
  let showPermissionsChoise = open;
  let showReshare = false;
  let showCloseButton = open;
  let showSubmitButton = open;
  let showChangeSubmitButton = open;
  let showPermissionsText = false;
  let showCaseNumberInput =
    (changedShare?.targetType || 0) === 0 ? true : false;
  let showJusticeSelect = (changedShare?.targetType || 0) === 0 ? true : false;
  let showShareList = false;
  if (
    changedShare &&
    !changedShare.isOwner &&
    changedShare.allowedBy !== changedShare.createdBy
  ) {
    subReshare = t("SUB_RESHARE", {
      subReshare: changedShare.createdByName,
    });
  }

  switch (display) {
    case "addShare":
      showChoiseUser = true;
      showChangeSubmitButton = false;
      showCloseButton = false;
      showJusticeSelect =
        shareSubTitle.includes("до справи") ||
        shareSubTitle.includes("до провадження")
          ? false
          : true;
      showCaseNumberInput =
        shareSubTitle.includes("до справи") ||
        shareSubTitle.includes("до провадження")
          ? false
          : true;
      title = t("ADD_PERMISSION_TITLE");
      break;
    case "addReshare":
      showPermissionsChoise = false;
      showChangeSubmitButton = false;
      showSubmitButton = false;
      showReshare = true;
      showPermissionsText = true;
      showJusticeSelect = false;
      showCaseNumberInput = false;
      // title = t('EDRRecipe');
      break;
    case "viewOnly":
      showPermissionsChoise = false;
      showChangeSubmitButton = false;
      showSubmitButton = false;
      showPermissionsText = true;
      break;
    case "addResharePermissions":
      showChoiseUser = true;
      showChangeSubmitButton = false;
      showCloseButton = false;
      showJusticeSelect = (changedShare?.targetType || 0) === 0;
      showCaseNumberInput = (changedShare?.targetType || 0) === 0;
      subTitle = t("ADD_PERMISSION_TITLE");
      showReshare = false;
      break;
    case "changePermission":
      title = t("READONLY_PERMISSION_TITLE");
      t("CHANGE_PERMISSION_TITLE");
      // t("READONLY_PERMISSION_TITLE");
      showSubmitButton = false;
      showJusticeSelect = false;
      showCaseNumberInput = false;
      break;
    case "showShareList": //hubi
      showShareList = true;
      showJusticeSelect = false;
      showCaseNumberInput = false;
      title = t("SHARE_LIST_AND_ORDERS");
      break;
    default:
      break;
  }

  // const getData = async (id) => {
  //   await requestOnePermission(id)
  //     .then((res) => {
  //       setChangedShareProp({
  //         ...res,
  //         ...res.meta,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  // useEffect(() => {
  //   if (changedShare) {
  //     getData(id);
  //   }
  // }, [changedShare]);

  useEffect(() => {
    if (showReshare && changedShare.id) {
      requestPermissionsListChildren(changedShare.id).then((permissisons) => {
        setPermissionChild(permissisons);
      });
    }
  }, [changedShare]);

  if (showShareList) {
    return (
      <Share
        title={"SHARE_CASE_LIST"}
        // list={list}
        setId={() => {}}
        targetType={location.pathname.includes("/cases/case=") ? 1 : 2}
        loactionPath={
          location.pathname.includes("/cases/case=")
            ? "provProceedings"
            : "case"
        }
        targetId={targetId}
      />
    );
  }
  return (
    <Fragment>
      <ShareDialogTitle //Заголовок форми
        classes={classes}
        setId={setId}
        title={title}
        t={t}
        changedShare={changedShare}
        toogleChainOfResharingsDialog={toogleChainOfResharingsDialog}
        openChainOfResharingsDialog={openChainOfResharingsDialog}
        description={description}
        shareSubTitle={shareSubTitle}
        link={link}
      />
      {changedShare && (
        <DialogContent
          className={cx(
            classes.content,
            classes.dialogContentWrappers,
            classes.smallWrapper,
            classes.fromToWrapper
          )}
          id={setId("content")}
        >
          {subReshare && <Typography component="h2">{subReshare}</Typography>}
          <ShareDialogFromTo //Ланцюжок батьківських довіреностей
            changedShare={changedShare}
            t={t}
            classes={classes}
            setId={setId}
          />
          {changedShare.createdAt && (
            <Typography component="h1" className={classes.flexBox}>
              <span>
                {t("CREATING_DATE", {
                  date: humanDateFormat(changedShare.createdAt),
                })}
              </span>
              &nbsp;
              {changedShare.documentId && (
                <>
                  <span
                    className={classes.link}
                    onClick={togglePreviewDialog(DisplayPreviewDialog.Share)}
                  >
                    {t("PREVIEW_LINK")}
                  </span>
                  <IconButton
                    color="inherit"
                    onClick={togglePreviewDialog(DisplayPreviewDialog.ECP)}
                    title={t("CHECK_ECP")}
                    className={classes.menuButton}
                  >
                    <img
                      src={KeyPng}
                      alt={t("CHECK_ECP")}
                      width={24}
                      height={24}
                    />
                  </IconButton>
                </>
              )}
              <PreviewDialog //перегляд довіреності або перевірка ецп
                format={"pdf"}
                file={previewDialog.blob}
                url={previewDialog.url}
                doc={previewDialog.base64}
                openDialog={openedPreviewDialog}
                toggleDialog={togglePreviewDialog()}
                setId={(name) => setId(`PreviewDialog-${name}`)}
              />
            </Typography>
          )}
          {changedShare.cancelDate && (
            <Typography component="h1" className={classes.flexBox}>
              <span>
                {t("PERMISSION_CANCEL_DATE", {
                  date: humanDateFormat(changedShare.cancelDate),
                })}
              </span>
              &nbsp;
            </Typography>
          )}
          {showPermissionsText && (
            <Fragment>
              <Typography
                component="p"
                id={setId("permission")}
                className={classes.formControl}
              >
                {t("PERMISSION_TITLE")}:&nbsp;
                {getPermText(t, changedShare)}
              </Typography>
            </Fragment>
          )}
          {/* {changedShare.allowShare === 1 && (
            <Typography
              component="p"
              id={setId("permission")}
              className={classes.formControl}
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <span
                className={classes.link}
                style={{
                  alignSelf: "flex-end",
                  fontSize: "12px",
                  textDecoration: "underline dotted",
                }}
                onClick={toggleDialog("cildrenPremission")}
              >
                {t("SHARE_CHAIN")}
              </span>
            </Typography>
          )} */}
        </DialogContent>
      )}

      {subTitle && (
        <DialogTitle
          className={cx(
            classes.title,
            classes.dialogContentWrappers,
            classes.smallWrapper
          )}
          id={setId("subtitle")}
        >
          {subTitle}
        </DialogTitle>
      )}

      <DialogContent
        className={cx(
          classes.content,
          classes.dialogContentWrappers,
          classes.smallWrapper
        )}
        id={setId("content-2")}
      >
        {showChoiseUser && (
          <ShareDialogChoiseUser
            classes={classes}
            t={t}
            setId={setId}
            error={error}
            clearINN={clearINN}
            INN={INN}
            onChangeINN={onChangeINN}
            onBlurINN={onBlurINN}
            foundUser={foundUser}
          />
        )}
        {showPermissionsChoise && (
          <ShareDialogPermissions
            display={display}
            classes={classes}
            permission={permission}
            onChangePermission={onChangePermission}
            setId={setId}
            t={t}
            error={error}
            reshare={reshare}
            showJusticeSelect={showJusticeSelect}
            showCaseNumberInput={showCaseNumberInput}
            caseNumIsChecked={caseNumIsChecked}
            handleChangeCaseNumIsChecked={handleChangeCaseNumIsChecked}
            courtStateChecked={courtStateChecked}
            handleChangeCourtStateCheckBox={handleChangeCourtStateCheckBox}
            onChangeJusticeCheckBox={onChangeJusticeCheckBox}
            onChangeCaseNumber={onChangeCaseNumber}
            onBlurCaseNumber={onBlurCaseNumber}
            toggleReshare={toggleReshare}
            toggleAllowAsvp={toggleAllowAsvp}
            allowShare={allowShare}
            allowAsvp={allowAsvp}
            changedShare={changedShare}
            targetId={targetId}
          />
        )}
        {showReshare && (
          <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            {permissionChild
              ?.filter((item) => item.isActive)
              .map((item) =>
                renderUserBlock(item, item.userName, "to", t, classes, setId)
              )}
          </div>
        )}

        {showReshare && handleClick && (
          <ShareDialogReshare
            addReshare={toggleDialog("addResharePermissions")}
            classes={classes}
            t={t}
            setId={setId}
            changedShare={changedShare}
            dataSource={dataSource}
            display={display}
            allowShare={allowShare}
            onSelectAllClick={onSelectAllClick}
            createSortHandler={createSortHandler}
            onCheckItem={onCheckItem}
            handleClick={handleClick}
            checked={checked}
            toggleDialog={toggleDialog}
          />
        )}
      </DialogContent>

      <ShareDialogActions
        classes={classes}
        setId={setId}
        onClose={onClose}
        toggleDialog={toggleDialog}
        display={display}
        busy={busy}
        t={t}
        duplicateShare={duplicateShare}
        changePermission={changePermission}
        error={error}
        foundUser={foundUser}
        addPermission={addShare()}
        showCloseButton={showCloseButton}
        showSubmitButton={showSubmitButton}
        showChangeSubmitButton={showChangeSubmitButton}
        isСhanged={isСhanged}
      />
    </Fragment>
  );
};

ShareDialogLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  foundUser: PropTypes.object,
  busy: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  display: PropTypes.string.isRequired,
  changedShare: PropTypes.object,
  reshare: PropTypes.bool,
  dataSource: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  createSortHandler: PropTypes.func.isRequired,
  onCheckItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  checked: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  openChainOfResharingsDialog: PropTypes.bool.isRequired,
  INN: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  duplicateShare: PropTypes.object,
  allowShare: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  targetType: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  toogleChainOfResharingsDialog: PropTypes.func.isRequired,
  addShare: PropTypes.func.isRequired,
  clearINN: PropTypes.func.isRequired,
  courtStateChecked: PropTypes.bool.isRequired,
  handleChangeCourtStateCheckBox: PropTypes.func.isRequired,
  onChangeINN: PropTypes.func.isRequired,
  onChangeJusticeCheckBox: PropTypes.func.isRequired,
  onChangeCaseNumber: PropTypes.func.isRequired,
  changePermission: PropTypes.func.isRequired,
  onChangePermission: PropTypes.func.isRequired,
  // showJusticeSelect: PropTypes.func.isRequired,
  showJusticeSelect: PropTypes.func,
  showCaseNumberInput: PropTypes.func.isRequired,
  toggleReshare: PropTypes.func.isRequired,
  pdf: PropTypes.string,
  shareSubTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string,
  isСhanged: PropTypes.bool.isRequired,
};

ShareDialogLayout.defaultProps = {
  changedShare: null,
  foundUser: null,
  reshare: true,
  duplicateShare: null,
  pdf: "",
  handleClick: null,
  onCheckItem: null,
  onSelectAllClick: null,
  link: "",
};

export default ShareDialogLayout;
