// @ts-nocheck

import { useEffect, useState } from "react";
import { styles } from "./OrderDialogStyle";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  withStyles,
} from "@material-ui/core";
import cx from "classnames";
import { useSelector } from "react-redux";
import { BlockScreen, Button } from "../../../../components";
import OrderDialogLayout from "./OrderDialogLayout";
import {
  getLegalFields,
  getNotLegalFields,
  Item,
  OrderDialogFormData,
} from "./items";
import {
  OrderIssueredByType,
  PermissionTargetType,
  // PermissionType,
  PreviewDialogType,
  SaveOrderBody,
  SaveOrderBodyMeta,
} from "./types";
import {
  addOrderPermission,
  requestOnePermission,
} from "../../../../actions/permissions";
import { generatePdf, requestPdf } from "../../../../actions/claim";

import blobToBase64 from "../../../../helpers/blobToBase64";
import { blobToTextEncoded } from "../../../../helpers/blobToText";
import getFormat from "../../../../helpers/getAttachFormat";
import stringToBlob from "../../../../helpers/stringToBlob";
import formatFile from "../../../../helpers/formatFile";
import getFileUrl from "../../../../helpers/getFileUrl";
import { userIdSelector } from "../../../../selectors/auth";
import { hideFields, showFields } from "./utils";
// import { searchUser } from "../../../../actions/user";
// import { getCheckCabinet } from "../../../../actions/check-cabinet";
// import withPDF from "../withPDF";
import {
  BaseItem,
  ChipsItemOptions,
  TextItemOptions,
} from "../../../../components/RenderControl";
import { DisplayPreviewDialog } from "../../../../components/ShareDialog/types";
import checkECP from "../../../../helpers/checkECP";

import { Link } from "react-router-dom";

type Props = {
  classes: any;
  pdf: string;
  docBase64: string;
  doc: null | Blob;
  t: (s: string) => string;
  showError: (s: string) => void;
  closeDialog: (removeDisplay?: boolean) => void;
  defaultMeta: SaveOrderBodyMeta | null;
  createdAt?: string;
  cancelDate?: string;
  documentId?: string;
  pdfLoading: boolean;
  readOnly: boolean;
  setPdf: (doc: any, data: any, documentId: string, isUpdate: false) => void;
  id?: string;
};

const unLegalFields = getNotLegalFields();
const legalFields = getLegalFields();

const validateField = (field: Item, value: any) => {
  const { required, options, type, visible } = field;
  if (!visible) return;

  if (required && (value === "" || value == null))
    throw new Error("Поле обов`язкове для заповнення");

  if (!options || value === "" || value == null) {
    return;
  }

  if (type === "string") {
    const { regexp, maxLen } = options as TextItemOptions;
    if (maxLen && value.length > maxLen) {
      throw new Error(`Максимальна довжина поля ${maxLen}`);
    }

    if (regexp && !regexp.test(String(value).toLocaleLowerCase())) {
      throw new Error("Некоректний формат поля");
    }
  }
};

const checkErrors = (errors: Record<string, any>) =>
  Object.values(errors).some((val) => !!val);

interface ExtendedOrderDialogFormData extends OrderDialogFormData {
  isLegal: boolean;
  caseNumberVisibility: boolean;
}

const initialState: ExtendedOrderDialogFormData = {
  address: "",
  caseNumber: "",
  companyName: "",
  contractDate: "",
  contractNumber: "",
  edrpou: "",
  email: "",
  ipn: "",
  issueredByAddress: "",
  issueredByName: "",
  name: "",
  orderDate: "",
  orderNumber: "",
  phone: "",
  workAddress: "",
  isLegal: false,
  caseNumberVisibility: false,
  issueredByType: 0,
};

const clearPreview = (): PreviewDialogType => ({
  blob: null,
  base64: "",
  url: "",
  opened: false,
  display: DisplayPreviewDialog.NONE,
});

// function bodyMetaToFormData({
//   allowedBy,
//   ...rest
// }: SaveOrderBodyMeta): ExtendedOrderDialogFormData {
//   return {
//     ...rest,
//     ...allowedBy,
//     isLegal: allowedBy ? !!allowedBy.companyName : false,
//     issueredByType: rest.issueredByName ? 1 : 0,
//     caseNumberVisibility: !!rest.caseNumber,
//   };
// }

type PDFState = {
  blob: File | null;
  formatSource: string;
  format: string;
  docBase64: string;
  pdf: string;
};

function OrderDialog({
  classes,
  t,
  closeDialog,
  setPdf,
  showError,
  readOnly,
  docBase64,
  doc,
  createdAt,
  cancelDate,
  id,
  defaultMeta,
}: Props) {
  const [loading, setLoading] = useState(false);
  // const [formData, setFormData] =
  //   useState<ExtendedOrderDialogFormData>(initialState);
  const [formData, setFormData] = useState<ExtendedOrderDialogFormData>(
    defaultMeta ? defaultMeta : initialState
  );
  const [isLegal, setIsLegal] = useState(false);
  const [PDF, setPDF] = useState<PDFState>({
    blob: null,
    formatSource: "",
    format: "",
    docBase64: "",
    pdf: "",
  });
  const [pdfLoading, setPdfLoading] = useState(false);
  const [documentId, setDocumentId] = useState<string | null>(null);

  const [issueredByType, setIssueredByType] = useState(0);
  const [caseNumberVisibility, setCaseNumberVisibility] = useState(false);
  const [errors, setErrors] = useState<Record<string, any>>({});
  const [orderPreview, setPreviewDialog] = useState<PreviewDialogType>(
    clearPreview()
  );
  const userId = useSelector(userIdSelector);

  const loadPdf = async (changedShare: any) => {
    if (changedShare) {
      setPdfLoading(true);
      let file = await requestPdf(changedShare);
      if (
        !!file.size &&
        !file.message &&
        (typeof file === "string" || typeof file === "object")
      ) {
        if (typeof file === "string") {
          file = stringToBlob(file);
        }
        const [base64, text, source] = await Promise.all([
          blobToBase64(file),
          blobToTextEncoded("Windows-1251")(file),
          file,
        ]);
        const formatSource = await formatFile(source, text);
        const format = await getFormat(formatSource, text);
        const url = await getFileUrl(formatSource, format, text);
        setPDF({
          blob: file,
          formatSource,
          format,
          docBase64: base64,
          pdf: url,
        });
      } else {
        setPDF({
          blob: null,
          formatSource: "",
          format: "",
          docBase64: "",
          pdf: "",
        });
      }
      setPdfLoading(false);
    }
  };
  const hasErrors = checkErrors(errors);
  const setOrderPreview = () => {
    setPreviewDialog({
      display: DisplayPreviewDialog.ORDER,
      opened: true,
      blob: PDF.blob,
      base64: PDF.docBase64,
      url: PDF.url,
    });
  };

  const setCheckECPPreview = async () => {
    try {
      const data: any = await checkECP(documentId!);
      if (!data) return;

      setPreviewDialog({
        display: DisplayPreviewDialog.ECP,
        opened: true,
        blob: data.file,
        base64: data.doc,
        url: data.pdf,
      });
    } catch (err) {
      console.log("File err: ", err);
      ///showError(e.message || '404 File not found');
    }
  };

  const togglePreviewDialog = (display: DisplayPreviewDialog) => () => {
    if (orderPreview.opened) {
      return setPreviewDialog(clearPreview());
    }
    //hubi
    switch (display) {
      case DisplayPreviewDialog.ORDER:
        return setOrderPreview();
      case DisplayPreviewDialog.ECP:
        return setCheckECPPreview();
      default:
        setPreviewDialog(clearPreview());
    }
  };

  const createFields = () => {
    const isLegalChips: BaseItem<any> = {
      type: "chips",
      name: "isLegal",
      visible: true,
      options: {
        items: [
          { label: "UN_LEGAL", value: false, key: "unLegal" },
          {
            label: "LEGAL",
            value: true,
            key: "isLegal",
          },
        ],
      } as ChipsItemOptions,
    };

    const causeNumVisibility: BaseItem<any> = {
      type: "checkbox",
      visible: true,
      name: "caseNumberVisibility",
      label: "CASE",
      options: { maxLen: 250 } as TextItemOptions,
    };

    let result = [isLegalChips, ...(isLegal ? legalFields : unLegalFields)];

    const caseIndex = result.findIndex((field) => field.name === "caseNumber");
    if (caseIndex !== -1) {
      result.splice(caseIndex, 0, causeNumVisibility);
    }

    if (formData.issueredByType === OrderIssueredByType.OFFICE) {
      result = showFields(result, ["issueredByName", "issueredByAddress"]);
    }

    if (formData.caseNumberVisibility) {
      result = showFields(result, ["caseNumber"]);
    }

    return !readOnly
      ? result
      : result.map((val) => ({ ...val, disabled: true }));
  };

  const [fields, setFields] = useState<Item[]>(createFields());
  let link = "";
  const getData = async (id) => {
    await requestOnePermission(id)
      .then((res) => {
        setDocumentId(res.documentId);
        const parsedMeta = res.meta; // Парсимо JSON-рядок

        setIsLegal(parsedMeta.allowedBy.isLegal);
        setIssueredByType(parsedMeta.issueredByType);
        setCaseNumberVisibility(parsedMeta.caseNumber);
        setIsLegal(parsedMeta.allowedBy.isLegal);
        // Виклик завантаження PDF
        loadPdf(res.documentId);

        link = res.documentId
          ? `/cases/case=${res.documentId}/proceeding=${3}`
          : "";

        setFormData({
          ...parsedMeta, // Використовуємо розпарсений об'єкт
          isLegal: parsedMeta.allowedBy.isLegal,
          caseNumberVisibility: !!parsedMeta.caseNumber,
          caseNumber: parsedMeta.caseNumber,
        });
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (id && id !== null && id !== "null") {
      getData(id);
    }
  }, [id, localStorage]);

  useEffect(() => {
    setFields(createFields());
    if (isLegal || formData.isLegal) {
      setFormData({ ...formData, companyName: "", edrpou: "" });
      setErrors({ ...errors, companyName: "", edrpou: "" });
    } else {
      setFormData({ ...formData, name: "", ipn: "" });
      setErrors({ ...errors, ipn: "", name: "" });
    }
  }, [isLegal, formData.isLegal]);

  useEffect(() => {
    if (
      issueredByType === OrderIssueredByType.OFFICE ||
      formData.issueredByType === OrderIssueredByType.OFFICE
    ) {
      setFields(showFields(fields, ["issueredByName", "issueredByAddress"]));
    } else {
      setFields(hideFields(fields, ["issueredByName", "issueredByAddress"]));
      setFormData({
        ...formData,
        issueredByName: "",
        issueredByAddress: "",
      });
      setErrors({
        ...errors,
        issueredByName: "",
        issueredByAddress: "",
      });
    }
  }, [issueredByType, formData.issueredByType]);

  useEffect(() => {
    if (caseNumberVisibility || formData.caseNumberVisibility) {
      setFields(showFields(fields, ["caseNumber"]));
    } else {
      setFields(hideFields(fields, ["caseNumber"]));
      setFormData({ ...formData, caseNumber: "" });
      setErrors({ ...errors, caseNumber: "" });
    }
  }, [caseNumberVisibility, formData.caseNumberVisibility]);

  // async function checkIpnOrEdpou(key: "ipn" | "edrpou", value: string) {
  //   if (value.length > 7) {
  //     const foundUser = await getCheckCabinet({ code: value });

  //     if (foundUser && foundUser.users && foundUser.users.length) {
  //       setErrors({
  //         ...errors,
  //         [key]: t(`USER_EXISTS_WITH_${key.toUpperCase()}`),
  //       });
  //     }
  //   }
  // }
  const handleValueChanged = (
    key: keyof OrderDialogFormData | string,
    value: any
  ) => {
    if (key === "issueredByType") value = +value;
    if (key === "isLegal") setIsLegal(value);

    const field = fields.find(({ name }) => key === name);

    setFormData({
      ...formData,
      [key]: value,
    });

    try {
      validateField(field!, value);
      setErrors({ ...errors, [key]: "" });
    } catch (e: any) {
      setErrors({ ...errors, [key]: e.message });
    }
    // if (key === "ipn" || key === "edrpou") checkIpnOrEdpou(key, value);
  };
  const handleOnSaveClick = async () => {
    const errors = fields.reduce((acc, field) => {
      try {
        validateField(field, formData[field.name]);
        acc[field.name] = "";
      } catch (e: any) {
        acc[field.name] = e.message;
      }
      return acc;
    }, {} as Record<string, any>);

    if (checkErrors(errors)) {
      return setErrors(errors);
    }

    const {
      caseNumber,
      contractDate,
      contractNumber,
      issueredByAddress,
      issueredByName,
      issueredByType,
      orderDate,
      orderNumber,
      isLegal,
      ...allowedBy
    } = formData;

    const data: SaveOrderBody = {
      // typeId: PermissionType.Order,
      allowAsvp: false,
      meta: {
        caseNumber,
        contractDate,
        contractNumber,
        issueredByAddress,
        issueredByName,
        issueredByType,
        orderDate,
        orderNumber,
        allowedBy: {
          address: allowedBy.address,
          companyName: allowedBy.companyName,
          edrpou: allowedBy.edrpou,
          email: allowedBy.email,
          ipn: allowedBy.ipn,
          name: allowedBy.name,
          phone: allowedBy.phone,
          isLegal: isLegal,
        },
      },
      userId: userId!,
      targetType: caseNumber
        ? PermissionTargetType.CAUSE
        : PermissionTargetType.COMMON,
      permissionType: "allowCommit",
      allowShare: false,
    };
    setLoading(true);
    try {
      const response = await addOrderPermission(data);
      if (response instanceof Error) {
        throw new Error(response.message);
      }
      const { docId } = response;
      const doc = await generatePdf(docId, { ...data, docId });
      setPdf(doc, data, docId, false);
      //нужно проставить false потому что setPdf должно перетереть это своим значением, чтобы можно было вернуться на эту форму
      closeDialog(false);
    } catch (e: any) {
      const info: string =
        e?.details || e?.message || t("ERROR_WHILE_CREATING_PDF");
      showError(info);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open
      onClose={() => closeDialog()}
      classes={{
        paper: classes.dialogRoot,
      }}
      className={cx(classes.dialog)}
    >
      <DialogTitle className={classes.dialogContentWrappers}>
        {t(readOnly ? "VIEW_ORDER" : "CREATING_NEW_ORDER")}
        {caseNumberVisibility && <Link to={link}></Link>}
      </DialogTitle>
      <OrderDialogLayout
        readOnly={readOnly}
        fields={fields}
        t={t}
        onValueChanged={handleValueChanged}
        formData={{
          ...formData,
          ...formData?.allowedBy,
        }}
        togglePreviewDialog={togglePreviewDialog}
        errors={errors}
        pdf={PDF}
        createdAt={createdAt}
        cancelDate={cancelDate}
        orderPreview={orderPreview}
      />
      <DialogActions
        className={cx(
          classes.actions,
          classes.dialogContentWrappers,
          classes.mobilePadding
        )}
        style={{
          justifyContent: "flex-start",
          marginTop: "24px",
        }}
      >
        <Button
          variant={"contained"}
          color="transparent"
          disabled={false}
          size={"small"}
          onClick={closeDialog}
        >
          {t("CLOSE")}
        </Button>
        {!readOnly && (
          <Button
            variant={"contained"}
            color="yellow"
            disabled={hasErrors}
            size={"small"}
            onClick={handleOnSaveClick}
          >
            {t("SAVE")}
          </Button>
        )}
      </DialogActions>
      <BlockScreen open={loading || pdfLoading} />
    </Dialog>
  );
}

export default withStyles(styles)(OrderDialog);
