import * as api from "../services/api";
import store from "../store";

export const CHECK_CERTIFICATE = "CHECK_CERTIFICATE";
export const REQUEST_USER_INFO = "REQUEST_USER_INFO";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const SEARCH_USER = "SEARCH_USER";

export const REQUEST_USER_SUGGESTIONS = "REQUEST_USER_SUGGESTIONS";

export const REQUEST_AUTH_MODE = "REQUEST_AUTH_MODE";

export const REQUEST_USER_SETTINGS = "REQUEST_USER_SETTINGS";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";

const SEND_SMS_CODE = "SEND_SMS_CODE";
const CHECK_PHONE_EXISTS = "CHECK_PHONE_EXISTS";
const VERIFY_SMS_CODE = "VERIFY_SMS_CODE";
const SEND_EMAIL_CODE = "SEND_EMAIL_CODE";
const CHECK_EMAIL = "CHECK_EMAIL";
const VERIFY_EMAIL_CODE = "VERIFY_EMAIL_CODE";

const USERS_URL = "api/users";

export function searchUser(data) {
  return api.post(`${USERS_URL}/search`, data, SEARCH_USER, store.dispatch);
}

export function updateUserInfo(userInfo) {
  return api.put(USERS_URL, userInfo, UPDATE_USER_INFO, store.dispatch);
}

export function getUserSuggestions(searchString) {
  return api
    .post(
      `${USERS_URL}/search`,
      { searchString },
      REQUEST_USER_SUGGESTIONS,
      store.dispatch
    )
    .then((result) => result.users);
}

export async function checkCertificate(data) {
  const result = await api.post(
    `${USERS_URL}/check-cert`,
    data,
    CHECK_CERTIFICATE,
    store.dispatch
  );
  if (result instanceof Error) {
    if (result.status === 400) {
      result.message =
        "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено";
    }
    throw result;
  }
  return result;
}

// export function getAuthMode() {
//     return api.get(`${USERS_URL}/two_factor_auth`, REQUEST_AUTH_MODE, store.dispatch);
// }

// export function setAuthMode(mode) {
//     return api.post(`${USERS_URL}/two_factor_auth`, mode, REQUEST_AUTH_MODE, store.dispatch);
// }

export function getUserSettings() {
  const state = store.getState();
  const userId = state.authorization.info && state.authorization.info.userId;
  if (window.amplitude) {
    window.amplitude.setUserId(userId);
    console.log(`Amplitude: встановлений id користувача ${userId}`);
  }

  return api.get(
    `${USERS_URL}/${userId}/settings`,
    REQUEST_USER_SETTINGS,
    store.dispatch
  );
}

export function putUserSettings(settings) {
  const {
    info: { userId },
  } = store.getState().authorization;
  return api.put(
    `${USERS_URL}/${userId}/settings`,
    { settings },
    UPDATE_USER_SETTINGS,
    store.dispatch
  );
}

export function setUserSettings(name, value) {
  const personalData = {
    ...store.getState().personalData,
    [name]: value,
  };
  return store.dispatch({
    type: SET_USER_SETTINGS,
    payload: personalData,
  });
}

export function checkPhoneExists(phone) {
  return api.get(
    `${USERS_URL}/phone/already_used?phone=${phone}`,
    CHECK_PHONE_EXISTS,
    store.dispatch
  );
}

export function sendSMSCode(phone) {
  return api.post(
    `${USERS_URL}/phone/send_sms`,
    { phone },
    SEND_SMS_CODE,
    store.dispatch
  );
}

export function verifySMSCode(phone, code) {
  return api.post(
    `${USERS_URL}/phone/verify`,
    {
      phone,
      code,
    },
    VERIFY_SMS_CODE,
    store.dispatch
  );
}

export function sendEmailCode(email, isLegal, edrpou) {
  return api.put(
    `${USERS_URL}/email/change`,
    {
      email,
      isLegal,
      edrpou,
    },
    SEND_EMAIL_CODE,
    store.dispatch
  );
}

export function checkEmail(email, isLegal, edrpou) {
  return api.post(
    `${USERS_URL}/email/check`,
    {
      email,
      isLegal,
      edrpou,
    },
    CHECK_EMAIL,
    store.dispatch
  );
}

export function verifyEmailCode(email, code, isLegal, edrpou) {
  return api.post(
    `${USERS_URL}/email/confirm`,
    {
      email,
      code,
      isLegal,
      edrpou,
    },
    VERIFY_EMAIL_CODE,
    store.dispatch
  );
}
