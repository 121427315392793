import DataSource, { DataSourceClass } from "../helpers/dataSource";
// import { ApiDataSource } from "../services/api/DataSource";
import {
  REGISTER_REQ_LIST,
  SET_ACTIVE_REGISTER,
  REGISTER_URL,
  StateRegister,
  StateRegisterRequest,
} from "../actions/stateRegisters";

interface StateRegistersState {
  activeRegister?: StateRegister;
  dataSource?: DataSourceClass;
  display: string;
  list?: StateRegisterRequest[];
  loading: boolean;
  registerList: StateRegister[];
}

const initialState: StateRegistersState = {
  activeRegister: undefined,
  dataSource: undefined,
  display: "",
  list: undefined,
  loading: false,
  registerList: [],
};

export const registerDataSource = DataSource(REGISTER_URL, undefined, {
  storeName: "registerList",
});
export const requestDataSource = DataSource(REGISTER_URL, "request", {
  actionName: REGISTER_REQ_LIST,
});

export default (state: StateRegistersState = initialState, action: any) => {
  state = registerDataSource.reducer()(state, action);
  state = requestDataSource.reducer()(state, action);
  const { payload, type } = action;
  switch (type) {
    case SET_ACTIVE_REGISTER:
      let { activeRegister, dataSource } = state;

      if (activeRegister?.id !== payload.id) {
        activeRegister = payload;
        requestDataSource.setBaseUrl(`${REGISTER_URL}/${payload.id}/request`);
        dataSource = requestDataSource;
      }
      return { ...state, activeRegister, dataSource, display: "requests" };
    default:
      return state;
  }
};
