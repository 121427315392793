import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  requestClaim,
  clearActiveClaim,
  addActiveClaim,
  downloadDocumentAttach,
} from "../../actions/claim";
import { routerActions } from "react-router-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { translate } from "react-translate";

import { RegularCard, Preloader } from "../../components";
import PageCardTitle from "../../components/PageCardTitle";
import ScanDocument from "../../components/ScanDocument";
import redirectes from "../../helpers/documentRedirected";
import StatusChip from "../../components/StatusChip";

import { usePrevious } from "../../hooks/usePrevious";
import { historyReturn } from "../../helpers/history";

const DocumentView = ({ history, role, ...props }) => {
  const [error, setError] = useState("");
  const [redirectTo, setRedirectTo] = useState(null);

  const prevRole = usePrevious({ role });

  function replaceHistory(e) {
    history.replace({ ...history.location, state: undefined });
  }

  const loadData = async () => {
    const {
      match: {
        params: { claimId },
      },
      activeClaim,
      dispatch,
      children,
    } = props;

    let claim = activeClaim;
    // const historyData = history?.location?.state?.dataFromNotification;

    if ((activeClaim && activeClaim?.id !== claimId) || role !== prevRole) {
      // якщо потрапили на сторінку з справи чи по посиланню і ще його не вантажили
      // або коли знаходячись на цій сторінці змінили роль користувача
      claim = await requestClaim(claimId);
    }

    // if (
    //   // якщо прийшли з повідомлень, де вже підвантажився документ, беремо готові дані
    //   // (лише коли компонент ініціалізувався вперше чи оновився при тій самій ролі)
    //   ((!prevRole && role) || (role && prevRole && role === prevRole)) &&
    //   historyData?.id
    // ) {
    //   claim = historyData;
    // } else if (
    //   !claim ||
    //   (activeClaim && activeClaim?.id !== claimId) ||
    //   role !== prevRole
    // ) {
    //   // якщо потрапили на сторінку з справи чи по посиланню і ще його не вантажили
    //   // або коли знаходячись на цій сторінці змінили роль користувача
    //   claim = await requestClaim(claimId);
    // }

    // if (!claim?.id) {
    //   history.replace("/");
    // }

    if (claim.message) {
      const message =
        claim.message === "Document is unavailable"
          ? t("NEED_DOCUMENT_ACCESS")
          : t("UNKNOWN_ERROR", { error: claim.message });
      if (!historyReturn(history, { message })) {
        setError(message);
      }
    } else {
      addActiveClaim(claim);
    }

    if (claim.state <= 1) {
      dispatch(routerActions.replace(`/claims/${claim.id}`));
    }
    if (claim && !children) {
      const redirectTo = redirectes[claim.category || "6"] || redirectes["6"];
      setRedirectTo(redirectTo);
    }
  };

  useEffect(() => {
    // if (!role) return;

    // if (
    //   role === "judge" &&
    //   history?.location?.state?.from.includes("/notifications")
    // ) {
    //   setRole("individual");
    // }

    loadData();

    window.addEventListener("beforeunload", replaceHistory, true);
    return () => {
      window.removeEventListener("beforeunload", replaceHistory, true);
      clearActiveClaim();
    };
  }, [role]);

  const downloadAttach = (attach) => () =>
    downloadDocumentAttach(props.activeClaim.id, attach.attachId, attach).then(
      (data) => data
    );

  const { t, setId, activeClaim, children } = props;
  const { description, visualization, number, documentStateInfo } =
    activeClaim || {};
  const text = visualization
    ? visualization.replace(", власник undefined", "")
    : number || "";
  let errorText = "";
  let errorSubText = "";
  if (error) {
    errorText = error.includes("have needed access")
      ? t("NEED_DOCUMENT_ACCESS")
      : error;
    errorSubText = error.includes("have needed access") ? t("DELETED_DOC") : "";
  }

  return (
    <RegularCard
      headerColor="primary"
      cardTitle={
        <PageCardTitle
          text={
            <>
              {error ? errorText : description || t("DOC")}
              {activeClaim && activeClaim.state && (
                <StatusChip
                  status={activeClaim.state}
                  tableChip={false}
                  documentStateInfo={documentStateInfo}
                />
              )}
            </>
          }
          stepName={"pageDocumentView"}
          setId={setId}
          documentStateInfo={documentStateInfo}
          placement="right"
          claim={activeClaim}
        />
      }
      cardSubtitle={error ? errorSubText : text}
      setId={setId}
    >
      {!error && children}
      {!activeClaim && !error && <Preloader />}
      {errorText}
      {errorSubText}
      {!error && activeClaim && (
        <ScanDocument
          viewDocument={activeClaim}
          disableToolbar={true}
          downloadAttach={downloadAttach}
          attaches={activeClaim.attaches}
          redirectTo={redirectTo}
          fromWhatComponent={"DocumentView"}
          history={history}
        />
      )}
    </RegularCard>
  );
};

DocumentView.propTypes = {
  activeClaim: PropTypes.object,
  history: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DocumentView.defaultProps = {
  activeClaim: null,
  children: "",
};

const mapStateToProps = ({
  claim: { activeClaim },
  authorization: { info, role: prevRole, roleInfo },
}) => ({ activeClaim, info, role: roleInfo?.code || prevRole });

const translated = compose(
  translate("CaseList"),
  connect(mapStateToProps),
  withRouter
)(DocumentView);

export default translated;
