import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Toolbar, Icon } from "@material-ui/core";
import { Button } from "../../../components";
// import includeFieldsFromUserRole from 'helpers/includeFieldsFromUserRole';
import Filters from "./Filters";
import { IconButton } from "@material-ui/core";
import { SaveAlt } from "@material-ui/icons";
// После взлома пропала кнопка "Управління довіреностями" в разделе "Мої справи"
// const showCaseProcShare = display =>
//     includeFieldsFromUserRole('showCaseProcShare') && (display === 'caseList' || display === 'procList');
import { requestDownloadZip } from "../../../actions/cases";
import { LinearProgress } from "@material-ui/core";

const CaseToolbar = ({
  classes,
  t,
  checked,
  toggleCaseDialog,
  setId,
  dataSource,
  display,
  backToPrevDisplay,
  statuses,
  loadData,
  courtTypes,
  justiceTypes,
  courts,
  togglePermissionDialog,
  addToFavorites,
  favoriteIsChecked,
  deleteFromFavorite,
  page,
  showCreateButton,
  toggleDialog,
  // currentShares,
  enableShareButton,
  getMyJudgeRoles,
  getMyStateMemberRoles,
  history,
  // location,
  role,
  // activeCase,
  activeProc,
  procChecked,
}) => {
  const [isFileLoading, setIsFileLoading] = useState(false);
  const download = async () => {
    setIsFileLoading(true);
    try {
      const { response, filename } = await requestDownloadZip(procChecked);

      response
        .blob()
        .then((blob) => {
          const { document } = window;
          const URL = window.URL || window.webkitURL;

          const element = document.createElement("a");
          element.setAttribute("href", URL.createObjectURL(blob));
          element.setAttribute("download", filename);

          element.style.display = "none";
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
        })
        .catch((err) => {
          console.log("Not valid file: ", err);
        })
        .finally(() => {
          setIsFileLoading(false);
        });
    } catch (err) {
      console.log("Download error: ", err);
    }
  };

  return (
    <>
      <Toolbar
        className={classes.toolbar}
        disableGutters={true}
        id={setId("toolbar")}
      >
        {display !== "caseList" && !!history.length && (
          <Button
            color="transparent"
            variant="contained"
            className={classes.mobileButtonFullWidth}
            onClick={backToPrevDisplay}
            id={setId("toolbar-back-button")}
            setId={(elementName) => setId(`toolbar-back-${elementName}`)}
          >
            <Icon>arrow_back</Icon>&nbsp;&nbsp;
            {t("BACK")}
          </Button>
        )}
        {role !== "judge" &&
          display === "documentProcList" &&
          showCreateButton && (
            <Button
              color="yellow"
              variant="contained"
              onClick={togglePermissionDialog([0])}
              id={setId("create-button")}
              setId={(elmentName) => setId(`create-${elmentName}`)}
            >
              {t(page === "cases" ? "CREATE_NEW" : "CREATE_NEW_DESICION")}
            </Button>
          )}
        {/* {display === "documentProcList" && (
      <Button
        color="transparent"
        disabled={!checked.length}
        onClick={toggleCaseDialog(display, false)}
        id={setId("toolbar-info-button")}
        className={classes.mobileButtonFullWidth}
        setId={(elementName) => setId(`toolbar-info-${elementName}`)}
      >
        <Icon>info</Icon>&nbsp;&nbsp;
        <span>ІНФОРМАЦІЯ ПРО ПРОВАДЖЕННЯ</span>
        <span>{t("CASE_INFO_BUTTON")}</span>
      </Button>
    )} */}
        <Button
          color="transparent"
          disabled={!checked.length}
          onClick={toggleCaseDialog(display, false)}
          id={setId("toolbar-info-button")}
          className={classes.mobileButtonFullWidth}
          setId={(elementName) => setId(`toolbar-info-${elementName}`)}
        >
          <Icon>info</Icon>&nbsp;&nbsp;
          <span>{t("CASE_INFO_BUTTON")}</span>
        </Button>
        {(display === "procList" || display === "documentProcList") && (
          <IconButton
            color="transparent"
            onClick={download}
            title={t("DOWNLOAD_ZIP")}
            className={`${classes.mobileButtonFullWidth} ${classes.downloadBtn}`}
            disabled={isFileLoading || (!procChecked && !activeProc?.id)}
          >
            <SaveAlt />
          </IconButton>
        )}
        {display === "caseList" && (
          <Button
            color="transparent"
            disabled={!checked.length}
            onClick={favoriteIsChecked ? deleteFromFavorite : addToFavorites}
            id={setId("toolbar-info-button")}
            className={classes.mobileButtonFullWidth}
            setId={(elementName) => setId(`toolbar-info-${elementName}`)}
          >
            <Icon>{favoriteIsChecked ? "star_border" : "star"}</Icon>
            &nbsp;&nbsp;
            <span>
              {favoriteIsChecked
                ? t("UN_FAVORITES_BUTTON")
                : t("FAVORITES_BUTTON")}
            </span>
          </Button>
        )}
        {display !== "documentProcList" &&
          display !== "documentCaseList" &&
          display !== "documentView" && (
            <Button
              color="transparent"
              onClick={toggleDialog("showShareList")}
              id={setId("toolbar-add-button")}
              setId={(elmentName) => setId(`toolbar-add-${elmentName}`)}
              className={classes.mobileButtonFullWidth}
              disabled={!enableShareButton}
            >
              <Icon>supervisor_account</Icon>&nbsp;&nbsp;
              {t("CHANGE_SHARE")}
            </Button>
          )}
        {/* {showCaseProcShare(display) &&
            <Button
                color="transparent"
                onClick={toggleDialog('addShare')}
                id={setId('toolbar-add-button')}
                setId={elmentName => setId(`toolbar-add-${elmentName}`)}
                className={classes.mobileButtonFullWidth}
                //disabled={!enableShareButton}
            >
                <Icon>supervisor_account</Icon>&nbsp;&nbsp;
                {t('CHANGE_SHARE')}
            </Button>
        } */}
        {display !== "documentView" && display !== "signing" && (
          <Filters
            dataSource={dataSource}
            setId={setId}
            getMyJudgeRoles={getMyJudgeRoles}
            getMyStateMemberRoles={getMyStateMemberRoles}
            statuses={statuses}
            loadData={loadData}
            display={display}
            courtTypes={courtTypes}
            justiceTypes={justiceTypes}
            courts={courts}
          />
        )}
      </Toolbar>
      {isFileLoading && <LinearProgress className={classes.progress} />}
    </>
  );
};

CaseToolbar.propTypes = {
  // getMyStateMemberRoles: PropTypes.array.isRequired,
  // getMyJudgeRoles: PropTypes.array.isRequired,
  checked: PropTypes.string,
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  toggleCaseDialog: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,

  backToPrevDisplay: PropTypes.func.isRequired,
  statuses: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  courtTypes: PropTypes.array.isRequired,
  justiceTypes: PropTypes.array.isRequired,
  courts: PropTypes.array.isRequired,
  togglePermissionDialog: PropTypes.func.isRequired,
  addToFavorites: PropTypes.func.isRequired,
  favoriteIsChecked: PropTypes.bool.isRequired,
  deleteFromFavorite: PropTypes.func.isRequired,
  showCreateButton: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  // currentShares: PropTypes.array.isRequired,
  enableShareButton: PropTypes.bool.isRequired,
  history: PropTypes.array.isRequired,
};

CaseToolbar.defaultProps = {
  checked: "",
};

const mstp = ({ authorization: { role: prevRole, roleInfo } }) => {
  return {
    role: roleInfo?.code || prevRole,
  };
};

export default connect(mstp)(CaseToolbar);
