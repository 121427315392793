import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Icon,
  DialogActions,
} from "@material-ui/core";
import cx from "classnames";
import { Button } from "../../components";

import { getPermText } from "../../helpers/permission";
import { renderUserBlock } from "./ShareDialogFromTo";
import { requestPermissionsChain } from "../../actions/permissions";

const renderChainOfResharingsDialogContent = (
  chainOfResharings,
  classes,
  t,
  setId
) => (
  <DialogContent
    className={cx(
      classes.content,
      classes.dialogContentWrappers,
      classes.smallWrapper,
      classes.fromToWrapper
    )}
  >
    {chainOfResharings.map((item, index) => (
      <section
        className={cx(classes.changeTitle, classes.chainOfResharingsTitle)}
        id={setId("resharings-title")}
        key={item.id}
      >
        {index === 0 && (
          <Fragment>
            {renderUserBlock(
              item,
              item.allowedByName,
              "from",
              t,
              classes,
              setId
            )}
            <div className={classes.chainOfResharingsBlock}>
              <Typography component="h2">
                {t("PERMISSION_TITLE")}:&nbsp;
                {getPermText(t, item)}
              </Typography>
            </div>
          </Fragment>
        )}
        <div className={classes.chainOfResharingsBlock}>
          <Icon className={cx(classes.arrow, classes.downArrow)}>
            arrow_right_alt
          </Icon>
        </div>
        <div className={classes.chainOfResharingsBlock}>
          {renderUserBlock(item, item.userName, "to", t, classes, setId)}
        </div>
        <div className={classes.chainOfResharingsBlock}>
          <Typography component="h2">
            {t("PERMISSION_TITLE")}:&nbsp;{getPermText(t, item)}
          </Typography>
        </div>
      </section>
    ))}
  </DialogContent>
);

const ShareDialogTitle = ({
  classes,
  setId,
  title,
  toogleChainOfResharingsDialog,
  openChainOfResharingsDialog,
  t,
  changedShare,
  description,
  shareSubTitle,
  link,
}) => {
  const [chainOfResharings, setChainOfResharings] = useState();
  const loadChain = () => {
    if (!chainOfResharings) {
      requestPermissionsChain(changedShare.id).then((chain) => {
        if (chain) {
          setChainOfResharings(chain);
        }
      });
    }

    toogleChainOfResharingsDialog();
  };

  return (
    <DialogTitle
      className={cx(
        classes.title,
        classes.dialogContentWrappers,
        classes.smallWrapper
      )}
      id={setId("title")}
    >
      <span
        className={cx(
          changedShare &&
            chainOfResharings &&
            chainOfResharings.length > 0 &&
            classes.first,
          shareSubTitle && classes.fullWidth
        )}
      >
        {title}&nbsp;
        {link && <Link to={link}>{shareSubTitle}</Link>}
        {!link && shareSubTitle}
      </span>
      {changedShare && (
        <span
          className={cx(classes.last, shareSubTitle && classes.fullWidth)}
          onClick={loadChain}
        >
          <span className={classes.underlineLink}>{t("CHAIN_TITLE")}</span>
          <Dialog
            open={openChainOfResharingsDialog}
            onClose={toogleChainOfResharingsDialog}
            className={classes.dialog}
          >
            {chainOfResharings &&
              renderChainOfResharingsDialogContent(
                chainOfResharings,
                classes,
                t,
                setId
              )}
            <DialogActions
              className={cx(classes.actions, classes.dialogContentWrappers)}
              id={setId("action")}
            >
              <Button
                id={setId("cancel-button")}
                onClick={toogleChainOfResharingsDialog}
                color="transparent"
              >
                {t("CLOSE")}
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      )}
      {description && <Typography>{description}</Typography>}
    </DialogTitle>
  );
};

ShareDialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  changedShare: PropTypes.object,
  toogleChainOfResharingsDialog: PropTypes.func.isRequired,
  openChainOfResharingsDialog: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  shareSubTitle: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

ShareDialogTitle.defaultProps = {
  changedShare: null,
};

export default ShareDialogTitle;
