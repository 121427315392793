import { render } from "react-dom";

export function handlePrintHistory(reactComponent) {
  try {
    const iframe = document.createElement("iframe");
    // Ховаємо iframe за межами екрану, щоб він був рендерений
    iframe.style.position = "absolute";
    iframe.style.top = "-10000px";
    iframe.style.left = "-10000px";
    document.body.appendChild(iframe);

    const printWindow = iframe.contentWindow;
    printWindow.document.open();
    printWindow.document.write(`
      <!doctype html>
      <html>
        <head>
          <title>Картка руху документу</title>
          ${STYLES}
        </head>
        <body>
          <div id="print-root"></div>
        </body>
      </html>
    `);
    printWindow.document.close();

    printWindow.onload = () => {
      const container = printWindow.document.getElementById("print-root");
      if (container) {
        render(reactComponent, container);
      }
      // Невелика затримка для завершення рендерингу React-компонента
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        // Після друку видаляємо iframe
        setTimeout(() => {
          document.body.removeChild(iframe);
        }, 500);
      }, 300);
    };
  } catch (err) {
    console.error("print err: ", err);
  }
}

export const getHtmlTemplate = (header, rows) => `
  <!DOCTYPE html>
  <html>
    <head>
      ${STYLES}
    </head>
    <body>
      <h3>${header}</h3>
      <table>
        ${rows}
      </table>
    </body>
  </html>
`;

export const getRows = (array) => {
  const rows = array
    .map((rowData) => {
      return rowData?.[0] && rowData?.[1]
        ? `
        <tr>
          <td>${rowData[0]}</td>
          <td>${rowData[1]}</td>
        </tr>`
        : null;
    })
    .filter((row) => row !== null)
    .join("");

  return rows;
};

const STYLES = `<style>
  html, body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: .9rem;
  }
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  td:first-of-type {
    font-weight: bold;
  }
  @media (max-width: 350px) {
    table {
      font-size: .7rem;
    }
  }
</style>`;
