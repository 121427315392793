// @ts-nocheck
import React, { Fragment, useState, FC } from "react";
import { compose } from "redux";
import { translate } from "react-translate";
import { Snackbar } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
// import HelpIcon from "@material-ui/icons/Help";
import RefreshIcon from "@material-ui/icons/Refresh";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "../../../variables/styles/executive";

import humanDateFormat from "../../../helpers/humanDateFormat";
import { updateASVPStatus } from "../../../actions/executive-docs";
import statuses, {
  ExecStage,
  vpStaus,
} from "../../../variables/executiveStatuses";

import { Button } from "../../../components";
import { IconButton } from "../../../components";
import StatusChip from "../../ExecutiveDocs/decorators/index";
import HelpStatusDialog from "./HelpStatusDialog.jsx";

const infoBock = (classes, title, rows) => {
  return (
    <div className={classes.infoblock}>
      <div className={classes.title}>{title}</div>
      <table>
        <tbody>
          {rows
            .filter((item) => !!item[1])
            .map((item, idx) => (
              <tr key={idx}>
                <td className={classes.stylesExDocViewInfoBlockTitle}>
                  {item[0] || ""}
                </td>
                <td className={classes.stylesExDocViewInfoBlockData}>
                  {item[1] || ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

interface Props {
  activeDoc: any;
  t?: any;
  classes?: any;
  setId?: any;
}

const ExecutiveInfo: FC<Props> = ({
  activeDoc,
  t,
  classes,
  setId,
  getExecutiveCallback,
}) => {
  const [statusId, setStatusId] = React.useState(activeDoc?.statusId);
  const [updated, setUpdated] = React.useState(false);
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [error, setError] = React.useState("");
  const [openedStatus, setOpeneStatus] = useState(false); //hubi

  const {
    collector,
    debtor,
    payer,
    paymentInfo,
    statusInfo,
    collectorIssuance,
    debtorIssuance,
  } = activeDoc || {};

  const [statusInfoResult, setStatusInfoResult] = useState(null);

  const handleDialogClose = () => {
    setOpeneStatus(false);
  };

  const sumTotal = paymentInfo?.summ || 0;
  const sumPaid = paymentInfo?.paidSumm || 0;
  const sumToPay = sumTotal - sumPaid;

  const checkExecMyRole = Array.isArray(activeDoc?.execMyRole)
    ? activeDoc?.execMyRole?.find("collector") ||
      activeDoc?.execMyRole?.find("collectorRepresentative")
    : activeDoc?.execMyRole?.includes("collector") ||
      activeDoc?.execMyRole?.includes("collectorRepresentative");

  const showUpdateStatusBtn =
    ((!activeDoc?.statusIsFinal && activeDoc?.statusStageId === 5) ||
      [8, 9].includes(activeDoc?.statusId)) &&
    checkExecMyRole;

  const updateStatus = async () => {
    try {
      setUpdated(true);
      const newASVPStatus = await updateASVPStatus(activeDoc?.id);
      if (newASVPStatus instanceof Error) {
        setUpdated(false);
        setStatusId(activeDoc?.statusId);
        setError(newASVPStatus.message);
        setShowSnackbar(true);
      } else {
        setUpdated(false);
        setStatusId(newASVPStatus.statusId);
      }
      setStatusInfoResult(newASVPStatus?.statusInfo?.result);
      if (getExecutiveCallback) await getExecutiveCallback();
    } catch (error) {
      alert(error.message);
    }
  };

  const statusButton = () => (
    <div>
      {!updated ? (
        <>
          <StatusChip
            t={t}
            setId={setId}
            status={statusId || activeDoc?.statusId}
            statusName={activeDoc?.statusName}
            tableChip={true}
            catStatuses={statuses}
          />
          <HelpStatusDialog open={openedStatus} onClose={handleDialogClose} />
        </>
      ) : (
        <CircularProgress
          className={classes.stylesExDocViewForCircular}
          loading={true}
        />
      )}
      {showUpdateStatusBtn && updated === false && (
        <IconButton
          id={"update-exec-status-btn"}
          className={classes.stylesExDocViewForRefreshBtn}
          color="white"
          aria-hidden="true"
          title={t("UPDATE_STATUS_BTN")}
          aria-label="delete"
          onClick={() => updateStatus()}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </div>
  );

  const getVpStaus = (statusInfo) => {
    const statusKey = statusInfo?.result?.result?.vpStaus;
    return vpStaus[statusKey] || null;
  };

  const getVpStatus = () => {
    if (
      !activeDoc?.statusInfo ||
      Object.keys(activeDoc?.statusInfo).length === 0
    ) {
      return (
        <>
          {activeDoc?.enforcementName
            ? activeDoc?.enforcementName
            : "Не визначено"}
        </>
      );
    }

    const keys = Object.keys(statusInfo);

    const faultKey = keys.find(
      (key) => statusInfo[key]?.message && statusInfo[key]?.code
    );

    const message = faultKey
      ? statusInfo[faultKey]?.message
      : "Message not found";
    // const code = faultKey ? statusInfo[faultKey]?.code : "Code not found";

    return <>{message ? message : "Не визначено"}</>;
  };
  return (
    <Fragment>
      <div className={classes.container}>
        {infoBock(
          classes,
          `${t("INFO_ABOUT_DOC")} ${
            activeDoc?.penaltyTypeName
              ? "(" +
                activeDoc?.penaltyTypeName +
                " " +
                t("PENALTY_LOWERCASE") +
                ")"
              : ""
          }`,
          [
            [t("DOC_TYPE"), activeDoc?.execDocTypeName],
            [t("DELIVERY_OF_DATE"), humanDateFormat(activeDoc?.docDate)],
            [
              t("ABOUT_LABEL"),
              statusInfo?.result?.status !== "FAULT"
                ? activeDoc?.enforcementName
                : getVpStatus(),
            ],
            [t("STATE_LABEL"), statusButton()],
            [
              t("REASON_FAULT"),
              (statusInfo?.result?.status === "FAULT"
                ? statusInfo?.result?.fault?.message
                : statusInfo?.result?.result?.endReason) ||
                (statusInfoResult?.status === "FAULT"
                  ? statusInfoResult?.fault?.message
                  : statusInfoResult?.result?.endReason) ||
                (statusInfo?.data?.result?.status === "FAULT"
                  ? statusInfo?.data?.result?.fault?.message
                  : statusInfo?.data?.result?.result?.endReason),
            ],
          ]
        )}

        {(statusInfo?.result || statusInfoResult) &&
          infoBock(classes, t("EXECUTIVE_PROCEEDINGS"), [
            [t("NUMBER_LABEL"), statusInfo?.result?.result?.vpNum],
            [t("EXECUTOR_LABEL"), statusInfo?.result?.result?.executor],
            [t("PHONE_LABEL"), statusInfo?.result?.result?.executorPhone],
            [t("STATE_LABEL"), getVpStaus(statusInfo) || "Не визначено"],
            [
              activeDoc?.statusStageId === ExecStage.ASVP_REJECTED
                ? t("RETURN_DATE_LABEL")
                : t("COMPLETION_DATE_LABEL"),
            ],
            [
              activeDoc?.statusStageId === ExecStage.ASVP_REJECTED
                ? t("RETURN_REASON_LABEL")
                : t("COMPLETION_REASON_LABEL"),
              statusInfo?.result?.result?.endReason,
            ],
          ])}

        {infoBock(
          classes,
          activeDoc?.isStateCollector ? t("STATE_COLLECTOR") : t("COLLECTOR"),
          [
            [
              collector?.isLegal ? t("NAME_LABEL") : t("PIB_LABEL"),
              collector?.name,
            ],
            [
              collector?.isLegal ? t("EDRPOU_LABEL") : t("RNOCPP_LABEL"),
              collector?.code,
            ],
            [t("ADDRESS_LABEL"), collector?.address],
            [t("EMAIL_LABEL"), collector?.email],
            [t("PHONE_LABEL"), collector?.phone],
            [t("ISSUED"), collectorIssuance?.isDuplicate ? t("DUPLICATE") : ""],
            [
              t("ISSUE_FORM"),
              collectorIssuance?.issuanceType === 1
                ? t("ELECTRONIC")
                : collectorIssuance?.issuanceType === 2
                ? t("PAPER")
                : "",
            ],
            [
              t("ISSUE_DATE"),
              !collectorIssuance?.ecourtIssued &&
              !collectorIssuance?.paperIssued
                ? t("NOT_ISSUED")
                : collectorIssuance?.issuanceType === 1
                ? humanDateFormat(collectorIssuance?.ecourtIssued)
                : collectorIssuance?.issuanceType === 2
                ? humanDateFormat(collectorIssuance?.paperIssued)
                : "",
            ],
          ]
        )}

        {infoBock(classes, t("DEBTOR"), [
          [debtor?.isLegal ? t("NAME_LABEL") : t("PIB_LABEL"), debtor?.name],
          [
            debtor?.isLegal ? t("EDRPOU_LABEL") : t("RNOCPP_LABEL"),
            debtor?.code,
          ],
          [t("ADDRESS_LABEL"), debtor?.address],
          [t("EMAIL_LABEL"), debtor?.email],
          [t("PHONE_LABEL"), debtor?.phone],
          [t("DELIVERED"), debtorIssuance?.isDuplicate ? t("DUPLICATE") : ""],
          [
            t("DELIVERY_FORM"),
            debtorIssuance?.issuanceType === 1
              ? t("ELECTRONIC")
              : debtorIssuance?.issuanceType === 2
              ? t("PAPER")
              : "",
          ],
          [
            t("ISSUE_DATE"),
            !debtorIssuance?.ecourtIssued && !debtorIssuance?.paperIssued
              ? t("NOT_ISSUED")
              : debtorIssuance?.issuanceType === 1
              ? humanDateFormat(debtorIssuance?.ecourtIssued)
              : debtorIssuance?.issuanceType === 2
              ? humanDateFormat(debtorIssuance?.paperIssued)
              : "",
          ],
        ])}

        {payer &&
          infoBock(classes, t("PAYER"), [
            [payer?.isLegal ? t("NAME_LABEL") : t("PIB_LABEL"), payer?.name],
            [
              payer?.isLegal ? t("EDRPOU_LABEL") : t("RNOCPP_LABEL"),
              payer?.code,
            ],
            [t("ADDRESS_LABEL"), payer?.address],
            [t("EMAIL_LABEL"), debtor?.email],
            [t("PHONE_LABEL"), payer?.phone],
          ])}

        {sumTotal > 0 &&
          infoBock(classes, t("PAYMENT_INFO"), [
            [t("BANK_EDRPOU_LABEL"), paymentInfo?.bankEdrpou],
            [t("BANK_MFO_LABEL"), paymentInfo?.bankMfo],
            [t("BANK_NAME_LABEL"), paymentInfo?.bankName],
            [t("IBAN_LABEL"), paymentInfo?.iban],
            [t("SUM_LABEL"), sumTotal.toFixed(2)],
            [t("PAID_LABEL"), sumPaid > 0 ? sumPaid.toFixed(2) : undefined],
            [
              t("TO_BE_PAID_LABEL"),
              sumToPay < sumTotal ? sumToPay.toFixed(2) : undefined,
            ],
            [t("RECEIVER_EDRPOU_LABEL"), paymentInfo?.payeeCode],
            [t("RECEIVER_LABEL"), paymentInfo?.payeeName],
          ])}

        {activeDoc?.resolution &&
          infoBock(classes, t("RESOLUTION"), [["", activeDoc?.resolution]])}
      </div>

      {showSnackbar && (
        <Snackbar
          id={"error"}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          message={<span id="message-id">{error}</span>}
          action={[
            <Button
              key="close-error"
              color="yellow"
              size="small"
              onClick={() => setShowSnackbar(false)}
              className={classes.smallButton}
            >
              OK
            </Button>,
          ]}
        />
      )}
    </Fragment>
  );
};

const translated = compose(
  withStyles(styles),
  translate("ExecutiveDocs")
)(ExecutiveInfo);

export default translated;
