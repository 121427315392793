import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Route, Redirect } from "react-router-dom";
import { BlockScreen } from "../../components";
import { appRoutes } from "../../containers/Index/routes";

import { isLoggedIn } from "../../actions/auth";

import GoogleTagManager from "./GoogleTagManager";

export const PrivateRoute = ({
  component: Component,
  auth,
  accessRoles,
  location,
  role,
  courtIdUserScopes,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSigned, setIsSigned] = useState(false);
  const [rolesLoaded, setRolesLoaded] = useState(false);

  useEffect(() => {
    const checkSignedInStatus = async () => {
      const signedIn = isLoggedIn();
      setIsSigned(signedIn);

      if (signedIn && !rolesLoaded) {
        if (Array.isArray(courtIdUserScopes) && courtIdUserScopes.length > 0) {
          setRolesLoaded(true);
        }
      }

      setIsLoading(false);
    };

    checkSignedInStatus();
  }, [courtIdUserScopes, rolesLoaded]);

  useEffect(() => {
    if (auth.info) {
      const signedStatus = Array.isArray(accessRoles)
        ? !!accessRoles.filter(
            (item) =>
              Array.isArray(courtIdUserScopes) &&
              courtIdUserScopes.some((scope) => scope.code === item)
          ).length
        : false;
      setIsSigned(signedStatus);
    }
  }, [auth.info, accessRoles, courtIdUserScopes]);

  if (isLoading || !rolesLoaded) {
    return <BlockScreen open={true} transparentBackground={true} />;
  }

  if (isSigned && !auth.info) {
    return <BlockScreen open={true} transparentBackground={true} />;
  }

  if (auth.info) {
    if (!courtIdUserScopes) {
      return <BlockScreen open={true} transparentBackground={true} />;
    }

    if (!accessRoles) {
      return (
        <Redirect
          to={{
            pathname: "/notifications",
            state: { from: location },
          }}
        />
      );
    }

    if (
      Array.isArray(accessRoles) &&
      role &&
      !accessRoles.find((item) => item === role)
    ) {
      const foundRoute = appRoutes.find(
        (item) =>
          item.accessRoles &&
          item.accessRoles.includes(role) &&
          !!(item.accessRoles || []).find((roleName) =>
            courtIdUserScopes.some((scope) => scope.code === roleName)
          )
      );
      return (
        <Redirect
          to={{
            pathname: (foundRoute || {}).path || "/notifications",
            state: { from: location },
          }}
        />
      );
    }

    // isSigned = Array.isArray(accessRoles)
    //   ? !!accessRoles.filter((item) =>
    //       Array.isArray(courtIdUserScopes) &&
    //       courtIdUserScopes.some((scope) => scope.code === item)
    //     ).length
    //   : false;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <GoogleTagManager>
          {isSigned ? (
            <Component {...props} {...rest} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )}
        </GoogleTagManager>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  auth: PropTypes.object,
  accessRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  location: PropTypes.object.isRequired,
  courtIdUserScopes: PropTypes.array,
};

PrivateRoute.defaultProps = {
  auth: {},
  accessRoles: [],
};

function mapStateToProps(state) {
  return {
    auth: state.authorization,
    role: state.authorization.role,
    courtIdUserScopes: state.roles,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
