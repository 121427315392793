export default {
  ACCEPTED: "Прийнятий до виконання",
  ATTRIBUTES: "Атрибути",
  BACK: "Повернутись",
  CHECK_STATUS: "Перевірити статус",
  CANCELED: "Постанову скасовано",
  CHECK_CERTIFICATE_ERROR:
    "Ви намагаєтесь підписати не з тим КЕП, з яким виконано вхід в Кабінет, або сертифікат КЕП пошкоджено",
  CASE_PROC_NUMBER: "Справа № / Провадження №",
  CLOSE_TEXT: "Закрити",
  COLLECTOR_DEBITOR: "Стягувач/Боржник",
  COURT_TITLE: "Суд",
  COUNT_CASES: "з {{from}} по {{to}} із {{total}}",
  COLLECTOR: "Стягувач",
  COLLECTOR_REPRESENTATIVE: "Представник стягувача",
  CREATED_AT: "Дата створення",
  DOCUMENT_TITLE: "Назва документа",
  DIRECTED: "Направлений за належністю",
  DOCUMENT_STATUS: "Статус",
  // DOCUMENT_COURT: "Суд",
  DOCUMENT_ROLE: "Моя роль",
  DOCUMENT_FORM: "Форма видачі стягувачу",
  DOCUMENT_COURT: "Суд, що видав ВД",
  DOCUMENT_TYPING_COURT: "Почніть вводити назву суду",
  DOCUMENT_CREATED_AT: "Дата надходження",
  DOCUMENT_DOC_DATE: "Дата документа",
  DOC_TYPE: "Тип: ",
  DEBTOR: "Боржник",
  DEBTOR_REPRESENTATIVE: "Представник боржника",
  DISPLAYED_CASES: "Відображати:",
  CONTINUE_EDITING: "Повернутись",
  DOCUMENT_DATE: "Дата документа",
  CHANGE_DATE: "Дата зміни",
  DNZS_DATE: "ДНЗС",
  PAYMENT_INFO_SUMM: "Сума",
  EXECUTOR_NAME: "Назва виконавця",
  FAULT_MESSAGE: "Причина повернення",
  DOCUMENT_NUMBER: "Номер документа",
  EMPTY_FILE:
    "Документ в процесі завантаження. Спробуйте, будь ласка, пізніше.",
  ERROR_TITLE: "Сталась помилка",
  EXECUTED: "Виконаний",
  EXECUTIVE_EMPTY_PAGE_DESCRIPTION:
    "Тут будуть відображатись ваші виконавчі документи та виконавчі документи, до яких вам надано доступ",
  EXEC_DATE: "Дані ВД",
  EXECUTE_IMMEDIATE: "Повторна подача", // було Негайне виконання
  EXECUTIVE_DOCS_TITLE: "Виконавчі документи",
  EXECUTIVE_EMPTY_PAGE_TITLE: "",
  EXEC_INFO_DOC_TITLE: "",
  FINISH: "Підписати та відправити",
  CAME_FROM_COURT: "Надійшов з суду",
  PREVIEW_CLAIM_TO_ASVP: "Заява до АСВП",
  UNACCEPTED: "Повернений без прийняття",
  UPDATE_STATUS_BTN:
    "Оновити інформацію про стан обробки заяви та стан виконавчого провадження в АСВП",
  INFO_ABOUT_DOC: "Виконавчий документ",
  PAYER: "Платник",
  PAYER_REPRESENTATIVE: "Представник платника",
  PAY: "Сплатити",
  PAID: "Сплачено",
  PAYMENT_ERROR: "Помилка сплати:",
  PAYMENT_TERM: "Строк добровільної сплати",
  PAYMENT_INFO: "Платіжна Інформація",
  PAYMENT_BILL_COLLECTOR: "Рахунок стягувача",
  MY_ROLE: "Моя роль",
  MISSED_DEADLINE: "Пропущено строк пред’явлення",
  NEXT: "Наступний крок",
  NO_DATE_FORCE: "Не набрав законної сили",
  FAULT: "Заява відхилена",
  HEADER: "Виконавчі документи",
  HEADER2: "Виконавчі документи сабтайтл",
  PENALTY: "Стягнення",
  PENALTY_LOWERCASE: "стягнення",
  RETURNED: "Повернений до органу, що видав",
  ECECUTE_AUTO_FAULT: "Неможливість автоматичної подачі",
  REPRESENTATIVE: "Представник",
  REPRESENTATIVE_COLLECTOR: "Представник стягувача",
  RECEIVED: "Надійшло",
  RECEPTION_ERROR: "Помилка прийому до АСВП",
  RETCREDITOR: "Повернений стягувачу",
  REDIRECT_TO_CASE: "Повернутись до списку документів",
  REGISTERED: "Зареєстровано",
  REFERRED: "Переданий до підприємства",
  REJECTED: "Повернений без виконання",
  RESEND_FOR_EXECUTIVE: "Переподати",
  REDIRECT_TO_EXECUTIVE: "Повернутись до виконавчого документу",
  SEND_TO_ASVP: "Надіслати до АСВП",
  SUBMIT_FOR_EXECUTIVE: "Подати на виконання",
  STATUS: "Статус",
  SIGN_DOCUMENT: "Підписати",
  SIGNED: "підписано",
  SIGNING_DOCUMENT: "Підписання документа",
  SENDING: "Відправка до АСВП",
  SENDING_ERROR: "Помилка відправки до АСВП",
  STATE_COLLECTOR: "Державний стягувач",
  TO_EXECUTE: "До виконання",
  TO_EXECUTE_AUTO: "Автоматична подача",
  TRANSFERRED: "Переданий до ліквідаційної комісії",
  VP_STATUS_UNKNOWN_STATEMENT:
    "Зава ще не відправлена або ідентифікатор заяви не знайдено в АСВП",
  VP_STATUS_NONE: "стан до відкриття ВП або повернення ВД без виконання",
  VP_STATUS_ENFORCE: "Примусове виконання",
  VP_STATUS_REFUSE: "Відмовлено у відкритті",
  VP_STATUS_OPEN: "Відкрито",
  VP_STATUS_STOPPED: "Зупинено",
  VP_STATUS_FINISHED: "Завершено",
  VP_STATUS_CANCELED: "Постанову скасовано",
  VP_STATUS_ENDED: "Закінчено",
  VP_STATUS_FAULT: "Помилка",
  VP_STATUS_REGISTERED: "Зареєстровано",
  WAITING_FOR_SIGN: "Очікує на підписання",
  WAITING_PROCESSING: "На обробці",
  WAITING: "Очікує прийняття",
  DELETED_BY_COURT: "Видалено судом",
  ANNULLED_BY_COURT: "Анульовано судом",
  CANT_ISSUE_ECOURT: "Не може бути подано в електронній формі",
  CANT_ISSUE_TO_COLLECTOR: "Не може бути вручено стягувачу в електронній формі",
  NOT_ENFORCEABLE: "Не підлягає виконанню",
  NOT_FOR_ASVP: "Не підлягає подачі до АСВП",
  NOT_ISSUED_TO_DEBTOR: "Не вручено Боржнику",
  CANT_ISSUE_TO_DEBTOR:
    "До виконання. Не може бути вручено боржнику в електронній формі",
  SET_STATUS_ERROR: "Помилка встановлення статусу виконавчого документу",
  RESUBMISSION_ERROR: "Помилка повторної подачі на виконання",
  EXECUTIVE_PROCEEDINGS: "Виконавче провадження",
  RESOLUTION: "Резолюція",
  ABOUT_LABEL: "Про:",
  STATE_LABEL: "Стан:",
  REASON_LABEL: "Причина:",
  REASON_FAULT: "Підстава закриття/повернення без виконання:",
  NUMBER_LABEL: "Номер:",
  EXECUTOR_LABEL: "Виконавець:",
  PHONE_LABEL: "Телефон:",
  RETURN_DATE_LABEL: "Дата повернення:",
  COMPLETION_DATE_LABEL: "Дата завершення:",
  RETURN_REASON_LABEL: "Підстава повернення:",
  COMPLETION_REASON_LABEL: "Підстава завершення:",
  NAME_LABEL: "Назва:",
  PIB_LABEL: "П.І.Б.:",
  ADDRESS_LABEL: "Адреса:",
  EMAIL_LABEL: "E-mail:",
  EDRPOU_LABEL: "ЄДРПОУ:",
  RNOCPP_LABEL: "РНОКПП:",
  BANK_EDRPOU_LABEL: "ЄДРПОУ банку:",
  BANK_NAME_LABEL: "Назва банку:",
  BANK_MFO_LABEL: "МФО банку:",
  IBAN_LABEL: "IBAN:",
  SUM_LABEL: "Сума:",
  PAID_LABEL: "Сплачено:",
  TO_BE_PAID_LABEL: "До сплати:",
  RECEIVER_EDRPOU_LABEL: "ЄДРПОУ отримувача:",
  RECEIVER_LABEL: "Отримувач:",
  ISSUED: "Видано:",
  ISSUE_FORM: "Форма видачі:",
  ISSUE_DATE: "Дата вручення:",
  DELIVERED: "Вручено:",
  DELIVERY_FORM: "Форма видачі:",
  DELIVERY_DATE: "Дата вручення:",
  DELIVERY_OF_DATE: "Дата видачі:",
  DUPLICATE: "дублікат",
  ELECTRONIC: "електронна",
  PAPER: "паперова",
  NOT_ISSUED: "не видано",
  NOT_DELIVERED: "не вручено",
  ELECTRONIC_FORM: "Електронна",
  PAPER_FORM: "Паперова",
  NOT_NOTICED_FORM: "Не вказано",
  FROM_SUM: "Від:",
  TO_SUM: "До:",
  FROM_SUM_CHIP: "Від суми:",
  TO_SUM_CHIP: "До суми:",
  NO_ASVP_SHARES_MESSAGE: "У Вас відсутні довіреності з правом подачі до АСВП",
  UNKNOWN_STATE: "Не визначено",
  CHECK_ECP: "Перевірка КЕП",
  SAVE_TO_DEVICE: "Зберегти файл та електронний підпис архівом",
  PREVIEW_LINK: "Відкрити у новому вікні",
};
