import { requestAppealCourt } from "../../../actions/claim";
// import { requestPermissionsList } from "../../../actions/permissions";
import DataSource from "./dataSource";

const { procDataSource } = DataSource;

export function togglePermissionDialog(causeActionTypes = [0], viewDocument) {
  return async () => {
    const { loading } = this.state;
    let { openPermissionDialog } = this.state;
    const { page, courts } = this.props;
    let appealCourtError = "";
    let appealCourt = null;

    if (
      (causeActionTypes.includes(1) || causeActionTypes.includes(2)) &&
      !openPermissionDialog
    ) {
      this.setState({ loading: true, blockScreen: true });
      const { proceedingsList, t } = this.props;
      let { activeProc } = this.state;

      const activeProcId = viewDocument.proceedingId;
      try {
        if (!activeProc || activeProc.id !== activeProcId) {
          activeProc = (proceedingsList || []).find(
            (proc) => activeProcId === proc.id
          );
          if (!activeProc) {
            const proceedings = await procDataSource.load(viewDocument.caseId);
            for (let key in proceedings.req) {
              const proc = proceedings.req[key];
              if (proc.id === activeProcId) {
                activeProc = proc;
                break;
              }
            }
          }
        }

        const { procCourtTypeId, jurisdictionTypeId, courtId } = activeProc;
        //Якщо суд першої інстанції і адмін правопорушення, то подається в той же суд
        if (procCourtTypeId === 2 && jurisdictionTypeId === 6) {
          appealCourt = courts.find((court) => court.id === courtId);
          if (!appealCourt) {
            appealCourtError = t("APPEAL_COURT_NAME_ERROR", {
              courtName: "",
            });
          }
        } else {
          appealCourt = await requestAppealCourt(activeProcId);
          if (!appealCourt?.id) {
            appealCourtError = t("APPEAL_COURT_NAME_ERROR", {
              courtName: "",
            });
            appealCourt = null;
          } else if (appealCourt.isEnabled === 0) {
            appealCourtError = t("APPEAL_COURT_NAME_ERROR", {
              courtName: appealCourt.name,
            });
            appealCourt = null;
          }
        }

        this.setState({
          appealCourtError,
          appealCourt,
          activeProc,
        });
      } catch (error) {
        appealCourtError = error.message;
      }
    }
    if (
      !appealCourtError &&
      !loading &&
      !this.state.openPermissionDialog &&
      page === "cases"
    ) {
      this.setState({ loading: true, blockScreen: true });
      openPermissionDialog = true;
      const states = {
        loading: false,
        blockScreen: false,
        openCreateClaimDialog: !openPermissionDialog,
        openPermissionDialog,
        causeActionTypes,
      };
      // if (!loadingPermissions) {
      //   requestPermissionsList().then(() => this.setState(states));
      // } else {
      //   this.setState(states);
      // }
      this.setState(states);
    } else if (!appealCourtError) {
      this.setState(
        {
          openPermissionDialog: false,
          blockScreen: false,
          causeActionTypes,
        },
        this.toggleTemplateDialog(viewDocument)
      );
    } else {
      this.setState({ loading: false, blockScreen: false });
    }
  };
}

export function closeDialog() {
  this.setState({
    openCreateClaimDialog: false,
    openPermissionDialog: false,
    blockScreen: false,
  });
}

export function toggleTemplateDialog(activeDoc) {
  const { activeProc, openCreateClaimDialog } = this.state; // procChecked
  const { proceedingsList } = this.props;
  const activeProcId = activeDoc?.proceedingId || activeProc?.id;
  const foundProc = Array.isArray(proceedingsList)
    ? proceedingsList.find((proc) => activeProcId === proc.id)
    : null;
  this.setState({
    openCreateClaimDialog: !openCreateClaimDialog,
    loading: false,
    blockScreen: false,
    activeProc: foundProc || activeProc,
  });
}

export function toggleCaseDialog(
  display,
  changeDisplay = true,
  needLoad = true
) {
  return () =>
    this.setState(
      { openCaseDialog: !this.state.openCaseDialog },
      () => changeDisplay && this.nextDisplay(display, needLoad)
    );
}
