import { Component } from "react";
import PropTypes from "prop-types";

import { translate } from "react-translate";
import { connect } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
} from "@material-ui/core";

import EDSForm from "../../components/EDSForm";

import {
  addEncodeKey,
  deleteClaim,
  permanentDeleteClaim,
  recoverClaim,
  requestClaimTemplates,
  requestClaimTemplatesCat,
  requestTemplate,
  //setWizardStates,
} from "../../actions/claim";

import {
  addPermission,
  requestPermissionsList,
} from "../../actions/permissions";

import { requestEDDDictionary } from "../../actions/dictionary";

import DataSource from "../../helpers/dataSource";
import claimCreate from "../../helpers/createClaim";
import customPassword from "../../helpers/customPassword";
import getEncodeCert from "../../services/eds/helpers/getEncodeCert";

import personCat from "../../variables/inPersonOnlyTemplatesCategories";
import styles from "../../variables/styles/claimList";

import ClaimLayout from "./components/ClaimLayout";
import { hasPermissionForDelete } from "../../components/Table/hasPermissionForDelete";
import { ReturnAndLocateInTableHOC } from "../../components/ReturnAndLocateInTableHOC";
import { compose } from "redux";
// import auth from "../../variables/styles/auth";

const dataSource = DataSource("api/documents/my");

class ClaimList extends Component {
  state = {
    blockScreen: false,
    busy: false,
    checked: [],
    courtNotFound: false,
    doc: null,
    openCreatePermissionDialog: false,
    openCreateClaimDialog: false,
    openDeleteClaimDialog: false,
    openPermissionDialog: false,
    ownership: {
      claimant: true,
    },
    pdf: null,
    selectedTemplateId: null,
    showPdf: false,
    template: null,
    templatePermissionFilter: [],
  };

  toggleDeleteDialog = () => {
    if (!this.state.busy) {
      this.setState({
        openDeleteClaimDialog: !this.state.openDeleteClaimDialog,
      });
    }
  };

  toggleTemplateDialog = () => {
    if (!this.state.busy) {
      this.setState({
        openCreateClaimDialog: !this.state.openCreateClaimDialog,
      });
    }
  };

  togglePermissionDialog = () => {
    const { busy } = this.state;
    const { filters, loadingPermissions } = this.props;

    const personOnly =
      filters && personCat.find((item) => item === filters.category);

    if (personOnly) {
      this.toggleTemplateDialog();
    } else if (!busy && !this.state.openPermissionDialog) {
      const openPermissionDialog = true;
      const states = {
        blockScreen: false,
        openCreateClaimDialog: !openPermissionDialog,
        openPermissionDialog,
      };
      if (!loadingPermissions) {
        this.setState({ blockScreen: true }, () =>
          requestPermissionsList("?filter[isActive]=true")
            .then((res) => {
              this.setState(states);
            })
            .catch((err) => console.log("requestPermissionsList error", err))
        );
      } else {
        this.setState(states);
      }
    } else {
      this.setState({ openPermissionDialog: false }, this.toggleTemplateDialog);
    }
  };

  closeDialog = () =>
    this.setState({
      openCreateClaimDialog: false,
      openDeleteClaimDialog: false,
      openPermissionDialog: false,
    });

  getOwners = () => {
    const { permissionList, userInfo } = this.props;
    return Array.isArray(permissionList)
      ? permissionList.filter((item) => {
          if (item.targetType === 1) {
            return item.targetType === 0 || item.targetType === 3;
          } else {
            return (
              (item.targetType === 0 || item.targetType === 3) &&
              item.allowedBy !== userInfo.userId &&
              item.userId === userInfo.userId
            );
          }
        })
      : [];
  };

  getList = () => {
    const { userInfo, itemShareList } = this.props;
    const { courtIdUserScopes } = userInfo;
    const owners = this.getOwners();
    const list = Array.isArray(this.props.list) ? this.props.list : [];
    return list
      .map((item) => {
        const ownerData = owners.find(
          (share) =>
            share.allowedBy === item.owner && share.userId === userInfo.userId
        );
        const isOwner = item.owner === userInfo.userId;
        const itemPermission = Array.isArray(itemShareList)
          ? itemShareList.find(({ targetId }) => targetId === item.id)
          : null;
        const userIsExecutiveRegister =
          item.category === 4 &&
          courtIdUserScopes.includes("executiveDocumentsRegister");
        const haveEditPermission =
          isOwner ||
          (ownerData && ownerData.allowEdit === 1) ||
          (itemPermission && itemPermission.allowEdit === 1) ||
          userIsExecutiveRegister;
        const haveCommitPermission =
          isOwner ||
          (ownerData && ownerData.allowCommit === 1) ||
          (itemPermission && itemPermission.allowCommit === 1) ||
          userIsExecutiveRegister;
        const haveDeletePermission =
          (haveCommitPermission || userIsExecutiveRegister) &&
          [-1, 1, 2, 5, 6, 9].some((i) => i === item.state);
        return {
          ...item,
          haveEditPermission,
          isOwner,
          ownerData,
          haveDeletePermission,
        };
      })
      .filter(this.filter);
  };

  withMeta = (list) => {
    list.meta = (this.props.list || []).meta;
    return list;
  };

  componentDidMount() {
    this.reload();
    window.addEventListener("beforeunload", this.beforeunloadEventHandler);
    if (window.amplitude) {
      window.amplitude.track("Перегляд сторінки: Заяви");
      console.log("Amplitude: Відстеження перегляду сторінки /claims");
    }
  }

  beforeunloadEventHandler = () => {
    this.props.returnObject.removeReturnObject();
  };

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.beforeunloadEventHandler);
  }

  UNSAFE_componentWillReceiveProps({ list }) {
    const { list: oldList, trash } = this.props;
    let returnObject = this.props.returnObject.getReturnObject();
    if (
      list !== oldList ||
      (list && list[0] && oldList && oldList[0] && list[0].id !== oldList[0].id)
    ) {
      this.setState({
        checked:
          this.props.returnObject.isForm("claims") && !trash
            ? [returnObject.id]
            : [],
      });
      // this.props.returnObject.isForm("claims") &&
      //   this.props.returnObject.removeReturnObject();
    }
    if (dataSource.start === 0) {
      this.setState({ page: 0 });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.role !== this.props.role ||
      prevProps.roleInfo !== this.props.roleInfo
    ) {
      this.reload();
    }
  }

  reload() {
    const {
      history,
      templates,
      filters,
      categories,
      trash,
      roleInfo,
      role: prevRole,
      path,
    } = this.props;
    const role = roleInfo?.code || prevRole;

    const { category } = filters || {};

    if (category === 4) {
      if (path === "/executive" && role === "individual") {
        history.push("/c_executive");
      }
      requestEDDDictionary();
    }
    if (role === "representative" || role === "legalEntityAgent") {
      requestPermissionsList("?filter[isActive]=true");
    }

    if (!templates) {
      requestClaimTemplates();
    }

    if (!categories) {
      requestClaimTemplatesCat();
    }

    let returnObject = this.props.returnObject.getReturnObject();

    dataSource.clear();
    dataSource.privateFilters = filters || {};
    dataSource.setValue("storeName", this.props.rootComponent + "List");
    dataSource.setValue("isNotDeleted", !trash);

    if (this.props.returnObject.isForm("claims") && !trash) {
      dataSource.filters = returnObject.filters;
      dataSource.sort = returnObject.sort;
      dataSource.search = returnObject.search;
    }

    dataSource.load();
  }

  onSelectAllClick = () => {
    const { checked } = this.state;
    if (!checked.length) {
      return this.setState({
        checked: this.getList()
          .filter((claim) => claim.haveDeletePermission)
          .map((item) => item.id),
      });
    }

    return this.setState({ checked: [] });
  };

  unCheckWithoutPermission = (checked) => {
    const listObject = this.getList().reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item,
      }),
      {}
    );

    return checked.filter(
      (id) => listObject[id] && listObject[id].haveDeletePermission
    );
  };

  onCheckItem = (id) => () => {
    const { checked } = this.state;
    if (checked.includes(id)) {
      checked.splice(checked.indexOf(id), 1);
      return this.setState({ checked });
    }
    this.setState({
      checked: this.unCheckWithoutPermission([...checked, id]),
    });
    return false;
  };

  hasNotEditPermission = (rowData) => {
    const { trash } = this.props;

    const {
      category,
      state,
      digitalDocumentStaticFile,
      haveEditPermission,
      haveDeletePermission,
    } = rowData;
    return trash
      ? !haveDeletePermission
      : !haveEditPermission &&
          (state === 1 || !digitalDocumentStaticFile) &&
          category !== 4;
  };

  saveReturnObject = (claimId) => {
    this.props.returnObject.setReturnObject({
      form: "claims",
      id: claimId,
      filters: dataSource.filters,
      sort: dataSource.sort,
      search: dataSource.search,
      path: window.location.pathname + window.location.search,
    });
  };

  handleClick = (rowData) => () => {
    const { path, history, trash } = this.props;

    if (this.hasNotEditPermission(rowData)) return null;
    if (trash) return this.onCheckItem(rowData.id)();

    this.saveReturnObject(rowData.id);

    return history.push(`${path}/${rowData.id}`);
  };

  changeOwnership = (ownership) => {
    const { representativeClaimant, claimentUserId } = ownership;
    let templatePermissionFilter = [];
    /*
    // Define permissions by justice for filter templates
    // in sortTemlate of
    // home/rynda/src/cabinet-front/src/components/CreateClaimDialog/index.jsx
    */
    if (
      representativeClaimant &&
      claimentUserId &&
      typeof claimentUserId === "string"
    ) {
      const { permissionList } = this.props;
      const commonPermission = (permissionList || []).find(
        (value) =>
          !!value.isActive &&
          value.addClaimPermission &&
          value.allowedBy === claimentUserId &&
          (value.targetType || 0) === 0
      );
      templatePermissionFilter = commonPermission
        ? []
        : (permissionList || []).filter(
            (value) =>
              !!value.isActive &&
              value.allowedBy === claimentUserId &&
              value.targetType === 3
          );
    }
    return this.setState({
      ownership,
      templatePermissionFilter,
    });
  };

  getTemplate = (templateId) =>
    (this.props.templates || [])
      .filter((template) => template.id === templateId)
      .shift() || {};

  handleClaimRecover = () => {
    const { checked } = this.state;

    this.setState({ busy: true });
    Promise.all(checked.map(recoverClaim)).then(() =>
      this.setState(
        {
          busy: false,
          checked: [],
        },
        dataSource.load
      )
    );
  };

  handleClaimDelete = () => {
    const { t, trash } = this.props;
    const action = trash ? permanentDeleteClaim : deleteClaim;

    this.setState({
      blockScreen: true,
      openDeleteClaimDialog: false,
      busy: true,
    });
    Promise.all(this.getIdsForDelete().map(action))
      .then((results) => {
        if (results.filter((result) => result instanceof Error).length) {
          if (!trash) {
            this.setState({ error: t("DELETING_FALSE") });
          }
          setTimeout(() => this.setState({ error: null }), 3000);
        }
        return Promise.resolve();
      })
      .then(() => {
        dataSource.load().then(() =>
          this.setState({
            blockScreen: false,
            busy: false,
            checked: [],
          })
        );
      });
  };

  handleSelectTemplate = (templateId) => async () => {
    this.setState({
      blockScreen: true,
      openCreateClaimDialog: false,
      selectedTemplateId: templateId,
    });

    const { rootComponent, history, t } = this.props;
    const template = await requestTemplate(templateId);

    if (template.message || template.serverMessage || !template.jsonSchema) {
      const message = template.message || template.serverMessage;
      const error = message.includes("JSON.parse")
        ? "Invalid JSON schema."
        : message;

      return this.setState(
        {
          blockScreen: false,
          error: t(error),
        },
        () => setTimeout(() => this.setState({ error: "" }), 5000)
      );
    }
    const { useEncryption } = template.jsonSchema || {};

    if (useEncryption) {
      return this.setState({
        openCreatePermissionDialog: true,
        blockScreen: false,
      });
    }

    if (template?.jsonSchema?.properties?.form?.properties?.text?.maxLength) {
      template.jsonSchema.properties.form.properties.text.maxLength = 1000000000;
    }
    if (template?.jsonSchema?.properties?.attaches?.maxSize) {
      template.jsonSchema.properties.attaches.maxSize = 5368709120;
    }

    // console.log("template: ", template);

    this.setState({
      blockScreen: true,
      template,
    });

    const claim = await this.handleClaimCreate();
    if (claim.message || claim.serverMessage) {
      let error = t(claim.message || claim.serverMessage);
      if (error.includes("ClaimList")) {
        error = t("UNKNOWN_ERROR");
      }
      return this.setState(
        {
          blockScreen: false,
          error,
        },
        () => setTimeout(() => this.setState({ error: "" }), 5000)
      );
    }
    return history.push(`/${rootComponent}/${claim.id}`);
  };

  handleClaimCreate = (passPhrase) => {
    const { ownership, template, templatePermissionFilter } = this.state;
    let addData = null;
    if (
      Array.isArray(templatePermissionFilter) &&
      templatePermissionFilter.length > 0
    ) {
      const permission = templatePermissionFilter.find(
        (value) =>
          parseInt(value.targetId) ===
          template.docTypeCourts[0].jurisdictionType
      );

      if (permission) {
        addData = { permissionId: permission.id };
      }
    }
    return claimCreate(template.id, ownership, addData, null, passPhrase);
  };

  handleSelectKey = async (cert, signer, resetPrivateKey) => {
    const { userInfo, rootComponent, t } = this.props;

    const passPhrase = customPassword();
    const { userId } = userInfo;

    try {
      const { issuer, serial } = await getEncodeCert(signer, 0);
      const targetKey = await signer.execute(
        "EnvelopDataEx",
        [issuer],
        [serial],
        false,
        passPhrase,
        true
      );

      const claim = await this.handleClaimCreate(passPhrase);
      await addEncodeKey(passPhrase, claim.id);
      await addPermission({
        userId,
        targetType: 0, // It's a magic
        permissionType: "allowCommit",
        targetId: claim.id,
        targetKey,
      });

      await resetPrivateKey();

      this.props.history.push(`/${rootComponent}/${claim.id}`);
    } catch (e) {
      this.setState({
        blockScreen: false,
        error: t(e.message || e.serverMessage),
      });
    }
  };

  createSortHandler = (property) => () => {
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }

    dataSource.sort = { [property]: order };
    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
  };

  filter = ({ digitalDocumentTemplateId }) =>
    !!this.getTemplate(digitalDocumentTemplateId);

  pagination = (e, page) => {
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource.load();
  };

  changeCount = ({ target: { value } }) => {
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load();
  };

  getIdsForDelete() {
    const { checked } = this.state;
    const listObject = this.getList().reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item,
      }),
      {}
    );

    return checked.filter((id) => {
      const item = listObject[id];
      return item && hasPermissionForDelete(item);
    });
  }

  render() {
    const { t, filters, list, templates } = this.props;
    const { openCreatePermissionDialog } = this.state;
    const { category: docCategory } = filters || {};
    return (
      <>
        <ClaimLayout
          {...this.props}
          {...this.state}
          docCategory={docCategory}
          list={list && templates ? this.withMeta(this.getList()) : null}
          idsForDelete={this.getIdsForDelete()}
          dataSource={dataSource}
          toggleTemplateDialog={this.toggleTemplateDialog}
          toggleDeleteDialog={this.toggleDeleteDialog}
          handleClaimRecover={this.handleClaimRecover}
          onSelectAllClick={this.onSelectAllClick}
          createSortHandler={this.createSortHandler}
          getTemplate={this.getTemplate}
          onCheckItem={this.onCheckItem}
          handleClick={this.handleClick}
          handleSearch={this.handleSearch}
          handleClaimCreate={this.handleSelectTemplate}
          clearSearch={this.clearSearch}
          handleClaimDelete={this.handleClaimDelete}
          pagination={this.pagination}
          changeCount={this.changeCount}
          changeOwnership={this.changeOwnership}
          togglePermissionDialog={this.togglePermissionDialog}
          closeDialog={this.closeDialog}
          load={dataSource.load}
          owners={this.getOwners()}
        />
        <Dialog
          open={openCreatePermissionDialog}
          maxWidth="md"
          scroll="body"
          onClose={() =>
            this.setState({
              openCreatePermissionDialog: false,
              selectedTemplateId: null,
            })
          }
        >
          <DialogTitle>{t("ENVELOP_USES")}</DialogTitle>
          <DialogContent>
            <EDSForm onSelectKey={this.handleSelectKey} />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

ClaimList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  userInfo: PropTypes.object.isRequired,
  permissionList: PropTypes.array,
  itemShareList: PropTypes.array,
  templates: PropTypes.array,
  filters: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  categories: PropTypes.array,
  trash: PropTypes.bool,
  title: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  rootComponent: PropTypes.string.isRequired,
  loadingPermissions: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  roleInfo: PropTypes.string.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
};

ClaimList.defaultProps = {
  list: null,
  permissionList: [],
  itemShareList: [],
  templates: [],
  filters: null,
  categories: null,
  trash: false,
  courts: null,
};

const ClaimListComponent = compose(
  withStyles(styles),
  translate("ClaimList"),
  ReturnAndLocateInTableHOC
)(ClaimList);

const mapStateToProps = ({
  claim: { templates, categories, statuses, loading, ...rest },
  permissions: { list: permissionList, itemShareList, loadingPermissions },
  authorization: { info: userInfo, role, roleInfo },
  dictionary: { courts },
}) => {
  return {
    list: dataSource.getList(rest),
    templates,
    categories,
    permissionList,
    statuses,
    loading,
    userInfo,
    itemShareList,
    courts,
    loadingPermissions,
    role,
    roleInfo,
  };
};

// decorate and export
export default connect(mapStateToProps)(ClaimListComponent);
