import { useState, useEffect, FC } from "react";
import { IAllowBy, IOwner } from "../../../pages/Claim/types";
import { PermissionType } from "../../../pages/Share/components/OrderDialog/types";

import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import cx from "classnames";
import { formatUserName } from "../../../helpers/userName";
// import color from "@material-ui/core/colors/amber";

const isOrder = (claimentSelectMode: any) => claimentSelectMode === "order";
const filterActive = (items: IOwner[]) => items.filter((item) => item.isActive);

type ClaimentSelectMode = "select" | "order" | string;

type Props = {
  classes: any;
  t: (key?: any) => any;
  setId: (key?: any) => any;
  owners?: IOwner[] | any[];
  ownersFilter?: (owners: any[], claimentSelectMode: any) => any[];
  value?: any;
  setOwnerId: (key?: any) => any;
  claimentSelectMode: ClaimentSelectMode;
};

const SelectClaiment: FC<Props> = ({
  classes,
  t,
  setId,
  owners,
  ownersFilter,
  value: { claimentUserId },
  setOwnerId,
  claimentSelectMode,
}) => {
  const [isCurrentOrder, setIsCurrentOrder] = useState(false);

  useEffect(() => {
    setIsCurrentOrder(isOrder(claimentSelectMode));
  }, [claimentSelectMode]);

  const defaultOwnersFilter = (owners: any[], claimentSelectMode: any) => {
    const thisIsOrder = isOrder(claimentSelectMode);

    const filteringOwners = thisIsOrder
      ? owners.reduce((acc: any, item: any) => {
          if (item.allowCommit && item.typeId === PermissionType.Order) {
            acc.push(item);
          }
          return acc;
        }, [])
      : owners.reduce((acc: any, item: any) => {
          if (
            item.allowCommit &&
            (item.typeId === PermissionType.Default ||
              item.typeId === PermissionType.EDRRecipe)
          ) {
            acc.push(item);
          }
          return acc;
        }, []);

    return filterActive(filteringOwners);
  };

  let filteringOwners = ownersFilter
    ? ownersFilter(owners || [], claimentSelectMode)
    : defaultOwnersFilter(owners || [], claimentSelectMode);

  const selected = !!filteringOwners.length;
  const label = !isOrder(claimentSelectMode)
    ? t("PERMISSION_LABEL")
    : t("PERMISSION_LABEL_ORDER");
  const noValuesText = !isOrder(claimentSelectMode)
    ? t("NOT_HAVE_PERMISSIONS")
    : t("NOT_HAVE_ORDER_PERMISSIONS");
  const helperText = isOrder(claimentSelectMode)
    ? t("SHARE_PERMISSION_HELPER_TEXT")
    : "";

  // console.log("filteringOwners: ", filteringOwners);

  return (
    <FormControl className={classes.formControl} id={setId("select-wrap")}>
      {selected && isCurrentOrder && (
        <>
          <InputLabel id="test-select-label">{label}</InputLabel>
          <Select
            id={setId("select")}
            onChange={setOwnerId}
            value={claimentUserId || ""}
            renderValue={(value: IAllowBy | any) => {
              return value
                ? `${value?.companyName ? value?.companyName : value?.name}`
                : "";
            }}
            name="owner"
            className={cx(classes.margin, classes.textField, classes.flex)}
          >
            {filteringOwners.map((infoItem: any, i: number) => {
              const metaData = infoItem.meta;
              let text;
              let fullClaimentUserId: {
                claimentUserId: any;
                permissionId: any;
              } = {
                claimentUserId: "",
                permissionId: "",
              };
              if (metaData?.allowedBy) {
                fullClaimentUserId = {
                  claimentUserId: metaData.allowedBy,
                  permissionId: infoItem.id,
                };
                text = metaData?.allowedBy?.companyName
                  ? metaData?.allowedBy?.companyName
                  : metaData?.allowedBy?.name;
              } else {
                fullClaimentUserId = {
                  claimentUserId: infoItem.allowedBy,
                  permissionId: infoItem.id,
                };
                text = infoItem?.allowedByName;
              }

              return (
                <MenuItem
                  key={`${infoItem.allowedBy}-${i}`}
                  // @ts-ignore
                  value={fullClaimentUserId || ""}
                  id={`select-allowedBy-${infoItem.allowedBy}-${i}`}
                >
                  {formatUserName(text)}
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
          <a
            href="https://wiki-ccs.court.gov.ua/w/%D0%9E%D1%81%D0%BE%D0%B1%D0%BB%D0%B8%D0%B2%D0%BE%D1%81%D1%82%D1%96_%D0%BF%D0%BE%D0%B4%D0%B0%D1%87%D1%96_%D0%B2%D0%B8%D0%BA%D0%BE%D0%BD%D0%B0%D0%B2%D1%87%D0%B8%D1%85_%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%96%D0%B2_%D0%B4%D0%BE_%D0%90%D0%A1%D0%92%D0%9F"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: 12,
              color: "black",
              textDecoration: "underline",
              fontWeight: 700,
              cursor: "pointer",
              lineHeight: 1.2,
            }}
          >
            Увага! Для подачі представниками електронних виконавчих документів
            до АСВП виписки з ЄДР та довіреності повинні бути підписані після
            13.02.2024.
          </a>
        </>
      )}

      {selected && !isCurrentOrder && (
        <>
          <InputLabel id="test-select-label">{label}</InputLabel>
          <Select
            id={setId("select")}
            onChange={setOwnerId}
            value={claimentUserId || ""}
            name="owner"
            className={cx(classes.margin, classes.textField, classes.flex)}
            renderValue={(value: IAllowBy | any) => {
              return value
                ? `${
                    filteringOwners.find(
                      (item: any) => item.allowedBy === value
                    )?.allowedByName
                  }`
                : "";
            }}
          >
            {filteringOwners.map(
              ({ id, allowedBy, allowedByName, meta }: any, i: number) => {
                let fullClaimentUserId: {
                  claimentUserId: any;
                  permissionId: any;
                } = {
                  claimentUserId: allowedBy || meta?.allowedBy,
                  permissionId: id,
                };

                return (
                  <MenuItem
                    key={`${allowedBy}-${i}`}
                    // @ts-ignore
                    value={fullClaimentUserId || ""}
                    id={`select-allowedBy-${allowedBy}-${i}`}
                  >
                    {formatUserName(allowedByName)}
                  </MenuItem>
                );
              }
            )}
          </Select>
          <FormHelperText>{helperText}</FormHelperText>
          <a
            href="https://wiki-ccs.court.gov.ua/w/%D0%9E%D1%81%D0%BE%D0%B1%D0%BB%D0%B8%D0%B2%D0%BE%D1%81%D1%82%D1%96_%D0%BF%D0%BE%D0%B4%D0%B0%D1%87%D1%96_%D0%B2%D0%B8%D0%BA%D0%BE%D0%BD%D0%B0%D0%B2%D1%87%D0%B8%D1%85_%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%96%D0%B2_%D0%B4%D0%BE_%D0%90%D0%A1%D0%92%D0%9F"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              fontSize: 12,
              color: "black",
              textDecoration: "underline",
              fontWeight: 700,
              cursor: "pointer",
              lineHeight: 1.2,
            }}
          >
            Увага! Для подачі представниками електронних виконавчих документів
            до АСВП виписки з ЄДР та довіреності повинні бути підписані після
            13.02.2024.
          </a>
        </>
      )}

      {!selected && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {noValuesText}
        </Typography>
      )}
    </FormControl>
  );
};

SelectClaiment.defaultProps = {
  owners: [],
  value: {},
};

export default SelectClaiment;
