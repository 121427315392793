import { AUTH_SET_TOKEN, TOKEN_ERROR } from "../actions/auth";
// import { SET_USER_INFO } from "../actions/auth";
// import checkAuthPhoneValidation from 'triggers/checkAuthPhoneValidation';
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";

export const REQUEST_USER_INFO_FAIL = "REQUEST_USER_INFO_FAIL";
export const REQUEST_USER_INFO_SUCCESS = "REQUEST_USER_INFO_SUCCESS";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";

export const SET_USER_PHONE_VALID_SUCCESS = "SET_USER_PHONE_VALID_SUCCESS";
export const REQUEST_AUTH_MODE_SUCCESS = "REQUEST_AUTH_MODE_SUCCESS";
export const SET_ROLE = "SET_ROLE";
export const GET_ROLE = "GET_ROLE";
export const POST_ROLE = "POST_ROLE";
export const DB_ERROR = "DB_ERROR";
export const ERROR_503 = "ERROR_503";
export const AUTH_ERROR = "AUTH_ERROR";
export const DIRECT_UPDATE = "DIRECT_UPDATE";

const initialState = {
  token: null,
  info: null,
  useTwoFactorAuth: false,
  foundUser: null,
  tokenError: false,
  role: undefined,
  roleInfo: undefined,
  DBError: false,
  ERROR_503: false,
  authError: null,
};

const getInfo = (payload) => {
  const data = payload || {};
  const { companyName } = data;
  if (data && data.services && data.services.eds && data.services.eds.data) {
    const {
      postalCode,
      localityName,
      stateOrProvinceName,
      streetAddress,
      organizationName,
      title: CEOName,
    } = data.services.eds.data;
    const state = stateOrProvinceName
      ? `${capitalizeFirstLetter(stateOrProvinceName)} область, `
      : "";
    const city = localityName ? `${localityName} ` : "";
    const street = streetAddress ? `,${streetAddress} ` : "";
    const address = postalCode ? `,${postalCode}` : "";
    data.subAddress = `${state}${city}${street}${address}`;
    data.companyName = organizationName || companyName || "";
    data.CEOName = CEOName;
  }
  return data;
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_TOKEN:
      return { ...state, token: action.payload, tokenError: false };
    case REQUEST_USER_INFO_SUCCESS:
      return {
        ...state,
        role: action.payload.role,
        info: getInfo(action.payload),
      };
    case REQUEST_USER_INFO_FAIL:
      return initialState;
    case REQUEST_AUTH_MODE_SUCCESS:
      return {
        ...state,
        useTwoFactorAuth: action.payload.useTwoFactorAuth,
      };
    case SEARCH_USER_SUCCESS:
      return {
        ...state,
        foundUser: (action.payload.users || [])[0] || null,
      };
    case UPDATE_USER_INFO:
    case SET_USER_PHONE_VALID_SUCCESS:
      return { ...state, info: { ...state.info, ...action.payload } };
    case TOKEN_ERROR:
      return { ...state, tokenError: action.payload };
    case GET_ROLE:
      return { ...state, roleInfo: action.payload, authErro: null };
    case POST_ROLE:
      return {
        ...state,
        role: action.payload.role,
        info: getInfo(action.payload),
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.payload.role,
        roleInfo: action.payload,
        authErro: null,
      };
    case DB_ERROR:
      return { ...state, DBError: action.payload };
    case ERROR_503:
      return { ...state, ERROR_503: action.payload };
    case AUTH_ERROR:
      return { ...state, authError: action.payload };
    case DIRECT_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
export default rootReducer;
