import { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import { translate } from "react-translate";

import setComponentsId from "../../helpers/setComponentsId";
import styles from "../../variables/styles/executive";

import checkECP from "../../helpers/checkECP";
import { downloadScanFile, downloadStaticFile } from "../../actions/cases";
import { downloadScanArchive } from "../../actions/cases";
import { downloadDocumentAttach } from "../../actions/claim";
import blobToBase64 from "../../helpers/blobToBase64";
import { blobToTextEncoded } from "../../helpers/blobToText";
import stringToBlob from "../../helpers/stringToBlob";
import formatFile from "../../helpers/formatFile";
import formatSource from "../../helpers/formatSource";
import getFileUrl from "../../helpers/getFileUrl";
import getFormat from "../../helpers/getAttachFormat";
import { usePrevious } from "../../hooks/usePrevious";

import {
  getDocumentInfo,
  getExecInfo,
  getExecOrganizations,
  getExecPaymentForm,
  getExecPermissions,
  sendToASVP,
  setExecStatus,
  downloadExecStaticArchive,
} from "../../actions/executive-docs";

import getUrlParams from "../../helpers/getUrlParams";
import getDocumentFileName from "../../helpers/getDocumentFileName";

import PreviewDialog from "../../components/Attach/PreviewDialog";
import DocumentWizard from "../../components/DocumentWizard";
import ExecutiveInfo from "./components/ExecutiveInfo";
import PermissionsDialog from "../../components/PermissionsDialog";
import AttachList from "../../components/Attach/AttachList";
import Preview from "../../components/Attach/Preview";
import { RegularCard, Preloader } from "../../components";

import { ExecutiveActionButtons } from "./components/ExecutiveActionButtons";
import { ExecutiveAdditionalButtons } from "./components/ExecutiveAdditionalButtons";

import { ExecutiveCardTitle } from "./components/ExecutiveCardTitle";
import { ExecutiveDialogError } from "./components/ExecutiveDialogError";

import { useEffect } from "react";
const HIDDEN_FOR_NOT_PAYED = ["video", "audio", "compressed"];

const ExecutiveDocView = ({
  t,
  setId,
  classes,
  courts,
  previewAttach,
  history,
  match: {
    params: { id },
  },
  role,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isWizardActive, setIsWizardActive] = useState(false);
  const [openedDialog, setOpenedDialog] = useState(false);
  const [showPermissionDialog, setShowPermissionDialog] = useState(false);
  const [closedPermissionDialog, setClosedPermissionDialog] = useState(false);

  const [activeExecutive, setActiveExecutive] = useState(null);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [attaches, setAttaches] = useState([]);
  // const [claim, setClaim] = useState(null);
  const [ownership, setOwnership] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const [permissions, setPermissions] = useState(null);

  const [newPageUrl, setNewPageUrl] = useState("");
  const [docSignPDF, setDocSignPDF] = useState(null);

  const [isResendClaim, setIsResendClaim] = useState(false);

  const [previewDoc, setPreviewDoc] = useState({
    doc: null,
    text: null,
    url: "",
    format: "",
    source: null,
    size: null,
    formatedSource: null,
  });
  const [previewDialog, setPreviewDialog] = useState({
    url: "",
    blob: null,
    base64: "",
  });

  const prevRole = usePrevious({ role });

  const { url, blob, base64 } = previewDialog;

  // Permissions actions

  const getPermissions = async (execId, execMyRole) => {
    return checkExecRole(execMyRole, "collectorRepresentative")
      ? await getExecPermissions(execId, "collectorRepresentative")
      : [];
  };

  const checkExecRole = (execMyRole, checkRole) => {
    return Array.isArray(execMyRole)
      ? execMyRole.includes[checkRole]
      : execMyRole === checkRole;
  };

  const changeOwnership = (ownership) => {
    ownership.claimentUserId && setOwnership(ownership);
  };

  const closePermissionDialog = () => {
    setShowPermissionDialog(false);
    setClosedPermissionDialog(false);
  };

  const togglePermissionDialog = async () => {
    if (showPermissionDialog) {
      setShowPermissionDialog(false);
      setClosedPermissionDialog(true);
    }
  };

  useEffect(async () => {
    if (!showPermissionDialog && closedPermissionDialog) {
      setClosedPermissionDialog(false);

      const loadedPermissions = await getPermissions(
        id,
        activeExecutive?.execMyRole
      );
      setPermissions(loadedPermissions);

      let loadedOrganizations = [];
      if (!organizations) {
        loadedOrganizations = await getExecOrganizations();
        setOrganizations(loadedOrganizations);
      }

      const { execMyRole } = activeExecutive;
      const execRoles = Array.isArray(execMyRole)
        ? execMyRole
        : execMyRole.split(",");

      setOwnership({
        ...ownership,
        claimant: true,
        representativeClaimant: execRoles.includes("collectorRepresentative"),
      });

      await runWizard();
      setIsResendClaim(false);
    }
  }, [showPermissionDialog, closedPermissionDialog]);

  // end of Permissions actions

  const loadData = async () => {
    setLoading(true);

    const activeExecutive = await getExecInfo(id);
    if (!activeExecutive?.id) {
      return history.replace("/");
    }
    setActiveExecutive(activeExecutive);

    const documentInfo = await getDocumentInfo(activeExecutive?.docId);
    setDocumentInfo(documentInfo);

    const loadedPermissions = await getPermissions(
      id,
      activeExecutive?.execMyRole
    );
    setPermissions(loadedPermissions);

    let file = await downloadScanFile(activeExecutive.docId);
    if (
      !!file.size &&
      !file.message &&
      (typeof file === "string" || typeof file === "object")
    ) {
      if (typeof file === "string") {
        file = stringToBlob(file);
      }
      const [doc, text, docFile] = await Promise.all([
        blobToBase64(file),
        blobToTextEncoded("Windows-1251")(file),
        file,
      ]);
      const source = await formatSource(docFile, text);
      const formatedSource = await formatFile(source, text);
      const format = await getFormat(formatedSource, text);
      const url = await getFileUrl(formatedSource, format, text);

      setPreviewDoc({
        ...previewDoc,
        doc,
        text,
        url,
        format,
        source,
        formatedSource,
      });
      setNewPageUrl(url);
    } else if (!file.size) {
      console.log("!file.size: ", !file.size);
      setPreviewDoc({
        ...previewDoc,
        text: t("EMPTY_FILE"),
        format: "text",
      });
    }
    setLoading(false);
  };

  useEffect(async () => {
    await loadData();
  }, []);

  useEffect(async () => {
    if (!role) return;

    setLoading(true);
    if (role && prevRole && role !== prevRole) {
      await loadData();
    }
    setLoading(false);
  }, [role]);

  // Buttons actions

  const goToExecPayment = async () => {
    try {
      const formData = await getExecPaymentForm(activeExecutive?.id);
      if (formData instanceof Error) throw formData;

      const form = document.createElement("form");
      form.setAttribute("method", formData.method);
      form.setAttribute("action", formData.url);

      const parsed = getUrlParams(formData.body);

      Object.keys(parsed).forEach((key) => {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "string");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", parsed[key]);
        form.appendChild(hiddenField);
      });

      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "string");
      hiddenField.setAttribute("name", "back_url");
      hiddenField.setAttribute("value", form.baseURI);
      form.appendChild(hiddenField);

      const btn = document.createElement("input");
      btn.value = "продовжити";
      btn.setAttribute("type", "submit");
      form.appendChild(btn);

      document.body.appendChild(form);
      form.submit();
    } catch (error) {
      setError(`${t("PAYMENT_ERROR")} ${error.message}`);
    }
  };

  const openPreviewASVP = async () => {
    setOpenedDialog(true);

    try {
      let file = await downloadStaticFile(activeExecutive?.statusDocId);
      const [doc, text, docFile] = await Promise.all([
        blobToBase64(file),
        blobToTextEncoded("Windows-1251")(file),
        file,
      ]);

      const source = await formatSource(docFile, text);
      const formatedSource = await formatFile(source, text);
      const format = await getFormat(formatedSource, text);
      const url = await getFileUrl(formatedSource, format, text);

      setPreviewDialog({
        url: url,
        blob: formatedSource,
        base64: doc,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  const handleResendExec = () => async (claim) => {
    try {
      const newStatus = await setExecStatus(activeExecutive?.id, {
        statusId: 1,
        statusDocId: claim.id,
        actionName: "resetStatusDocTo",
      });

      if (newStatus instanceof Error) throw newStatus;
      setActiveExecutive(newStatus);
      // setClaim(claim);
    } catch (error) {
      setError(t("SET_STATUS_ERROR"));
      setIsWizardActive(false);
    } finally {
      setLoading(false);
    }
    setLoading(true);
    try {
      setIsResendClaim(true);

      const { execMyRole } = activeExecutive;
      const execRoles = Array.isArray(execMyRole)
        ? execMyRole
        : execMyRole.split(",");

      if (execRoles.includes("collector")) {
        setClosedPermissionDialog(true);
        await runWizard();
      } else {
        setOwnership({
          ...ownership,
          claimant: true,
          representativeClaimant: execRoles.includes("collectorRepresentative"),
        });
        setShowPermissionDialog(true);
        setClosedPermissionDialog(true);
      }
      // setIsWizardActive(true);
    } catch (error) {
      setError(`${t("RESUBMISSION_ERROR")}: ${error.message}`);
      setIsWizardActive(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitExec = () => async () => {
    const { statusDocId, execMyRole } = activeExecutive;
    const execRoles = Array.isArray(execMyRole)
      ? execMyRole
      : execMyRole.split(",");

    if (statusDocId) {
      await runWizard();
    } else {
      setOwnership({
        ...ownership,
        claimant: true,
        representativeClaimant: execRoles.includes("collectorRepresentative"),
      });
      setShowPermissionDialog(true);
      setClosedPermissionDialog(true);
    }
  };

  // end of Buttons actions

  // Document wizard actions

  const runWizard = async () => {
    setLoading(true);

    try {
      let loadedOrganizations = [];
      if (!organizations) {
        loadedOrganizations = await getExecOrganizations();
        setOrganizations(loadedOrganizations);
      }
      setIsWizardActive(true);
    } catch (error) {
      setError(`Помилка подачі на виконання: ${error.message}`);
      setIsWizardActive(false);
    } finally {
      setLoading(false);
    }
  };

  const handleWizardClose = async () => {
    setIsWizardActive(false);
    setLoading(false);
  };

  const handleWizardReturn = async () => {
    this.handleWizardClose();
  };

  // end of Document wizard actions

  // Attaches actions

  const downloadAttach = (attach) => () =>
    downloadDocumentAttach(documentInfo?.id, attach?.attachId, attach).then(
      (data) => data
    );

  useEffect(() => {
    let loadedAttaches = [];
    if (documentInfo) {
      const { extActionType, extState, attaches } = documentInfo;
      if (extActionType === 1 && extState !== 1) {
        loadedAttaches = attaches.filter((attach) => {
          for (const hideType of HIDDEN_FOR_NOT_PAYED) {
            if (attach.contentType.includes(hideType)) return false;
          }
          return true;
        });
      } else {
        loadedAttaches = [...attaches];
      }
    }
    setAttaches(loadedAttaches);
  }, [documentInfo]);

  // end of Attaches actions

  // Claim actions

  // useEffect(() => {
  //   if (claim) console.log("claim: ", claim);
  // }, [claim]);

  const handleClaimCommited = async () => {
    const { id } = activeExecutive;

    setLoading(true);

    try {
      await sendToASVP(id);
      const activeExecutive = await getExecInfo(id);
      setActiveExecutive(activeExecutive);
      setIsWizardActive(false);
    } catch (error) {
      setError(error.message);
      setIsWizardActive(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClaimLoaded = async (claim) => {
    // setClaim(claim);
    setLoading(false);
  };

  const claimToAsvpPdfZipDownloadCallback = async () => {
    try {
      const blob = await downloadExecStaticArchive(
        activeExecutive?.statusDocId
      );
      if (blob.status === 500) {
        return alert(blob.serverMessage + ", " + blob.details);
      }
      const name = getDocumentFileName(activeExecutive);
      const { document } = window;
      const URL = window.URL || window.webkitURL;
      const element = document.createElement("a");
      element.setAttribute("href", URL.createObjectURL(blob));
      element.setAttribute("download", name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } catch (error) {
      if (error instanceof Error) {
        return alert(error.serverMessage + " " + error.details);
      }
    }
  };
  // end of Claim actions

  // Document preview actions

  const checkKey = async () => {
    setLoading(true);
    if (docSignPDF) return;
    try {
      const checked = await checkECP(documentInfo?.id);
      setDocSignPDF(checked);
    } catch (e) {
      console.log("checkECP error: ", e.message || "404 File not found");
    } finally {
      setLoading(false);
    }
  };

  const download = async () => {
    setLoading(true);
    try {
      const blob = await downloadScanArchive(documentInfo?.id);
      const name = getDocumentFileName(activeExecutive);
      const { document } = window;
      const URL = window.URL || window.webkitURL;

      const element = document.createElement("a");
      element.setAttribute("href", URL.createObjectURL(blob));
      element.setAttribute("download", name);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    } catch (e) {
      console.log("download error: ", e.message || "404 File not found");
    } finally {
      setLoading(false);
    }
  };

  // end Document preview actions
  const handleClaimCreated = async (claim) => {
    try {
      const newStatus = await setExecStatus(activeExecutive?.id, {
        statusId: activeExecutive?.statusId,
        statusDocId: claim.id,
        actionName: "resetStatusDocTo",
      });
      if (newStatus instanceof Error) throw newStatus;
      setActiveExecutive(newStatus);
      // setClaim(claim);
    } catch (error) {
      setError(t("SET_STATUS_ERROR"));
      setIsWizardActive(false);
    } finally {
      setLoading(false);
    }
  };

  const clearError = () => {
    setError("");
  };

  const isDeleted = activeExecutive?.isDeleted;

  let errorSubText = "";
  if (error) {
    errorSubText = "Error text";
  }

  const getExecutiveCallback = async () => {
    await loadData();
  };

  return (
    <RegularCard
      headerColor="primary"
      cardTitle={
        <ExecutiveCardTitle
          classes={classes}
          setId={setId}
          activeExecutive={activeExecutive}
        />
      }
      cardSubtitle={errorSubText}
      setId={setId}
    >
      {loading && <Preloader />}
      {isWizardActive && (
        <DocumentWizard
          caseId={activeExecutive?.caseId}
          context={{
            courts,
            activeExecutive,
            organizations,
          }}
          courtId={activeExecutive?.courtId || 874}
          documentId={activeExecutive?.statusDocId}
          isResendClaim={isResendClaim}
          isRepresentative={
            activeExecutive?.execMyRole === "collectorRepresentative"
          }
          permissionId={ownership?.permissionId}
          onClose={handleWizardClose}
          onDocumentCommit={handleClaimCommited}
          onDocumentCreated={handleClaimCreated}
          onDocumentLoaded={handleClaimLoaded}
          onReturn={handleWizardReturn}
          owner={ownership || {}}
          proceedingId={activeExecutive?.proceedingId}
          returnToText={t("REDIRECT_TO_EXECUTIVE")}
          setId={setId}
          t={t}
          templateId={activeExecutive?.claimTemplateId}
        />
      )}
      {showPermissionDialog && (
        <PermissionsDialog
          allowAttachOrder={false}
          allowAttachFile={false}
          busy={loading}
          closeDialog={closePermissionDialog}
          disabledPersonally={ownership?.representativeClaimant}
          open={showPermissionDialog}
          onChange={changeOwnership}
          owners={permissions}
          ownersFilter={() => permissions}
          setId={(elementName) => setId(`permissions-dialog-${elementName}`)}
          togglePermissionDialog={togglePermissionDialog}
          value={ownership || {}}
        />
      )}
      {!loading && !isWizardActive && (
        <>
          <div className={classes.executiveInfo}>
            <ExecutiveInfo
              t={t}
              classes={classes}
              activeDoc={activeExecutive}
              history={history}
              getExecutiveCallback={getExecutiveCallback}
            />
            <div className={classes.previewContainer}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {/* --- M E S S A G E --- */}
                  <div className={isDeleted ? classes.message : classes.dNone}>
                    {isDeleted === 1
                      ? t("DELETED_BY_COURT")
                      : isDeleted === 2
                      ? t("ANNULLED_BY_COURT")
                      : ""}
                  </div>
                  {/* --- B U T T O N S --- */}
                  <ExecutiveActionButtons
                    classes={classes}
                    t={t}
                    setComponentsId={setComponentsId}
                    text={previewDoc?.text}
                    activeExecutive={activeExecutive}
                    permissions={permissions}
                    goToExecPayment={() => goToExecPayment()}
                    openPreviewASVP={() => openPreviewASVP()}
                    handleResendExec={handleResendExec()}
                    handleSubmitExec={handleSubmitExec()}
                  />
                </div>

                <ExecutiveAdditionalButtons
                  classes={classes}
                  t={t}
                  documentInfo={documentInfo}
                  checkKey={checkKey}
                  download={download}
                  newPageUrl={newPageUrl}
                />
              </div>

              {/* --- D I A L O G --- */}
              <PreviewDialog
                format={"pdf"}
                file={blob}
                url={url}
                doc={base64}
                openDialog={openedDialog}
                toggleDialog={() => setOpenedDialog(false)}
                setId={setComponentsId(`PreviewDialog-Claim-To-ASVP`)}
                activeExecutive={activeExecutive}
                pdfZipDownloadCallback={claimToAsvpPdfZipDownloadCallback}
              />
              <Preview
                doc={previewDoc?.doc}
                text={previewDoc?.text}
                url={previewDoc?.url}
                size={previewDoc?.size}
                format={previewDoc?.format}
                source={previewDoc?.formatedSource}
              />
            </div>
          </div>
          <AttachList
            attaches={attaches}
            handleDownload={downloadAttach}
            requestPreview={previewAttach}
            needTitle
          />
        </>
      )}
      {!!error && (
        <ExecutiveDialogError error={error} t={t} clearError={clearError} />
      )}
      {docSignPDF && (
        <PreviewDialog
          openDialog={!!docSignPDF}
          toggleDialog={() => setDocSignPDF(null)}
          setId={(name) => setId(`PreviewDialog - ${name} `)}
          printHistory={null}
          {...(docSignPDF || {})}
        />
      )}
    </RegularCard>
  );
};

ExecutiveDocView.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  previewAttach: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ExecutiveDocView.defaultProps = {
  activeClaim: null,
  children: "",
  previewAttach: null,
};

const mapStateToProps = ({
  dictionary: { courts },
  authorization: { info: userInfo, role: prevRole, roleInfo },
}) => ({
  courts,
  userInfo,
  role: roleInfo?.code || prevRole,
});

const translated = compose(
  withStyles(styles),
  translate("ExecutiveDocs"),
  connect(mapStateToProps),
  withRouter
)(ExecutiveDocView);

export default translated;
