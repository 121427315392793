import qs from "qs";
import moment from "moment";
import objectPath from "object-path";
import * as api from "../services/api";
import store from "../store";
import { addDataSource } from "../actions";

import numerateValue from "../helpers/numerateValue";

let stores = {};

export class DataSourceClass {
  data = [];
  busy = false;
  totalCount = 0;

  start = 0;
  count = 20;
  page = 0;
  search = "";
  filters = {};
  privateFilters = {};
  sort = {};
  isNotDeleted = true;
  params = {};
  storeName = null;
  actionName = null;
  subName = null;
  handleChange = () => {};
  aggs = {};

  constructor(baseUrl, subName, options = {}) {
    this.baseUrl = baseUrl;
    this.subName = subName;
    if (options.storeName) this.storeName = options.storeName;
    if (options.actionName) this.storeName = options.storeName;
  }

  reset = () => {
    this.page = 0;
    this.start = 0;
    this.search = "";
    this.filters = {};
    this.handleChange();
  };

  clear = () => this.reset();

  updateFilters = () => {
    const updatedFilters = { ...this.filters, ...this.privateFilters };

    if (updatedFilters.createdAt) {
      const parts = updatedFilters.createdAt.split(",");
      if (parts.length === 1) {
        this.filters.createdAt = {};
        delete updatedFilters.createdAt;
      }
      if (parts.length === 2) {
        updatedFilters.createdAt = `${parts[0]},${
          parts[1] ? moment(parts[1]).add(1, "days").format("YYYY-MM-DD") : ""
        }`;
      }
    }

    if (updatedFilters.docDate) {
      const parts = updatedFilters.docDate.split(",");
      if (parts.length === 1) {
        this.filters.docDate = {};
        delete updatedFilters.docDate;
      }
      if (parts.length === 2) {
        updatedFilters.docDate = `${parts[0]},${moment(parts[1])
          .add(1, "days")
          .format("YYYY-MM-DD")}`;
      }
    }

    if (updatedFilters.updatedAt) {
      // 86400000 - milliseconds in 1 day
      // фикс для фильтров за конкретную дату в "Державні Реєстри"
      const parts = updatedFilters.updatedAt.split(",");
      if (parts.length === 1) {
        this.filters.updatedAt = {};
        delete updatedFilters.updatedAt;
      }
      if (parts.length === 2) {
        updatedFilters.updatedAt = `${parts[0]},${moment(parts[1])
          .add(1, "days") // P.S выбираем конкретную дату, например 07.04.2023
          .format("YYYY-MM-DD")}`; // а на бек шлем filter%5BupdatedAt%5D: 2023-04-07%2C2023-04-08
      }
    }

    if (updatedFilters.dateForce) {
      const parts = updatedFilters.dateForce.split(",");
      if (parts.length === 1) {
        this.filters.dateForce = {};
        delete updatedFilters.dateForce;
      }
      if (parts.length === 2) {
        updatedFilters.dateForce = `${parts[0]},${moment(parts[1])
          .add(1, "days")
          .format("YYYY-MM-DD")}`;
      }
    }

    if (updatedFilters.paymentInfoSumm) {
      const parts = updatedFilters.paymentInfoSumm.split("-");
      updatedFilters.paymentInfoSumm = `${parts[0]},${parts[1]}`;
    }
    return updatedFilters;
  };

  load = (id) => {
    return api
      .get(this.getUrl(id || this.id), this.getActionName(), store.dispatch)
      .then((req) => {
        if (req.data instanceof Error) {
          return Promise.reject(req.data);
        }

        this.busy = false;
        this.totalCount = req.total;
        this.data = req.data;

        return Promise.resolve({
          data: req.data,
          total: req.total,
          meta: req.meta,
          req,
        });
      });
  };

  setBaseUrl(newUrl) {
    this.baseUrl = newUrl;
    return this;
  }

  setFilter(name, value, handleChange = true) {
    if (value) {
      objectPath.set(this.filters, name, value);
    } else {
      objectPath.del(this.filters, name);
    }
    handleChange && this.handleChange(name, value);
  }

  setSearch(value) {
    this.search = value || undefined;
    this.handleChange();
  }

  setValue(valueName, value) {
    this[valueName] = value;
    this.handleChange();
  }

  getList = (source) =>
    source && this.storeName ? source[this.storeName] : null;

  getActionName = () =>
    this.actionName ||
    `DATA_SOURCE/REQUEST_${this.baseUrl.toUpperCase().replace("/", "_")}${
      this.subName ? `_${this.subName.toUpperCase()}` : ""
    }`;

  getUrl = (id) => {
    const url = `${this.baseUrl}${id ? `/${id}` : ""}`;
    return `${url}?${this.getQueryString()}`;
  };

  getQueryString = () =>
    qs.stringify(this.serialize(), { arrayFormat: "index" });

  deserialize = (queryString) => this.setFilters(qs.parse(queryString));

  serialize = () =>
    Object.assign(
      Object.keys(this.params).reduce((acc, key) => {
        acc[key] = this.params[key];
        return acc;
      }, {}),
      this.isNotDeleted !== "off"
        ? this.isNotDeleted
          ? { is_not_deleted: 1 }
          : { is_deleted: 1 }
        : null, // filter 2531
      this.search && { search: this.search },
      {
        start: this.start,
        count: this.count,
        aggs: this.aggs,
        filter: this.updateFilters(),
        sort: this.sort,
      }
    );

  setFilters = ({
    count,
    filter,
    // is_not_deleted,
    sort,
    start,
    search,
    aggs,
  }) => {
    this.search = search || "";
    this.filters = numerateValue(filter || {});
    this.sort = sort || {};
    this.aggs = aggs || {};
    this.count = parseInt(count || 20, 10);
    this.start = parseInt(start || 0, 10);
    this.page = this.start / this.count;
  };

  reducer = (initialState) => (state, data) => {
    const storeName = this.storeName || "list";

    if (data.type === this.getActionName()) {
      return {
        ...state,
        loading: true,
      };
    }

    if (data.type === this.getActionName() + "_LOADING") {
      return {
        ...state,
        loading: true,
      };
    }

    if (data.type === this.getActionName() + "_FAIL") {
      return {
        ...state,
        [storeName]: [],
        loading: false,
      };
    }

    if (data.type !== this.getActionName() + "_SUCCESS") {
      return state || initialState;
    }

    this.data = data.payload;
    return {
      ...state,
      [storeName]: data.payload,
      loading: false,
    };
  };
}

export default function (name, subName, options = {}) {
  name = name.replace("api/api/", "api/");
  if (name.indexOf("api/") < 0) {
    name = `api/${name}`;
  }
  const storesName = `${name}${subName || ""}`;
  if (store) {
    const { datasource: datasources } = store.getState() || {};
    stores = { ...datasources, ...stores };
  }
  if (!stores[storesName]) {
    stores[storesName] = new DataSourceClass(name, subName, options);
  } else {
    addDataSource(storesName, stores[storesName]);
  }

  return stores[storesName];
}
