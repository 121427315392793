import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { translate } from "react-translate";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";

import DataSource from "../../helpers/dataSource_executiveDocs";
import setComponentsId from "../../helpers/setComponentsId";
import { usePrevious } from "../../hooks/usePrevious";

import ReturnAndLocateInTableHOC from "../../components/ReturnAndLocateInTableHOC/ReturnAndLocateInTableHOC";
import styles from "../../variables/styles/inputDocs";
import { getExecMyRoles, getExecForms } from "./data/dictionaries";

import StandartPageLayout from "../../components/StandartPageLayout";
import ExecutiveDocsToolbar from "./components/ExecutiveDocsToolbar";
import ExecutiveDocsTable from "./components/ExecutiveDocsTable";

import { setDefaultFilters } from "./helpers/setUpDataSource";
import { EXECUTIVE_DOCS_URL } from "../../variables/wikiUrls";

const dataSource = DataSource("api/executive/my");

const ExecutiveDocsList = ({
  list,
  setId,
  t,
  courts,
  classes,
  history,
  returnObject,
  userInfo,
  role,
  meta,
  ...props
}) => {
  const [blockScreen, setBlockScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  const [roles, setRoles] = useState([]);
  const [forms, setForms] = useState([]);

  const [isMounting, setIsMounting] = useState(true);

  // хук аналог componentDidUpdate(prevProps)
  const prevRole = usePrevious({ role });

  const clearDataSource = () => {
    dataSource.filters = {};
    dataSource.sort = {};
    dataSource.setSearch("");
    returnObject.removeReturnObject();
  };

  const load = () => {
    if (window.amplitude) {
      window.amplitude.track("Перегляд сторінки: Виконавчі документи");
      console.log("Amplitude: Відстеження перегляду сторінки /executive");
    }
    setBlockScreen(true);
    setLoading(true);
    setSubLoading(true);
    dataSource.load().then(() => {
      setBlockScreen(false);
      setLoading(false);
      setSubLoading(false);
    });
  };

  useEffect(() => {
    setRoles(getExecMyRoles(t));
    setForms(getExecForms(t));

    if (isMounting) {
      clearDataSource();
      setDefaultFilters({
        dataSource,
        userId: userInfo?.userId,
      });
      load();
    } else {
      history.push("/executive");
      load();
    }

    setIsMounting(false);

    window.addEventListener("beforeunload", beforeunloadEventHandler);

    return () =>
      window.removeEventListener("beforeunload", beforeunloadEventHandler);
  }, []);

  const beforeunloadEventHandler = () => {
    returnObject.removeReturnObject();
  };

  useEffect(async () => {
    if (!isMounting && prevRole && role && role !== prevRole) {
      load();
    }
  }, [role]);

  const createSortHandler = (property) => () => {
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }
    // переход сортировки c "createdAt desc" на "-createdAt"
    dataSource.sort = order === "desc" ? `-${property}` : property;
    dataSource.sort = { [property]: order };

    dataSource.setValue("page", 0);
    dataSource.setValue("start", 0);
    dataSource.load();
    dataSource.sort = { [property]: order };
  };

  const handleClick = (rowData) => async () => {
    returnObject.setReturnObject({
      form: "executive",
      id: rowData.id,
      filters: dataSource.filters,
      sort: dataSource.sort,
      path: window.location.pathname + window.location.search,
      search: dataSource.search,
    });

    return window
      .open(history.location.pathname + `/${rowData.id}`, "_blank")
      .focus();
  };

  const pagination = (e, page) => {
    setBlockScreen(true);
    setLoading(true);
    setSubLoading(true);

    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);

    dataSource.load().then(() => {
      setBlockScreen(false);
      setLoading(false);
      setSubLoading(false);
    });
  };

  const changeCount = ({ target: { value } }) => {
    setBlockScreen(true);
    setLoading(true);
    setSubLoading(true);

    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource.load().then(() => {
      setBlockScreen(false);
      setLoading(false);
      setSubLoading(false);
    });
  };

  return (
    <StandartPageLayout
      blockScreen={blockScreen}
      cardTitle={t("HEADER")}
      dataSource={dataSource}
      emptyPageTitle={t("EXECUTIVE_EMPTY_PAGE_TITLE")}
      emptyPageDescription={t("EXECUTIVE_EMPTY_PAGE_DESCRIPTION")}
      list={list}
      setId={setId}
      stepName={"executive"}
      subLoading={subLoading}
      loading={loading}
      wikiUrl={EXECUTIVE_DOCS_URL}
      toolbar={
        <ExecutiveDocsToolbar
          t={t}
          classes={classes}
          setId={(elmentName) => setId(`toolbar-${elmentName}`)}
          dataSource={dataSource}
          load={load}
          courts={courts}
          roles={roles}
          forms={forms}
          userId={userInfo?.userId}
          returnObject={returnObject}
        />
      }
    >
      <ExecutiveDocsTable
        classes={classes}
        setId={(elmentName) => setId(`table-${elmentName}`)}
        t={t}
        courts={courts}
        createSortHandler={createSortHandler}
        changeCount={changeCount}
        dataSource={dataSource}
        handleClick={handleClick}
        history={history}
        list={list}
        onSelectAllClick={() => {}}
        onCheckboxClick={() => {}}
        pagination={pagination}
        returnObject={returnObject}
        meta={meta}
      />
    </StandartPageLayout>
  );
};

ExecutiveDocsList.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,

  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  userInfo: PropTypes.object.isRequired,
  meta: PropTypes.object,
  role: PropTypes.string.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ExecutiveDocsList.defaultProps = {
  list: null,
  meta: {},
  setId: setComponentsId("executive-docs"),
};

const mapStateToProps = ({
  executive: { list },
  usermeta: { userMetaData },
  dictionary: { courts },
  authorization: { info: userInfo, token, role: prevRole, roleInfo },
}) => {
  const { meta } = userMetaData || {};
  return {
    courts,
    list,
    meta,
    token,
    userInfo,
    role: roleInfo?.code || prevRole,
  };
};

const ExecutiveDocs = compose(
  withStyles(styles),
  translate("ExecutiveDocs"),
  ReturnAndLocateInTableHOC,
  withRouter
)(ExecutiveDocsList);

export default connect(mapStateToProps)(ExecutiveDocs);
