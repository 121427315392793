import { useEffect, useState } from "react";
import { translate } from "react-translate";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { IconButton, withStyles } from "@material-ui/core";
import { OpenInNew, SaveAlt } from "@material-ui/icons";
import styles from "../../variables/styles/executive";

import JSZip from "jszip";

import { compose } from "redux";
import { connect } from "react-redux";
import {
  getExecInfo,
  getDocumentInfo,
  checkExecOrderSign,
} from "../../actions/executive.orders";

import blobToBase64 from "../../helpers/blobToBase64";
import { blobToTextEncoded } from "../../helpers/blobToText";
import { stringToPdfBlob } from "../../helpers/stringToBlob";
import humanDateFormat from "../../helpers/humanDateFormat";

import PreviewDialog from "../../components/Attach/PreviewDialog";
import Preview from "../../components/Attach/Preview";
import { Button } from "../../components";
import { RegularCard, Preloader } from "../../components";
import PageCardTitle from "../../components/PageCardTitle";

import ExecutiveInfo from "./components/ExecutiveInfo";
import KeyPng from "../../assets/img/key.svg";
import { PERMISSIONS_URL } from "../../variables/wikiUrls";

import { usePrevious } from "../../hooks/usePrevious";
import { historyReturn } from "../../helpers/history";

const ext = { doc: "pdf", sign: "p7s", arch: "zip" };

const ExecutiveOrderView = ({
  t,
  setId,
  classes,
  history,
  role,
  match: {
    params: { id },
  },
  ...props
}) => {
  const [activeExecutive, setActiveExecutive] = useState(null);
  const [documentInfo, setDocumentInfo] = useState(null);
  const [docSignPDF, setDocSignPDF] = useState(null);
  const prevRole = usePrevious({ role });

  const [loading, setLoading] = useState(false);
  const [previewDialogLoading, setPreviewDialogLoading] = useState(false);

  const [previewData, setPreviewData] = useState({
    formatedSource: null,
    doc: null,
    format: "",
    text: null,
    url: "",
  });

  const loadInfo = async () => {
    let execDoc = await getExecInfo(id);
    if (!execDoc?.id) {
      const message = t("NEED_DOCUMENT_ACCESS");
      if (!historyReturn(history, { message })) {
        return history.replace("/");
      }
    }
    setActiveExecutive(execDoc);
  };

  function replaceHistory(e) {
    history.replace({ ...history.location, state: undefined });
  }

  useEffect(async () => {
    if (!activeExecutive?.id) return;
    try {
      let docInfo = await getDocumentInfo(activeExecutive.id);

      if (docInfo?.body) {
        setDocumentInfo(docInfo);
      } else {
        throw new Error("Empty file");
      }

      let file = stringToPdfBlob(docInfo?.body);

      const [doc, text, docFile] = await Promise.all([
        blobToBase64(file),
        blobToTextEncoded("Windows-1251")(file),
        file,
      ]);

      let docBodyBase64File = docInfo?.body;
      let docBlobFile = stringToPdfBlob(docBodyBase64File);

      const url = URL.createObjectURL(docBlobFile);

      setPreviewData({
        doc: doc,
        text: text,
        docFile: docFile,
        format: "pdf",
        url: url,
        formatedSource: docBlobFile,
      });
    } catch (err) {
      console.log("Error: ", err);
      setPreviewData({
        text: t("EMPTY_FILE"),
        format: "text",
      });
    } finally {
      setLoading(false);
    }
  }, [activeExecutive?.id]);

  useEffect(async () => {
    if (!role) return;

    setLoading(true);

    if ((id && !activeExecutive) || role !== prevRole) {
      // якщо потрапили на сторінку з справи чи по посиланню і ще його не вантажили
      // або коли знаходячись на цій сторінці змінили роль користувача
      await loadInfo();
    }
    setLoading(false);

    window.addEventListener("beforeunload", replaceHistory, true);
    return () => {
      window.removeEventListener("beforeunload", replaceHistory, true);
    };
  }, [id, history, role]);

  const checkECP = async () => {
    try {
      setPreviewDialogLoading(true);
      const { body, sign } = documentInfo;

      const docAcceptDate = humanDateFormat(activeExecutive?.docAcceptDate, {
        isUtc: true,
      });

      // Назва документу № ННННН від ДД.ММ.РРРР
      const docName = activeExecutive?.docName || "б/н";
      const docNumber = activeExecutive?.docNum || "б/н";
      const documentName = `ВП_${docAcceptDate}_${docNumber}` || "Документ";
      const documentDescr =
        `${docName} № ${docNumber} від ${docAcceptDate}` || "";

      const result = await checkExecOrderSign({
        file: {
          name: `${documentName}.${ext.doc}`,
          descr: documentDescr,
          body: body,
        },
        sign: [{ name: `${documentName}.${ext.doc}.${ext.sign}`, body: sign }],
      });

      if (!result?.size) {
        throw new Error(t("NOT_VALID_KEP"));
      } else {
        setDocSignPDF({
          ...result,
          format: "pdf",
          name: t("CHECK_KEP_RESULT_TITLE"),
          doc: result,
          file: result,
          isError: false,
        });
      }
    } catch (error) {
      setDocSignPDF({
        format: "text",
        name: t("ERROR"),
        text: error.message,
        isError: true,
      });

      console.log("checkECP error: ", error.message || "404 File not found");
    } finally {
      setPreviewDialogLoading(false);
    }
  };

  const download = async () => {
    const { t } = props;
    setLoading(true);

    try {
      let { body, sign } = documentInfo;
      if (!body || !sign) throw new Error(t("NOT_VALID_ARCHIVE"));

      let blobDoc = stringToPdfBlob(body);
      let blobSign = stringToPdfBlob(sign);

      const zip = new JSZip();

      const docAcceptDate = humanDateFormat(activeExecutive?.docAcceptDate, {
        format: "YYYY-MM-DD",
      });

      const docNumber = activeExecutive?.docNum || "б/н";
      const vpNumber = activeExecutive?.vpNum || "б/н";
      const documentName =
        `ВП_${docAcceptDate}_${vpNumber}_${docNumber}` || "Документ";

      zip.file(`${documentName}.${ext.doc}`, blobDoc);
      zip.file(`${documentName}.${ext.doc}.${ext.sign}`, blobSign);

      zip.generateAsync({ type: "blob" }).then((blobdata) => {
        let zipblob = new Blob([blobdata]);
        var elem = window.document.createElement("a");
        elem.href = window.URL.createObjectURL(zipblob);
        elem.download = `${documentName || "filename"}.${ext.arch}`;
        elem.click();
      });
    } catch (error) {
      console.log("download error: ", error.message || "404 File not found");
    } finally {
      setLoading(false);
    }
  };

  const { doc, format, size, text, url, formatedSource } = previewData;

  const docAcceptDate = humanDateFormat(activeExecutive?.docAcceptDate);
  const docNumber = activeExecutive?.docNum;
  const docName = activeExecutive?.docName || "б/н";

  const title = `${docName} ${docNumber ? `№ ${docNumber}` : ""} ${
    docAcceptDate ? `від ${docAcceptDate}` : ""
  }`;

  return (
    <RegularCard
      headerColor="primary"
      cardTitle={
        <PageCardTitle
          text={title}
          stepName={"pageDocumentView"}
          setId={setId}
          placement="right"
          wikiUrl={PERMISSIONS_URL}
        />
      }
      setId={setId}
    >
      {loading && <Preloader />}
      {!loading && (
        <>
          <div className={classes.executiveInfo}>
            <ExecutiveInfo
              t={t}
              classes={classes}
              activeDoc={activeExecutive}
              history={history}
            />
            <div className={classes.previewContainer}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {!!(
                    (activeExecutive && activeExecutive?.executiveId)
                    // && activeExecutive?.vdCaseNumber
                  ) && (
                    <Link
                      style={{ display: "inline" }}
                      to={`/executive/${activeExecutive?.executiveId || ""}`}
                      className={classes.buttonLink}
                    >
                      <Button color="yellow" variant="contained">
                        {t(`REDIRECT_TO_EXECUTIVE`)}
                      </Button>
                    </Link>
                  )}
                </div>

                <div>
                  <IconButton
                    color="inherit"
                    onClick={checkECP}
                    title={t("CHECK_ECP")}
                    className={classes.menuButton}
                    disabled={!documentInfo}
                  >
                    <img
                      src={KeyPng}
                      style={{ opacity: !documentInfo ? "0.25" : "1" }}
                      alt={t("CHECK_ECP")}
                      width={24}
                      height={24}
                    />
                  </IconButton>
                  <IconButton
                    color="inherit"
                    onClick={download}
                    title={t("SAVE_TO_DEVICE")}
                    className={classes.menuButton}
                    disabled={!documentInfo}
                  >
                    <SaveAlt />
                  </IconButton>

                  <IconButton
                    color="inherit"
                    className={classes.menuButton}
                    onClick={() => window.open(previewData.url, "_blank")}
                    title={t("PREVIEW_LINK")}
                    disabled={!documentInfo}
                  >
                    <OpenInNew />
                  </IconButton>
                </div>
              </div>
              <Preview
                doc={doc}
                text={text}
                url={url}
                size={size}
                format={format}
                source={formatedSource}
              />

              {(previewDialogLoading || docSignPDF) && (
                <PreviewDialog
                  openDialog={previewDialogLoading || !!docSignPDF}
                  toggleDialog={() => setDocSignPDF(null)}
                  setId={(name) => setId(`PreviewDialog - ${name} `)}
                  printHistory={null}
                  name={t("CHECK_KEP_RESULT_TITLE")}
                  {...(docSignPDF || {})}
                ></PreviewDialog>
              )}
            </div>
          </div>
        </>
      )}
    </RegularCard>
  );
};

ExecutiveOrderView.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node,
};

ExecutiveOrderView.defaultProps = {
  children: "",
};

const mapStateToProps = ({
  authorization: { info, role: prevRole, roleInfo },
  dictionary: { courts },
}) => ({
  info,
  role: roleInfo?.code || prevRole,
  courts,
});

const translated = compose(
  withStyles(styles),
  translate("ExecutiveOrders"),
  connect(mapStateToProps),
  withRouter
)(ExecutiveOrderView);

export default translated;
