// @ts-nocheck

import { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { translate } from "react-translate";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import renderHTML from "react-render-html";

import { requestActiveMessage, setIsRead } from "../../actions/notifications";
import { humanDateTimeFormat } from "../../helpers/humanDateFormat";
import { BlockScreen, RegularCard } from "../../components";
import ReturnButton from "../../components/ReturnButton";
import { historyPush } from "../../helpers/history";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { Button } from "../../components";

const styles = {
  root: {
    padding: 16,
  },
  paper: {
    padding: 10,
  },
};

enum ROLES {
  "representative" = "Представник",
  "individual" = "Фізична особа",
  "legalEntityAgent" = "Представник організації",
  "judge" = "Суддя",
  "unknown" = "Невідома роль",
}

const NotificationDetails = (props) => {
  const [blockScreen] = useState(false);
  const [error] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [userRole, setUserRole] = useState("");

  useEffect(async () => {
    if (!props.role) return;
    setUserRole(props.role);

    let { activeMessage } = props;
    const { match, history, list } = props;
    const needUseUserMeta = !(
      (list || {}).meta && "unread" in (list || {}).meta
    );
    const { messageId } = match.params || {};
    if (!messageId) {
      history.push("/notifications");
    } else if (!activeMessage) {
      activeMessage = await requestActiveMessage(messageId);
    }
    if (activeMessage.message) {
      history.push("/notifications");
    } else if (needUseUserMeta) {
      setIsRead([activeMessage.id], activeMessage, false);
    } else if (!props.activeMessage && activeMessage) {
      setIsRead([activeMessage.id], activeMessage);
    }

    const message = history?.location?.state?.message;
    if (message) {
      history.replace({ ...history.location, state: undefined });
      setDialogMessage(message.replace(/\.$/, ""));
    }
  }, [props.role]);

  const formatMessage = (msg) => {
    if (!msg) return;

    let jsx = [];

    let result = msg
      .replaceAll("<html>", "")
      .replaceAll("</html>", "")
      .replaceAll("<body>", "")
      .replaceAll("</body>", "");

    const arr = [];
    let counter = 0;

    let i0 = 0;
    let i1 = 0;
    let i2 = 0;
    let i3 = 0;
    let i4 = 0;
    let tmp = 0;

    let offseting = 0;

    let tmpResult = result;
    urlify(result);

    function urlify(text) {
      var urlRegex = /<a\b[^>]*>(.*?)<\/a>/gi;

      return text.replaceAll(
        urlRegex,
        function (url, contents, offset, input_string) {
          jsx.push(text.substring(tmp, offset));

          tmp = offset + url.length;

          i1 = url.indexOf('<a href="');

          i2 = url.indexOf('">');

          let substr1 = url
            .substring(i1 + '<a href="'.length, i2)
            .replaceAll("https://cabinet-dev.court.gov.ua", "")
            .replaceAll("https://cabinet-test.court.gov.ua", "")
            .replaceAll("https://cabinet-stage.court.gov.ua", "")
            .replaceAll("https://cabinet.court.gov.ua", "");

          i3 = url.indexOf('">');
          i4 = url.indexOf("</a>");

          const substr2 = url.substring(i3 + 2, i4);

          arr[counter] = [];
          arr[counter].push(i0, i1 + i0, i2 + i0, i3 + i0, i4 + i0);

          offseting = offset + i4;

          jsx.push(
            <Link
              style={{ fontWeight: "bold" }}
              onClick={(e) => {
                e.preventDefault();
                historyPush(props.history, substr1);
              }}
              to={{
                pathname: substr1,
                state: { from: window.location.pathname },
              }}
            >
              {substr2}
            </Link>
          );

          counter++;
        }
      );
    }

    if (offseting < tmpResult?.length) {
      jsx.push(
        tmpResult.substring(offseting, tmpResult?.length).replace("</a>", "")
      );
    }

    return jsx;
  };

  const { activeMessage: notification, classes, setId } = props;
  if (blockScreen || (!notification && !error)) {
    return <BlockScreen open={true} />;
  }

  return (
    <>
      <RegularCard
        style={styles.root}
        headerColor="primary"
        cardTitle={
          <div id={setId("title")}>
            <ReturnButton t={props.t} />
            {!error &&
              renderHTML(
                notification.titleMessage || notification.shortMessage || ""
              )}
            {error}
          </div>
        }
        setId={setId}
        cardSubtitle={!error && humanDateTimeFormat(notification.createdAt)}
        content={
          error || (
            <Paper
              className={classes.paper}
              elevation={1}
              id={setId("content")}
            >
              {formatMessage(notification?.fullMessage).length > 0 &&
                formatMessage(notification?.fullMessage).map((item, index) => (
                  <span key={index}>{item}</span>
                ))}
            </Paper>
          )
        }
      />
      <Dialog open={!!dialogMessage}>
        <DialogTitle>Повідомлення</DialogTitle>
        <DialogContent>
          {dialogMessage ||
            "Об'єкт, на який ви переходите, не доступний для ролі"}{" "}
          "{ROLES[userRole] || ROLES.unknown}". <br />
          Для можливості переходу виберіть іншу роль.
        </DialogContent>
        <DialogActions>
          <Button
            id={"auth-dialog-individual-btn"}
            onClick={() => {
              setDialogMessage("");
            }}
            color="yellow"
          >
            Закрити
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

NotificationDetails.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  activeMessage: PropTypes.object,
  match: PropTypes.object.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
};

NotificationDetails.defaultProps = {
  list: null,
  activeMessage: null,
};

const mapStateToProps = ({
  notifications: { activeMessage, list },
  authorization: { info: userInfo, role: prevRole, roleInfo },
}) => ({
  activeMessage,
  list,
  userInfo,
  role: roleInfo?.code || prevRole,
});

const translated = compose(
  withStyles(styles),
  translate("Notifications"),
  connect(mapStateToProps),
  withRouter
)(NotificationDetails);

// decorate and export
export default translated;
