import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import cx from "classnames";

import Select from "../../components/Select";

import { CustomInput } from "../../components";
import setComponentsId from "../../helpers/setComponentsId";

import { permissions } from "../../helpers/permission";

// const testDisabled = (share, name) =>
//   share &&
//   !share.isAlloweder &&
//   (share.parent[name] ? share.parent[name] === 0 : share[name] === 0);

const mapJusticeTypesToOptions = (values) => {
  return (values || [])
    .filter((value) => value.id > 1)
    .map((value) => ({
      id: value.id,
      name: value.description,
    }));
};

const ShareDialogPermissions = ({
  allowShare,
  allowAsvp,
  classes,
  changedShare,
  caseNumber,
  havePermission,
  caseNumIsChecked,
  handleChangeCaseNumIsChecked,
  courtStateChecked,
  handleChangeCourtStateCheckBox,
  onChangeCaseNumber,
  onBlurCaseNumber,
  onChangeJusticeCheckBox,
  onChangePermission,
  permission,
  t,
  toggleReshare,
  toggleAllowAsvp,
  showJusticeSelect,
  showCaseNumberInput,
  setId,
  jurisdictionTypes,
  onChange,
  error,
  display,
  targetId,
}) => {
  const [value, setValue] = useState();
  const options = mapJusticeTypesToOptions(jurisdictionTypes);
  // name - назва радіобатона зі списку
  // permission - вибраний радіобатон
  // на що було дано дозвіл
  const checkRadioBtnAccess = (name) => {
    switch (name) {
      case "allowRead":
        return changedShare?.allowRead;
      case "allowEdit":
        return changedShare?.allowEdit;
      case "allowCommit":
        return changedShare?.allowCommit;
      default:
        return false;
    }
  };
  const showAllowAsvp = changedShare
    ? !!changedShare?.chainOfResharings?.find((item) => item.allowAsvp === 1)
    : !!changedShare?.allowAsvp || true;

  return (
    <Fragment>
      <FormControl
        component="fieldset"
        className={classes.formControl}
        id={setId("control")}
      >
        {showJusticeSelect && !caseNumIsChecked && !targetId && (
          <FormControlLabel
            className={cx(classes.radioLabel, classes.checkboxLabel)}
            control={
              <Checkbox
                onChange={handleChangeCourtStateCheckBox}
                checked={courtStateChecked}
                className={classes.checkbox}
                color="default"
                id={setId("at-proceedings-checkbox")}
              />
            }
            label={t("COURT_STATE")}
          />
        )}
        {courtStateChecked && (
          <Select
            style={{ height: "100px" }}
            label={"Виберіть судочинство"}
            aria-required="true"
            multi={false}
            name="at-proceedings-select"
            value={value}
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              setValue(value);
              onChangeJusticeCheckBox(value);
              // onChange && onChange(e);
            }}
            options={options}
            selectProps={{
              value: value,
              // onChange: onChangeJusticeCheckBox,
            }}
          />
        )}
        {showCaseNumberInput && !courtStateChecked && !targetId && (
          <FormControlLabel
            className={cx(classes.radioLabel, classes.checkboxLabel)}
            //id={setId('reshare-label')}
            //onChange={toggleReshare}
            control={
              <Checkbox
                onChange={handleChangeCaseNumIsChecked}
                checked={caseNumIsChecked}
                className={classes.checkbox}
                color="default"
                id={setId("case-checkbox")}
              />
            }
            label={t("AT_CASE")}
          />
        )}
        {caseNumIsChecked && (
          <CustomInput
            labelText={"№ справи"}
            formControlProps={{ fullWidth: true }}
            //error={!!error}
            //helperText={error}
            //success={!error}
            showErrors={true}
            setId={setComponentsId("custom-input")}
            inputProps={{
              value: caseNumber,
              onChange: onChangeCaseNumber,
              onBlur: onBlurCaseNumber,
              //error:  !!error,
            }}
          />
        )}
        <RadioGroup
          className={classes.group}
          value={permission}
          onChange={onChangePermission}
          aria-label="permission"
          name="permission1"
          id={setId("radio-group")}
        >
          {[...permissions].reverse().map(({ name, textId }, i) => {
            // console.log(name, textId, permission, display);
            return (
              <FormControlLabel
                className={classes.radioLabel}
                id={setId(`radio-label-${i + 1}`)}
                key={`perm-${name}`}
                value={name}
                control={
                  <Radio
                    className={classes.radio}
                    color="default"
                    id={setId(`radio-button-${i + 1}`)}
                    // disabled={testDisabled(changedShare, name)}
                    disabled={
                      display === "changePermission" ||
                      (display === "addResharePermissions" &&
                        !checkRadioBtnAccess(name))
                    }
                    // display === "changePermission" наразі для перегляду довіреності
                    // display === "addResharePermissions" вікно зміни довіреності при передорученні
                  />
                }
                style={name === "allowShare" ? { display: "none" } : {}}
                label={
                  <Fragment>
                    <span
                      className={classes.radioTitle}
                      id={setId(`radio-label-text-${i + 1}`)}
                    >
                      {t(`PERMISSION_${textId}`)}
                    </span>
                    <span
                      className={classes.radioDescription}
                      id={setId(`radio-label-description-${i + 1}`)}
                    >
                      {t(`PERMISSION_${textId}_DESCRIPTION`)}
                    </span>
                  </Fragment>
                }
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      {(!!changedShare?.allowAsvp || showAllowAsvp) && (
        <>
          <FormControl
            component="fieldset"
            className={classes.formControl}
            id={setId("allow-asvp-control")}
          >
            <FormControlLabel
              className={cx(classes.radioLabel, classes.checkboxLabel)}
              id={setId("allow-asvplabel")}
              checked={!!allowAsvp}
              onChange={(e) => {
                toggleAllowAsvp(e);
              }}
              control={
                <Checkbox
                  className={classes.checkbox}
                  color="default"
                  id={setId("allow-asvpcheckbox")}
                  disabled={display === "changePermission"}
                />
              }
              label={
                <Fragment>
                  <span
                    className={classes.radioTitle}
                    id={setId("allow-asvplabel-text")}
                  >
                    {t("SUPPLY_TO_ASVP")}
                  </span>

                  <span
                    className={classes.radioDescription}
                    id={setId("allow-asvplabel-description")}
                  >
                    {t("SUPPLY_TO_ASVP_DESCR")}
                  </span>
                </Fragment>
              }
            />
          </FormControl>
        </>
      )}

      {havePermission && (
        <>
          <FormControl
            component="fieldset"
            className={classes.formControl}
            id={setId("reshare-control")}
          >
            <FormControlLabel
              className={cx(classes.radioLabel, classes.checkboxLabel)}
              id={setId("reshare-label")}
              checked={allowShare}
              onChange={toggleReshare}
              control={
                <Checkbox
                  className={classes.checkbox}
                  color="default"
                  id={setId("reshare-checkbox")}
                  disabled={display === "changePermission"}
                />
              }
              label={
                <Fragment>
                  <span
                    className={classes.radioTitle}
                    id={setId("reshare-label-text")}
                  >
                    {t("RESHARE")}
                  </span>
                  <span
                    className={classes.radioDescription}
                    id={setId("reshare-label-description")}
                  >
                    {t("RESHARE_DESCRIPTION")}
                  </span>
                </Fragment>
              }
            />
          </FormControl>
        </>
      )}
    </Fragment>
  );
};

ShareDialogPermissions.propTypes = {
  error: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  courtStateChecked: PropTypes.bool.isRequired,
  handleChangeCourtStateCheckBox: PropTypes.func.isRequired,
  onChangePermission: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  allowShare: PropTypes.bool.isRequired,
  showCaseNumberInput: PropTypes.bool.isRequired,
  toggleReshare: PropTypes.func.isRequired,
  havePermission: PropTypes.bool,
  changedShare: PropTypes.object,
};

ShareDialogPermissions.defaultProps = {
  havePermission: true,
  changedShare: null,
};

const mstp = ({ dictionary: { jurisdiction_types } }) => {
  return {
    jurisdictionTypes: jurisdiction_types,
  };
};

export default connect(mstp)(ShareDialogPermissions);
