import { Fragment } from "react";
import PropTypes from "prop-types";

import getProperties from "../../../pages/Case/decorators/getProperties";
import CaseMasterLayout from "./MasterLayout";
import MyDocLayout from "./MyDocLayout";

const CaseLayout = (props) => {
  const {
    statuses,
    memberRoles,
    userId,
    setId,
    onCheckboxClick,
    onViewDocument,
    courtTypes,
    justiceTypes,
    // role,
    subTitle,
    templates,
    courts,
    procChecked,
    permissions,
    noShowAttachPreview,
  } = props;
  const {
    favorites,
    currentList,
    onCheck,
    targetType,
    handleCheckboxClick,
    currentChecked,
    showCreateButton,
    enableShareButton,
    showMyDocList,
    docFilter,
    courtsList,
    docDisplay,
    docTitle,
    currentShares,
    targetId,
    filterOwners,
    foundInMembers,
    docDataSource,
    docList,
    activeProc,
    activeCase,
  } = getProperties(props);
  // const hideMyDocLayoutForJudge = role === "judge";
  const hideMyDocLayoutForJudge = false;

  return (
    <Fragment>
      <CaseMasterLayout
        {...props}
        activeProc={activeProc}
        activeCase={activeCase}
        procChecked={procChecked}
        permissions={permissions}
        courtsList={courtsList}
        showCreateButton={showCreateButton}
        currentShares={currentShares}
        enableShareButton={enableShareButton}
        showMyDocList={showMyDocList}
        targetType={targetType}
        targetId={targetId}
        filterOwners={filterOwners}
        foundInMembers={foundInMembers}
        currentChecked={currentChecked}
        currentList={currentList}
        onCheck={onCheck}
        handleCheckboxClick={handleCheckboxClick}
        favorites={favorites}
        noShowAttachPreview={noShowAttachPreview}
      />
      {showMyDocList && !hideMyDocLayoutForJudge && (
        <MyDocLayout
          userId={userId}
          setId={(elementName) => setId(`my-doc-${elementName}`)}
          onCheckboxClick={onCheckboxClick}
          onViewDocument={onViewDocument}
          courts={courts}
          globalCourts={courts}
          statuses={statuses}
          memberRoles={memberRoles}
          courtTypes={courtTypes}
          justiceTypes={justiceTypes}
          checked={targetId}
          owners={filterOwners}
          favorites={favorites}
          subTitle={subTitle}
          title={docTitle}
          dataSource={docDataSource}
          list={docList}
          docFilter={docFilter}
          docDisplay={docDisplay}
          templates={templates}
          noShowAttachPreview={noShowAttachPreview}
        />
      )}
    </Fragment>
  );
};

CaseLayout.propTypes = {
  userId: PropTypes.string.isRequired,
  caseDocuments: PropTypes.array,
  checked: PropTypes.string,
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  onCheckItem: PropTypes.func.isRequired,

  onCheckboxClick: PropTypes.func.isRequired,
  onViewDocument: PropTypes.func.isRequired,
  procDocuments: PropTypes.array,
  proceedingsList: PropTypes.array,
  onCheckProcItem: PropTypes.func.isRequired,

  list: PropTypes.array,
  statuses: PropTypes.array.isRequired,
  memberRoles: PropTypes.array.isRequired,
  courtTypes: PropTypes.array,
  justiceTypes: PropTypes.array.isRequired,
  userMetaData: PropTypes.object,
  owners: PropTypes.array,
  activeCase: PropTypes.object,
  procChecked: PropTypes.string,
  isLegal: PropTypes.bool.isRequired,
  subTitle: PropTypes.string.isRequired,
  casesCourts: PropTypes.array,
  procCourts: PropTypes.array,
  myProcDocTitle: PropTypes.string.isRequired,
  itemShareList: PropTypes.array.isRequired,
  onProcCheckboxClick: PropTypes.func.isRequired,
  myCaseDocTitle: PropTypes.string,
  myProcDocuments: PropTypes.array,
  myCaseDocuments: PropTypes.array,
  templates: PropTypes.array,
  changedShare: PropTypes.object,
  courts: PropTypes.array,
};

CaseLayout.defaultProps = {
  caseDocuments: null,
  checked: "",
  procDocuments: [],
  proceedingsList: null,
  courtTypes: [],
  userMetaData: {},
  activeCase: null,
  procChecked: "",
  owners: [],
  list: null,
  casesCourts: [],
  procCourts: [],
  myCaseDocTitle: "",
  myProcDocuments: [],
  myCaseDocuments: [],
  templates: [],
  changedShare: null,
  courts: [],
};

export default CaseLayout;
