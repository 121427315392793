import React, { Component } from "react";
import PropTypes from "prop-types";
import { setRole, logout } from "../../actions/auth";
import setComponentsId from "../../helpers/setComponentsId";
// import includeFieldsFromUserRole from "../../helpers/includeFieldsFromUserRole";
import { connect } from "react-redux";
import { getRoles } from "../../actions/auth";

import {
  withStyles,
  Fab,
  Hidden,
  MenuItem,
  Avatar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  Divider,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import { Person } from "@material-ui/icons";
import cx from "classnames";

import headerLinksStyle from "../../variables/styles/headerLinksStyle";
import { translate } from "react-translate";
import roles from "../../variables/roles";
import { appRoutes } from "../../containers/Index/routes";

import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter";
import firstLetterToLowerCase from "../../helpers/firstLetterToLowerCase";
import { ButtonType } from "../../reducers/appDialogTypes";
import { checkLegalEntityAgentRole } from "../../actions/appDialog";
import { compose } from "redux";

import store from "../../store";
import { setRoles } from "../../reducers/roles";

const renderRole = (classes, role, t) => (
  <span className={classes.roleTextWrap}>
    <br />
    {t("HOW")}
    <span className={classes.roleText}> {role}</span>
  </span>
);

class HeaderLinks extends Component {
  state = { anchorEl: null };

  handleGetRoles = async () => {
    if (!this.state.rolesLoaded) {
      try {
        const roles = await getRoles();
        store.dispatch(setRoles(roles));
        this.setState({ rolesLoaded: true });
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    }
  };

  handleClick = async (event) => {
    const currentState = store.getState();
    const rolesFromState = currentState.roles;
    if (!this.state.rolesLoaded) {
      try {
        // const roles = await getRoles();
        store.dispatch(setRoles(rolesFromState));
        this.setState({ rolesLoaded: true });
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    }
    this.handleGetRoles();
    return this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    const { handleDrawerToggle } = this.props;
    this.setState({ anchorEl: null });
    handleDrawerToggle && handleDrawerToggle();
  };

  selectRole = (aRole) => async () => {
    const { auth } = this.props;
    const { role: currentRole } = auth;

    let role = aRole;
    let redirectToProfile = false;
    const isProfile = this.props.location.pathname === "/company-profile";
    let checkResult = await this.props.checkLegalEntityAgentRole(role);
    if (checkResult === ButtonType.CANCEL) role = currentRole;
    redirectToProfile = checkResult === ButtonType.YES;

    if (currentRole !== role && checkResult === ButtonType.OK) {
      await setRole(role);
    }
    this.setState({ anchorEl: null });

    if (redirectToProfile && !isProfile) {
      this.props.history.replace("/company-profile");
    }
  };

  handleLogout = () => {
    logout();
    this.handleClose();
  };

  menuItemText(name) {
    const { auth } = this.props;
    const info = auth.info || {};
    const { companyName } = info;

    switch (name) {
      case "legalEntityAgent":
        return `${roles[name]} ${companyName}`;
      default:
        return roles[name];
    }
  }

  render() {
    const { t, classes, auth, setId, match, courtIdUserScopes } = this.props;
    const { path } = match || {};
    const showRole = path !== "/search";
    const info = auth.info || {};
    const { roleInfo } = auth;

    const role = roleInfo?.code || auth.role;
    const {
      isLegal,
      first_name: firstName,
      last_name: lastName,
      fullAvaUrl,
    } = info;
    const companyName = roleInfo?.data?.companyName;

    const { anchorEl } = this.state;
    const open = !!anchorEl;
    roles.individual = isLegal ? t("LEGAL") : t("UN_LEGAL");
    const filteredRoles =
      courtIdUserScopes && courtIdUserScopes.length
        ? courtIdUserScopes.filter(
            (item) =>
              !!roles[item.code] &&
              !!appRoutes.find(
                (route) =>
                  route.navbar &&
                  route.accessRoles &&
                  route.accessRoles.includes(item.code)
              )
          )
        : [];

    const showSelectRoles =
      // includeFieldsFromUserRole("showToggleRoles") &&
      // courtIdUserScopes &&
      // !!courtIdUserScopes.length &&
      // !!courtIdUserScopes.find((item) => !!roles[item.code]) &&
      filteredRoles.length > 1;
    return (
      <div>
        <Fab
          onClick={this.handleClick}
          variant="extended"
          className={classes.buttonLink}
          id={setId("open-links-button")}
        >
          <span className={classes.userName}>
            {!isLegal &&
              `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
                lastName
              )}`}
            {isLegal && companyName}
            {showRole &&
              renderRole(
                classes,
                firstLetterToLowerCase(this.menuItemText(role)),
                t
              )}
          </span>
          <Avatar
            className={classes.avatar}
            src={fullAvaUrl}
            id={setId("avatar")}
          >
            {fullAvaUrl ? "" : <Person className={classes.links} />}
          </Avatar>
          <Hidden mdUp={true}>
            <p className={classes.linkText} id={setId("hidden-user-name")}>
              {isLegal && companyName}
              {!isLegal &&
                `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
                  lastName
                )}`}
              {showRole && renderRole(classes, roles[role], t)}
            </p>
          </Hidden>
        </Fab>

        <Popper
          id={setId("popover")}
          anchorEl={anchorEl}
          open={open}
          placement="bottom-end"
          className={cx(classes.pooperResponsive, !open && classes.popperClose)}
        >
          <ClickAwayListener
            onClickAway={this.handleClose}
            id={setId("popover-wrap")}
          >
            <Grow
              in={open}
              id={setId("menu-list")}
              style={{ transformOrigin: "right bottom" }}
            >
              <Paper className={classes.dropdown} id={setId("paper")}>
                <MenuList role="menu" id={setId("menu")}>
                  {showSelectRoles && (
                    <div className={classes.roleTitle}>{t("ROLE_TITLE")}</div>
                  )}
                  {showSelectRoles && !filteredRoles.length && (
                    <div>Завантаження ролей</div> // лоадер
                  )}
                  {showSelectRoles &&
                    filteredRoles.map((item) => (
                      <MenuItem
                        onClick={this.selectRole(item.code)}
                        id={setId(`${item.code}-link-item`)}
                        key={`${item.code}-link`}
                      >
                        {this.menuItemText(item.code)}
                      </MenuItem>
                    ))}
                  {showSelectRoles && (
                    <Divider className={classes.dropdownDivider} />
                  )}
                  {role !== "legalEntityAgent" && (
                    <Link to="/profile" id={setId("profile-link")}>
                      <MenuItem
                        onClick={this.handleClose}
                        id={setId("profile-link-item")}
                      >
                        {t("MY_PROFILE")}
                      </MenuItem>
                    </Link>
                  )}
                  {role === "legalEntityAgent" && (
                    <Link
                      to="/company-profile"
                      id={setId("company-profile-link")}
                    >
                      <MenuItem
                        onClick={this.handleClose}
                        id={setId("company-profile-link-item")}
                      >
                        {`${t("PROFILE")} ${companyName}`}
                      </MenuItem>
                    </Link>
                  )}
                  {/*СТАРЫЙ РАЗДЕЛ МОІ ДОКУМЕНТИ*/}
                  {/* {!isLegal && (
                    <Link to="/my-documents" id={setId("my-documents-link")}>
                      <MenuItem
                        onClick={this.handleClose}
                        id={setId("my-documents-link-item")}
                      >
                        {t("MY_DOCUMENTS")}
                      </MenuItem>
                    </Link>
                  )} */}
                  <MenuItem
                    onClick={this.handleLogout}
                    id={setId("logout-link-item")}
                  >
                    {t("LOGOUT")}
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  setId: PropTypes.func,

  handleDrawerToggle: PropTypes.func,
  match: PropTypes.object.isRequired,
};

HeaderLinks.defaultProps = {
  setId: setComponentsId("header-links"),
  handleDrawerToggle: undefined,
};

function mapStateToProps(state) {
  return {
    auth: state.authorization,
    router: state.router,
    courtIdUserScopes: state.roles,
  };
}

function MDTP(dispatch) {
  return {
    checkLegalEntityAgentRole: (role) =>
      dispatch(checkLegalEntityAgentRole(role)),
  };
}

// decorate and export
export default compose(
  withStyles(headerLinksStyle),
  translate("Header"),
  withRouter,
  connect(mapStateToProps, MDTP)
)(HeaderLinks);
