import * as api from "../services/api";
import store from "../store";

const { dispatch } = store;

export const REQUEST_PERMISSIONS = "REQUEST_PERMISSIONS";
export const REQUEST_PERMISSIONS_CHILDREN = "REQUEST_PERMISSIONS_CHILDREN";
export const REQUEST_PERMISSIONS_CHAIN = "REQUEST_PERMISSIONS_CHAIN";
export const REQUEST_DOCUMENT_PERMISSIONS = "REQUEST_DOCUMENT_PERMISSIONS";
export const ADD_PERMISSION = "ADD_PERMISSION";
export const EDIT_PERMISSION = "EDIT_PERMISSION";
export const REQUEST_PDF = "REQUEST_PERMISSIONS_PDF";
export const DELETE_PERMISSIONS = "DELETE_PERMISSIONS";
export const ADD_PREPARE_PERMISSION = "ADD_PREPARE_PERMISSION";
export const ADD_PERMISSION_META = "ADD_PERMISSION_META";
export const GET_PERMISSION_META = "GET_PERMISSION_META";
export const EDIT_PERMISSION_META = "EDIT_PERMISSION_META";
export const DELETE_PERMISSION_META = "DELETE_PPERMISSION_META";
export const DELETE_EDR_RECIPE = "DELETE_EDR_RECIPE";
export const SET_LOADING_PERMISSIONS = "SET_LOADING_PERMISSIONS";
export const GET_ONE_PERMISSION = "GET_ONE_PERMISSION";
export const ADD_EDR_RECIPE = "ADD_EDR_RECIPE";
export const GET_EDR_RECIPE = "GET_EDR_RECIPE";

// const PERMISSIONS_URL_v2 = "api/v2/users/me/permissions";
const PERMISSIONS_API_v2 = "api/v2/permissions";
const PERMISSIONS_URL = "api/permissions";
const PERMISSIONS_META_URL = "api/permission_meta";

export function requestPermissionsList(filter = "") {
  dispatch({ type: SET_LOADING_PERMISSIONS });
  return api.get(`${PERMISSIONS_URL}${filter}`, REQUEST_PERMISSIONS, dispatch);
}

export function requestPermissionsListChildren(id) {
  return api.get(
    `api/permissions/${id}/children/`, //&filter[isActive]=true
    REQUEST_PERMISSIONS_CHILDREN,
    dispatch
  );
}

export function requestPermissionsChain(id) {
  return api.get(
    `api/permissions/${id}/chain/`,
    REQUEST_PERMISSIONS_CHAIN,
    dispatch
  );
}

export function requestOnePermission(id) {
  dispatch({ type: SET_LOADING_PERMISSIONS });
  return api.get(`${PERMISSIONS_URL}/${id}`, GET_ONE_PERMISSION, dispatch);
}

export function requestDocumentPermissionsList(documentId) {
  return api.get(
    `${PERMISSIONS_URL}?document_id=${documentId}`,
    REQUEST_DOCUMENT_PERMISSIONS,
    dispatch
  );
}

export function addPermission(data) {
  return api.post(
    `${PERMISSIONS_API_v2}/permission`,
    data,
    ADD_PERMISSION,
    dispatch
  );
}

export function addPreparePermission(data) {
  return api.post(
    `${PERMISSIONS_API_v2}/permission`,
    data,
    ADD_PREPARE_PERMISSION,
    dispatch
  );
}

/**
 * Сохраняет данные ордера и формирует пдф.
 * Значення "permissionType" не обов’язкове і по замовчанню "allowCommit", значення "allowShare": false.
 * @param {string} data.userId
 * @param {LegalPart | NotLegalPart} data.meta
 * @param {PermissionType.Order} data.typeId
 * @return {Promise<any>}
 */
export function addOrderPermission(data) {
  return api.post(
    `${PERMISSIONS_API_v2}/order`,
    data,
    ADD_PREPARE_PERMISSION,
    dispatch
  );
}

export function editPermission(id, data) {
  return api.put(`${PERMISSIONS_URL}/${id}`, data, EDIT_PERMISSION, dispatch);
}

export function deletePermission(id) {
  return api.del(`${PERMISSIONS_URL}/${id}`, DELETE_PERMISSIONS, dispatch);
}

export function addPermissionMeta(id, data) {
  return api.post(
    `${PERMISSIONS_META_URL}/${id}`,
    { meta: data },
    ADD_PERMISSION_META,
    dispatch
  );
}

export function getPermissionMeta(id) {
  return api.get(
    `${PERMISSIONS_META_URL}/${id}`,
    {},
    GET_PERMISSION_META,
    dispatch
  );
}

export function editPermissionMeta(id, data) {
  return api.put(
    `${PERMISSIONS_META_URL}/${id}`,
    data,
    EDIT_PERMISSION_META,
    dispatch
  );
}

export function deletePermissionMeta(id) {
  return api.del(
    `${PERMISSIONS_META_URL}/${id}`,
    DELETE_PERMISSION_META,
    dispatch
  );
}

export function deleteEdrRecipe(id, deleteChildren = true) {
  return api.del(
    `${PERMISSIONS_URL}/${id}?deleteChildren=${deleteChildren}`,
    DELETE_EDR_RECIPE,
    dispatch
  );
}

export function addEDRRecipe() {
  return api.post(`${PERMISSIONS_URL}/edr`, {}, ADD_EDR_RECIPE, dispatch);
}

export function getEDRRecipe(id) {
  return api.get(`${PERMISSIONS_URL}/edr/${id}`, GET_EDR_RECIPE, dispatch);
}
