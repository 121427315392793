// import { requestPermissionsList } from "../../actions/permissions";
// import { loadPersonalData } from '../../actions/personalData';
// import { requestUserInfo } from "../../actions/auth";
// import store from "../../store";
// import { responseInterceptor } from "http-proxy-middleware";
// import { ButtonType } from "../../reducers/appDialogTypes";

import React from "react";
import { connect } from "react-redux";
import { translate } from "react-translate";

import PerfectScrollbar from "react-perfect-scrollbar";
import ReactResizeDetector from "react-resize-detector";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import { compose } from "redux";
import { BlockScreen, Header, Sidebar } from "../../components";
import SnackbarMessages from "../../components/SnackbarMessages";
import { addUserMeta, requestUserMeta } from "../../actions/usermeta";
import { requestDictionary } from "../../actions/dictionary";
import { setIsRead } from "../../actions/notifications";
import { setRole, logout, getRoles } from "../../actions/auth";

import setComponentsId from "../../helpers/setComponentsId";
import appStyle from "../../variables/styles/appStyle";
import logo from "../../logo_short.svg";
import WarningDialog from "../../components/WarningDialog";

import config from "../../config";
import routes, { appRoutes } from "./routes";

import { getUserSettings } from "../../actions/user";
import { checkLegalEntityAgentRole } from "../../actions/appDialog";
import AuthDialog from "../../components/AuthDialog/index";
import { setRoles } from "../../reducers/roles";
import store from "../../store";

const setId = setComponentsId("app");

const { warnings } = config(); // EVDroles

class App extends React.Component {
  mainPanel = null;

  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      itIsStatisticsPage: false,
      loading: true,
      testDialog: false,
      legalEntityAgentRole: "",
      result: null,
    };
  }

  componentDidMount() {
    const { viewed, meta, history, auth } = this.props;
    const { meta: noteMeta } = (this.props.notifications || {}).list || {};

    (async () => {
      let redirectToProfile = false;
      const isProfile = this.props.location.pathname === "/company-profile";
      const roles = await getRoles(); // Асинхронно отримуємо масив ролей
      store.dispatch(setRoles(roles)); // Оновлюємо стан Redux через екшен setRoles
      let role = auth.role;
      if (!role) {
        role = auth.info?.legalEntityId ? "legalEntityAgent" : "individual";
        setRole(role);
      } else {
        setRole(role);
      }

      if (this.props.location.pathname === "/statistics") {
        this.setState({ itIsStatisticsPage: true });
      }
      // if (EVDroles) {
      //   getExecutiveCount();
      // }
      // EC-334
      //   if (config.ApprovalRoles) {
      //   getApprovalCount();
      // }
      await Promise.all([
        getUserSettings(),
        requestUserMeta(),
        requestDictionary(),
        // loadPersonalData(),
        // requestPermissionsList(),
      ]);

      if (redirectToProfile && !isProfile) {
        history.replace("/company-profile");
      }

      if (!(viewed.length > 0 && noteMeta)) {
        return this.setState({ loading: false });
      }

      await setIsRead(viewed);

      await addUserMeta({
        meta: {
          ...meta,
          viewedNotification: undefined,
        },
      });

      this.setState({ loading: false });
    })();
  }

  //Клік івент кнопка фізична особа
  async handleIndividualClick() {
    setRole("individual");
  }

  //Клік івент кнопка представник
  async handleLegalEntityAgentClick() {
    setRole("legalEntityAgent");
  }

  //Клік івент кнопка представник організації
  handleRegCompany = () => {
    const { history } = this.props;
    history.replace("/company-profile");
  };

  //Клік івент кнопка реестрація
  handleRegProfile = () => {
    const { history } = this.props;
    history.replace("/profile");
  };

  handleLogout = () => {
    logout();
  };

  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }

  // example for updating of all dictionaries
  // async componentDidUpdate(prevProps) {
  // if (
  //   this.props.auth?.role &&
  //   prevProps.auth?.role &&
  //   this.props.auth?.role !== prevProps.auth?.role
  // ) {
  //   console.log("this.props.role: ", this.props.auth?.role);
  //   console.log("prevProps.role: ", prevProps.auth?.role);
  //   await Promise.all([
  //     getUserSettings(),
  //     requestUserMeta(),
  //     requestDictionary(),
  //   ]);
  // }
  // }

  componentDidUpdate() {
    if (this.mainPanel) this.mainPanel.scrollTop = 0;
  }

  onResize = () => {
    if (this.scrollbar) this.scrollbar.updateScroll();
  };

  render() {
    const { notifications, classes, auth, t, ...rest } = this.props;
    const {
      snackbar,
      notViewed: noteViewedList,
      list: noteList,
    } = notifications || {};
    const { mobileOpen, itIsStatisticsPage, loading } = this.state;

    if (itIsStatisticsPage) {
      return routes;
    }

    return (
      <div className={classes.wrapper} id={setId("all-wrapper")}>
        {/* <Progress percent={99} style={{zIndex: 2}} color="#4285f4"/> */}
        <Sidebar
          routes={appRoutes}
          logo={logo}
          handleDrawerToggle={this.handleDrawerToggle.bind(this)}
          open={mobileOpen}
          color="yellow"
          setId={(elementName) => setId(`side-bar-${elementName}`)}
          {...rest}
          noteList={
            noteViewedList && !!noteViewedList.length
              ? noteViewedList
              : noteList
          }
        />
        <PerfectScrollbar
          ref={(ref) => {
            this.scrollbar = ref;
          }}
        >
          <div
            className={classes.mainPanel}
            ref={(ref) => {
              this.mainPanel = ref;
            }}
            id={setId("page-with-header-wrapper")}
          >
            <Header
              routes={appRoutes}
              handleDrawerToggle={this.handleDrawerToggle.bind(this)}
              setId={(elementName) => setId(`header-${elementName}`)}
              {...rest}
            />
            {auth && auth.info && (
              <AuthDialog
                open={!!auth.authError}
                t={t}
                authInfo={auth}
                handleLogout={this.handleLogout}
                handleIndividualClick={this.handleIndividualClick}
                handleLegalEntityAgentClick={this.handleLegalEntityAgentClick}
                handleRegProfile={this.handleRegProfile}
                handleRegCompany={this.handleRegCompany}
              />
            )}
            <AuthDialog
              open={!!auth.authError}
              t={t}
              authInfo={auth}
              handleLogout={this.handleLogout}
              handleIndividualClick={this.handleIndividualClick}
              handleLegalEntityAgentClick={this.handleLegalEntityAgentClick}
              handleRegProfile={this.handleRegProfile}
              handleRegCompany={this.handleRegCompany}
            />
            <div className={classes.content} id={setId("page-wrapper")}>
              <div className={classes.container} id={setId("page-subwrapper")}>
                {loading && <BlockScreen open={loading} />}
                {!loading && routes}
                <ReactResizeDetector
                  handleWidth={true}
                  handleHeight={true}
                  onResize={this.onResize}
                />
              </div>
            </div>
          </div>
          <SnackbarMessages list={snackbar} />
        </PerfectScrollbar>
        {!loading &&
          warnings &&
          !!warnings.length &&
          warnings.map((warningName) => (
            <WarningDialog key={warningName} warningName={warningName} />
          ))}
      </div>
    );
  }
}

App.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  notifications: PropTypes.object,
  viewed: PropTypes.array.isRequired,
  meta: PropTypes.object,
  auth: PropTypes.object.isRequired,
};

App.defaultProps = {
  notifications: null,
  meta: {},
};

const mapStateToProps = ({
  authorization: auth,
  router,
  notifications,
  usermeta: { userMetaData },
}) => {
  let viewed = [];
  const { meta } = userMetaData || {};
  if (userMetaData && meta && meta.viewedNotification) {
    ({
      meta: { viewedNotification: viewed },
    } = userMetaData);
  }
  return {
    auth,
    router,
    notifications,
    viewed,
    meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkLegalEntityAgentRole: (role) =>
      dispatch(checkLegalEntityAgentRole(role)),
  };
};

export default compose(
  withStyles(appStyle),
  translate("Auth"),
  connect(mapStateToProps, mapDispatchToProps)
)(App);
