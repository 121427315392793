import {
  requestBenefitTypes,
  requestPartTypes,
  requestTransactionTypes,
} from "../../../../actions/payment";

export default function () {
  const {
    dictionary,
    document: doc,
    value,
    actions: { setBusy },
    document,
  } = this.props;
  const actions = [];
  setBusy(true);

  if (!document.digitalDocumentData.court) {
    let payment = {};
    Object.assign(payment, document.value);
    document.digitalDocumentData.court = {
      court: document.courtId,
    };
    document.digitalDocumentData.payment = payment;
  }

  if (!dictionary || !dictionary.benefitTypes) {
    actions.push(requestBenefitTypes);
  }

  if (!dictionary || !dictionary.partTypes) {
    actions.push(requestPartTypes);
  }

  if (!dictionary || !dictionary.transactionTypes) {
    actions.push(requestTransactionTypes);
  }

  Promise.all(actions.map((action) => action())).then(() => {
    if (!doc || !doc.id) {
      return;
    }
    this.onChange(value, true);
  });
}
