import React from "react";
import PropTypes from "prop-types";

import { Dialog, withStyles } from "@material-ui/core";
import cx from "classnames";

import shareDialogStyles from "../../variables/styles/shareDialog";
import customInputStyle from "../../variables/styles/customInputStyle";
import { withRouter } from "react-router-dom";

const styles = { ...customInputStyle, ...shareDialogStyles };

const ShareDialogWrapper = ({
  toggleDialog,
  display,
  classes,
  busy,
  setId,
  children,
  location,
}) => {
  const open = !!display && display !== "delete" && display !== "signing";
  return (
    <Dialog
      open={open}
      onClose={!busy ? toggleDialog(display) : undefined}
      id={setId("wrapper")}
      className={cx(classes.dialog, classes.fullWidth)}
      PaperProps={{
        style:
          location.pathname === "/cases" ||
          location.pathname.includes("/cases/case=")
            ? {
                maxWidth: "80%",
                width: "90vw",
                maxHeight: "80%",
                height: "80vh",
              } // Якщо path === "cases"
            : {}, // Інакше стилі не застосовуються
      }}
    >
      {children}
    </Dialog>
  );
};

const styled = withStyles(styles)(ShareDialogWrapper);

ShareDialogWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  busy: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  display: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(styled);
