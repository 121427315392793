import { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { translate } from "react-translate";

import { withStyles } from "@material-ui/core";
import { blobToTextEncoded } from "../../helpers/blobToText";
import getFormat from "../../helpers/getAttachFormat";
import stringToBlob from "../../helpers/stringToBlob";
import formatFile from "../../helpers/formatFile";
import getFileUrl from "../../helpers/getFileUrl";

import { searchUser } from "../../actions/user";
import {
  addPermission,
  addPreparePermission,
  editPermission,
  requestPermissionsList,
} from "../../actions/permissions";
import { generatePdf, requestPdf } from "../../actions/claim";

import shareDialogStyles from "../../variables/styles/shareDialog";
import customInputStyle from "../../variables/styles/customInputStyle";
import openInNewWindowStyles from "../../variables/styles/openInNewWindow";

import getPermission from "../../helpers/permission";
import ShareDialogLayout from "./ShareDialogLayout";
import blobToBase64 from "../../helpers/blobToBase64";
import checkECP from "../../helpers/checkECP";
import { DisplayPreviewDialog } from "./types";

const styles = {
  ...customInputStyle,
  ...shareDialogStyles,
  ...openInNewWindowStyles,
};

const clearPreview = () => ({
  blob: null,
  base64: "",
  url: "",
});

class ShareDialog extends Component {
  state = {
    isСhanged: false,
    openChainOfResharingsDialog: false,
    busy: false,
    INN: "",
    caseNumber: "",
    // targetId для чекбокса "На Судочинство"
    toStrJusticeId: "",
    courtStateChecked: false,
    caseNumIsChecked: false,
    permission: this.props.changedShare
      ? getPermission(this.props.changedShare).name
      : "allowRead",
    error: "", //this.props.error,
    foundUser: null,
    duplicateShare: null,
    response: null,
    pdf: null,
    doc: null,
    docBase64: null,
    allowShare:
      !!this.props.changedShare && this.props.changedShare.allowShare === 1,
    allowAsvp: !!this.props.changedShare && this.props.changedShare.allowAsvp,
    openedPreviewDialog: false,
    displayPreviewDialog: DisplayPreviewDialog.Share,
    previewDialog: clearPreview(),
  };

  setSharePreview = () => {
    this.setState((prevState) => ({
      previewDialog: {
        blob: prevState.doc,
        base64: prevState.docBase64,
        url: prevState.pdf,
      },
    }));
  };

  setCheckECPPreview = async () => {
    try {
      const data = await checkECP(this.props.changedShare.documentId);
      if (!data) return;

      this.setState(() => ({
        previewDialog: {
          blob: data.file,
          base64: data.doc,
          url: data.pdf,
        },
      }));
    } catch (e) {
      // this.showError(e.message || '404 File not found');
    }
  };

  setPdfPreview = async () => {
    try {
      const { changedShare } = this.props;
      if (
        changedShare &&
        changedShare.documentId &&
        (changedShare.allowCommit === 1 || changedShare.allowAsvp)
      ) {
        let file = await requestPdf(changedShare.documentId);
        if (!file) return;
        if (
          !!file.size &&
          !file.message &&
          (typeof file === "string" || typeof file === "object")
        ) {
          if (typeof file === "string") {
            file = stringToBlob(file);
          }
          const [base64, text, source] = await Promise.all([
            blobToBase64(file),
            blobToTextEncoded("Windows-1251")(file),
            file,
          ]);
          const formatSource = await formatFile(source, text);
          const format = await getFormat(formatSource, text);
          const url = await getFileUrl(formatSource, format, text);
          this.setState({
            error: "",
            pdf: url,
            docBase64: base64,
            doc: formatSource,
            busy: false,
          });
          this.setState(() => ({
            previewDialog: {
              blob: file,
              base64: base64,
              url: url,
            },
          }));
        } else {
          this.setState({
            pdf: null,
            doc: null,
            docBase64: null,
            busy: false,
          });
        }
      }
    } catch (e) {
      this.showError(e.message || "Документ не знайдено");
    }
  };

  togglePreviewDialog = (display) => () => {
    this.setState(
      (state) => ({
        openedPreviewDialog: !state.openedPreviewDialog,
        displayPreviewDialog: display,
      }),
      () => {
        switch (display) {
          case DisplayPreviewDialog.Share:
            return this.setPdfPreview();
          case DisplayPreviewDialog.ECP:
            return this.setCheckECPPreview();
          default:
            this.setState({
              previewDialog: clearPreview(),
            });
        }
      }
    );
  };

  setLoading = (busy) => this.setState({ busy });

  // componentDidMount() {
  // }

  UNSAFE_componentWillReceiveProps({ changedShare, display }) {
    if (
      !changedShare ||
      display !== this.props.display ||
      (changedShare &&
        this.props.changedShare &&
        changedShare.id !== this.props.changedShare.id)
    ) {
      const permission = changedShare
        ? getPermission(changedShare).name
        : "allowRead";
      this.setState({
        pdf: null,
        docBase64: null,
        permission,
        allowShare: !!changedShare && changedShare.allowShare === 1,
        allowAsvp: !!changedShare && changedShare.allowAsvp === 1,
      });
      // this.loadPdf();
    }
  }

  onChangePermission = ({ target: { value } }) =>
    this.setState({
      permission: value,
      isСhanged: true,
    });

  testFoundUser = () => {
    const { currentUserId, t, foundUser /*list, changedShare, targetId*/ } =
      this.props;
    if (currentUserId === foundUser.userId) {
      this.setState({
        error: t("INN_SELF_ERROR"),
        foundUser: null,
      });
    } else {
      // const firstLevelList = list.filter((item) => !item.parentId);
      // const duplicateShare = firstLevelList.find(
      //   (item) =>
      //     item.userId === foundUser.userId &&
      //     item.targetId === targetId &&
      //     item.isActive
      // );
      // const foundInChain =
      //   !!changedShare &&
      //   JSON.stringify(changedShare.chainOfResharings).indexOf(
      //     foundUser.userId
      //   ) > 0;
      // if (foundInChain) {
      //   this.setState({
      //     error: t("DUPLICATE_IN_SHARE"),
      //     foundUser: null,
      //   });
      // } else if (duplicateShare && !changedShare) {
      //   this.setState({
      //     error: t("INN_DUPLICATE_ERROR"),
      //     foundUser: null,
      //     duplicateShare,
      //   });
      // } else {
      this.setState({
        error: "",
        foundUser,
        duplicateShare: null,
      });
      // }
    }
    this.setState({ busy: false });
  };

  onChangeINN = ({ target: { value } }) => {
    const { t } = this.props;
    this.setState(
      {
        INN: value,
        foundUser: null,
        busy: value.length > 7,
      },
      () => {
        if (value.length > 7) {
          searchUser({ code: value }).then(() => {
            const { foundUser } = this.props;
            if (!foundUser) {
              this.setState({
                error: t("INN_BASE_ERROR"),
                foundUser: null,
                duplicateShare: null,
                busy: false,
              });
            } else {
              this.testFoundUser();
            }
          });
        } else {
          this.setState({
            error: "",
            foundUser: null,
          });
        }
      }
    );
  };

  onBlurINN = () => {
    // const { t } = this.props;
    // const { INN, lastSearchedINN } = this.state;
    // // Перевіряємо, чи вже виконувався пошук для цього значення
    // if (!INN || INN === lastSearchedINN || INN.length <= 7) {
    //   return;
    // }
    // this.setState(
    //   {
    //     busy: true,
    //     lastSearchedINN: INN, // Запам'ятовуємо останнє значення, для якого виконувався пошук
    //   },
    //   () => {
    //     searchUser({ code: INN }).then(() => {
    //       const { foundUser } = this.props;
    //       if (!foundUser) {
    //         this.setState({
    //           error: t("INN_BASE_ERROR"),
    //           foundUser: null,
    //           duplicateShare: null,
    //           busy: false,
    //         });
    //       } else {
    //         this.testFoundUser();
    //       }
    //     });
    //   }
    // );
  };

  onChangeCaseNumber = ({ target: { value } }) => {
    this.setState({
      caseNumber: value,
    });
  };

  onBlurCaseNumber = () => {
    const foundCase = this.state.cases.find(
      (item) => item.number === this.state.caseNumber
    );
    this.setState({
      caseId: foundCase.id,
    });
  };

  handleChangeCourtStateCheckBox = ({ target: { checked } }) => {
    this.setState({
      courtStateChecked: checked,
    });
    // если выключаем чек бокс "На судочинство" то очищаем в стейте параметр toStrJusticeId
    if (!checked) {
      this.setState({
        toStrJusticeId: "",
      });
    }
  };

  handleChangeCaseNumIsChecked = ({ target: { checked } }) => {
    this.setState({
      caseNumIsChecked: checked,
    });
    if (!checked) {
      this.setState({
        caseNumber: "",
      });
    }
  };

  onChangeJusticeCheckBox = (value) => {
    // получаем value из селекта "судочинства" в ShareDialogPermissions
    // преобразовывает justiceId в строку, для передачи в запрос /api/permissions & /api/permissions/prepare_document
    this.setState({
      toStrJusticeId: value,
    });
  };

  onClose = (closeFunction) => () => {
    this.clearINN();
    closeFunction();
  };

  toogleChainOfResharingsDialog = () =>
    this.setState({
      openChainOfResharingsDialog: !this.state.openChainOfResharingsDialog,
    });

  // чекбокс "Передоручення"
  toggleReshare = ({ target: { checked } }) =>
    this.setState({
      allowShare: checked,
      isСhanged: true,
    });

  // чекбокс "Передоручення"
  toggleAllowAsvp = ({ target: { checked } }) => {
    this.setState({
      allowAsvp: checked,
      isСhanged: true,
    });
  };

  clearINN = () =>
    this.setState({
      busy: false,
      INN: "",
      error: "",
      foundUser: null,
      duplicateShare: null,
    });

  refreshList = () => {
    if (this.props.permissionsDataSource) {
      return this.props.permissionsDataSource.load();
    }

    return requestPermissionsList();
  };

  getParentData = (withChangedShare) => {
    const { changedShare, targetId, targetType } = this.props;
    const { documentId } = this.state;
    return Object.assign(
      {},
      withChangedShare && changedShare && { parentId: changedShare.id },
      withChangedShare &&
        changedShare &&
        changedShare.targetId && { targetId: changedShare.targetId },
      withChangedShare &&
        changedShare &&
        changedShare.targetType && {
          targetType: changedShare.targetType,
        },
      targetId && { targetId },
      targetType && { targetType },
      documentId && { documentId },
      withChangedShare &&
        changedShare && {
          meta: {
            ...changedShare.meta,
            parentIsExtract: changedShare.typeId === 2 ? 1 : 0,
            // changedShare.typeId === 2
          },
        }
    );
  };

  getData(itIsUpdate = false) {
    const { changedShare } = this.props;
    let { targetType, targetId, reshare, justiceTypes } = this.props;
    // const parentData = this.getParentData(true);
    const {
      permission,
      foundUser,
      allowShare,
      allowAsvp,
      toStrJusticeId,
      caseNumber,
      courtStateChecked,
      caseNumIsChecked,
    } = this.state;

    const { userId } = itIsUpdate ? changedShare : foundUser;
    if (courtStateChecked) {
      targetType = 3;
    }
    if (caseNumIsChecked) {
      targetType = 2;
    }
    const parentData = !itIsUpdate
      ? this.getParentData(reshare)
      : {
          id: changedShare.id,
          targetId,
          targetType,
        };

    if (itIsUpdate && changedShare && changedShare.parentId) {
      parentData.parentId = changedShare.parentId;
    }
    // if (parentData.targetType === 0) {
    //   targetType = targetType || (caseNumber ? 2 : toStrJusticeId ? 3 : null);
    //   targetId = targetId || (targetType === 3 ? toStrJusticeId : null);
    // } else {
    //   targetType = targetType || parentData.targetType;
    //   targetId = targetId || parentData.targetId;
    // }

    if (targetType === 2) {
      parentData.meta = {
        ...parentData.meta,
        ...(caseNumber !== "" ? { caseNumber } : {}),
      };
    } else if (targetType === 3) {
      targetId = String(toStrJusticeId);
      // const justiceName = justiceTypes.find(
      //   (item) => item.id === parseInt(targetId)
      // )?.nameGen;
      parentData.meta = {
        ...parentData.meta,
        justiceTypes,
      };
    }
    return {
      // justiceName добавить в meta со справочника jurisdictionTypes
      ...parentData,
      targetType,
      targetId,
      userId,
      permissionType: permission,
      allowShare,
      allowAsvp: !!allowAsvp,
      meta: {
        ...(caseNumber !== "" ? { caseNumber } : {}),
      },
    };
  }

  addPermissionNoCommit = () => {
    const { toggleDialog, display, changedShare, t } = this.props;
    const list = this.props?.list || [];

    let data = this.getData();
    if (changedShare) {
      data.targetType = changedShare.targetType;
      data.targetId = changedShare.targetId;
    }

    const newDisplay =
      display === "addResharePermissions" ? "addReshare" : display;

    addPermission(data).then((response) => {
      if (
        response.message ===
        "The destination user has already had this permission!"
      ) {
        this.setState({
          error: t("RESHARE_DUPLICATE_ERROR"),
          duplicateShare: changedShare,
          busy: false,
        });
      } else if (response instanceof Error) {
        this.setState({
          error: response.message,
          busy: false,
        });
      } else if (!list.length) {
        this.componentWillUnmount = () => toggleDialog(newDisplay, true);
        this.refreshList();
      } else {
        this.refreshList().then(() =>
          this.setState(
            {
              busy: false,
              error: "",
            },
            toggleDialog(newDisplay, true)
          )
        );
      }
    });
  };

  addPermissionWithCommit = (itIsUpdate = false) => {
    const { setPdf, changedShare } = this.props;
    let data = this.getData(itIsUpdate);

    if (changedShare) {
      data.targetType = changedShare.targetType;
      data.targetId = changedShare.targetId;
    }

    addPreparePermission(data).then((response) => {
      if (response instanceof Error) {
        this.setState({
          error: response.message,
          busy: false,
        });
      } else {
        const { docId: documentId } = response;
        generatePdf(documentId, {
          ...data,
          documentId,
        }).then((doc) => {
          this.setState(
            {
              busy: false,
              error: "",
            },
            () => setPdf(doc, data, documentId, itIsUpdate)
          );
        });
      }
    });
  };

  addShare =
    (itIsUpdate = false) =>
    () =>
      this.setState({ busy: true }, () => {
        const { permission, allowAsvp } = this.state;

        if (permission === "allowCommit" || !!allowAsvp) {
          this.addPermissionWithCommit(itIsUpdate);
        } else {
          this.addPermissionNoCommit();
        }
      });

  changePermission = () => {
    const { toggleDialog, display } = this.props;
    const { permission, allowShare, allowAsvp } = this.state;
    const {
      changedShare: { userId, id },
    } = this.props;
    if (permission === "allowCommit") {
      this.addPermissionWithCommit(true);
      this.refreshList();
    } else {
      this.setState({ busy: true }, () =>
        editPermission(id, {
          userId,
          permissionType: permission,
          allowShare,
          allowAsvp: !!allowAsvp,
        }).then(() =>
          this.refreshList().then(() =>
            this.setState({ busy: false }, () => toggleDialog(display, true)())
          )
        )
      );
    }
  };

  render() {
    const { display, error, busy, setId } = this.props;

    return (
      <ShareDialogLayout
        {...this.props}
        {...this.state}
        setId={(elementName) => setId(`dialog-${display}-${elementName}`)}
        error={this.state.error || error}
        busy={!!this.state.busy || !!busy}
        onClose={this.onClose}
        toogleChainOfResharingsDialog={this.toogleChainOfResharingsDialog}
        addShare={this.addShare}
        clearINN={this.clearINN}
        onChangeINN={this.onChangeINN}
        onBlurINN={this.onBlurINN}
        handleChangeCaseNumIsChecked={this.handleChangeCaseNumIsChecked}
        handleChangeCourtStateCheckBox={this.handleChangeCourtStateCheckBox}
        onChangeJusticeCheckBox={this.onChangeJusticeCheckBox}
        onChangeCaseNumber={this.onChangeCaseNumber}
        changePermission={this.changePermission}
        onChangePermission={this.onChangePermission}
        toggleReshare={this.toggleReshare}
        toggleAllowAsvp={this.toggleAllowAsvp}
        togglePreviewDialog={this.togglePreviewDialog}
        id={this.props?.changedShare?.id}
        pdf={this.state.pdf}
      />
    );
  }
}

ShareDialog.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  foundUser: PropTypes.object,
  busy: PropTypes.bool,
  toggleDialog: PropTypes.func.isRequired,
  handleClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  display: PropTypes.string.isRequired,
  changedShare: PropTypes.object,
  reshare: PropTypes.bool,
  onSelectAllClick: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  createSortHandler: PropTypes.func.isRequired,
  onCheckItem: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  checked: PropTypes.array.isRequired,
  setPdf: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  targetType: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  targetId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  shareSubTitle: PropTypes.node,
  description: PropTypes.string,
  notShowCloseButton: PropTypes.bool,
};

ShareDialog.defaultProps = {
  changedShare: null,
  foundUser: null,
  reshare: true,
  busy: false,
  handleClick: null,
  onCheckItem: null,
  onSelectAllClick: null,
  targetType: null,
  targetId: null,
  shareSubTitle: "",
  description: "",
  notShowCloseButton: false,
};

const styled = withStyles(styles)(ShareDialog);
const translated = translate("Share")(styled);

const mapStateToProps = (state) => {
  // console.log("hubi Redux State:", state); // Виводимо весь state у консоль

  return {
    foundUser: state.authorization.foundUser,
    userInfo: state.authorization.info,
    justiceTypes: state.dictionary.jurisdiction_types,
  };
};

export default connect(mapStateToProps)(translated);
