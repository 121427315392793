import React, { Component } from "react";
import PropTypes from "prop-types";
import setComponentsId from "../../../helpers/setComponentsId";

import { connect } from "react-redux";
import { translate } from "react-translate";
import { withStyles } from "@material-ui/core";
import styles from "../../../variables/styles/paymentStyle";

import PaymentLayout from "./paymentComponents/PaymentLayout";
import updatePaymentForm from "./paymentDecorations/updatePaymentForm";
import onChangePayment from "./paymentDecorations/onChangePayment";
import componentDidMount from "./paymentDecorations/componentDidMount";
import openPayWindow from "./paymentDecorations/openPayWindow";

const regExp = /^(\d{0,12})([.,](\d{1,2})?)?$/;

export const replacePoint = (value) =>
  value ? `${value}`.replace(".", ",") : "0";

export const valueToString = (value, oldValue = "0") => {
  if (!value) return "0";
  const newValue = replacePoint(value.userDefinedTotalSum || value.totalSum);
  if (oldValue === `${newValue}0`) {
    return oldValue;
  }
  return oldValue === `${newValue}0` ? oldValue : newValue;
};

class Payment extends Component {
  state = {
    busy: true,
    useBenefits:
      this.props.value &&
      this.props.value.benefits &&
      this.props.value.benefits.useBenefits,
    otherAmount: !!(this.props.value && this.props.value.userDefinedTotalSum),
    form: null,
    openUploadModal: false,
    newSum: valueToString(this.props.value),
    noCaseError: "",
    isUpdating: false,
    error:
      this.props.value &&
      this.props.value.errors &&
      this.props.value.errors.error
        ? this.props.value.errors.error
        : "",
    courtId: this.props.document.courtId,
  };

  componentDidMount = componentDidMount.bind(this);
  updatePaymentForm = updatePaymentForm.bind(this);
  onChange = onChangePayment.bind(this);
  handleOpenPayWindow = openPayWindow.bind(this);

  onOpenUploadModal = () => this.setState({ openUploadModal: true });
  closeUploadModal = () => this.setState({ openUploadModal: false });
  clearBenefits = (useBenefits) => {
    const { value, document } = this.props;
    let { newSum } = this.state;
    newSum = useBenefits ? this.state.newSum : valueToString(value, newSum);
    document.digitalDocumentData.properties = {
      properties: { court: document.courtId },
    };
    this.setState(
      {
        useBenefits,
        busy: false,
        newSum,
      },
      () =>
        this.onChange({
          ...value,
          benefits: { useBenefits },
        })
    );
  };

  toggleMode = () => {
    const { value, actions } = this.props;
    const { deleteAttach } = actions || {};
    const { confirmationFiles } = (value || {}).benefits || {};
    const useBenefits = !this.state.useBenefits;
    if (!useBenefits && confirmationFiles && !!confirmationFiles.length) {
      this.setState({ busy: true }, () =>
        Promise.all(
          confirmationFiles.map(({ attachId }) => deleteAttach(attachId))
        ).then(() => this.clearBenefits(useBenefits))
      );
    } else {
      this.clearBenefits(useBenefits);
    }
  };

  toggleUseUserAmount = () => {
    const {
      value,
      value: { totalSum },
    } = this.props;
    const otherAmount = !this.state.otherAmount;
    let { newSum } = this.state;
    newSum = otherAmount
      ? valueToString(value, newSum)
      : replacePoint(totalSum);
    this.setState(
      {
        otherAmount,
        newSum,
        busy: true,
      },
      () => this.handleChangeUserAmount({ target: { value: newSum } })
    );
  };

  getSuccessTransaction() {
    const { value } = this.props;

    if (
      !value.transactionsHistory ||
      !Array.isArray(value.transactionsHistory)
    ) {
      return null;
    }

    return value.transactionsHistory
      .filter(({ status }) => status === "success")
      .shift();
  }

  handleChangeUserAmount = ({ target: { value } }) => {
    const newValue = value.replace(/^0+/, "") || "0";
    const { value: propsValue } = this.props;
    let { newSum, newSum: setValue } = this.state;

    if (+newValue === 0) {
      setValue = propsValue.totalSum;
      newSum = newValue;
    } else if (!newValue || regExp.test(newValue)) {
      newSum = newValue.replace(".", ",");
      setValue =
        +newValue.replace(",", ".") ||
        propsValue.userDefinedTotalSum ||
        propsValue.amountToPay;
    }
    if (value === "0." || value === "0,") {
      newSum = "0,";
      setValue = 0;
    } else if (!newValue && value && regExp.test(value)) {
      newSum = value;
    }

    this.setState(
      {
        busy: true,
        newSum,
      },
      () =>
        this.onChange({
          ...propsValue,
          userDefinedTotalSum: setValue,
          amountToPay: setValue,
        })
    );
  };

  handleChangeBenefits = ({ target }) => {
    const { value } = this.props;

    const newValue = {
      ...value,
      benefits: {
        ...value.benefits,
        type: target.value,
      },
    };
    this.onChange(newValue);
  };

  handleDelete = (index) => () => {
    const { value, actions } = this.props;
    const {
      benefits,
      benefits: { confirmationFiles },
    } = value || {};
    const { deleteAttach } = actions || {};

    if (confirmationFiles && deleteAttach) {
      deleteAttach(confirmationFiles[index].attachId);
      confirmationFiles.splice(index, 1).shift();
    }

    const newValue = {
      ...value,
      benefits: {
        ...benefits,
        confirmationFiles,
      },
    };

    this.onChange(newValue);
    this.setState({ openUploadModal: false });
  };

  /**
   * @typedef {Object} SavedAttach
   * @property {number} attachId
   * @property {number} size
   * @property {string} fileLink
   * @property {string} mimeType
   * @property {string} userFileName
   */

  /**
   * @param {SavedAttach} result
   */
  handleUpload = (result) => {
    const { value } = this.props;
    // console.log("result: ", result);

    const { benefits } = value || {};
    const { confirmationFiles = [] } = benefits;

    const newValue = {
      ...value,
      benefits: {
        ...benefits,
        confirmationFiles: [...confirmationFiles, result],
      },
    };

    this.onChange(newValue);
    this.setState({ openUploadModal: false });
  };

  render() {
    const { setId, value } = this.props;
    const transaction = this.getSuccessTransaction();
    const { otherAmount, newSum } = this.state;
    const busy =
      this.state.busy ||
      (otherAmount && newSum !== valueToString(value, newSum));

    return (
      <PaymentLayout
        {...this.props}
        {...this.state}
        value={{ ...(value || {}) }}
        setId={setId}
        transaction={transaction}
        toggleMode={this.toggleMode}
        onOpenUploadModal={this.onOpenUploadModal}
        handleChangeBenefits={this.handleChangeBenefits}
        handleDelete={this.handleDelete}
        handleUpload={this.handleUpload}
        closeUploadModal={this.closeUploadModal}
        toggleUseUserAmount={this.toggleUseUserAmount}
        handleChangeUserAmount={this.handleChangeUserAmount}
        handleOpenPayWindow={this.handleOpenPayWindow}
        busy={busy}
      />
    );
  }
}

Payment.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.object,
  actions: PropTypes.object,
  document: PropTypes.object,
  dictionary: PropTypes.object,
  setId: PropTypes.func,
  t: PropTypes.func.isRequired,
};

Payment.defaultProps = {
  onChange: undefined,
  value: {},
  actions: {},
  document: null,
  dictionary: null,
  setId: setComponentsId("payment"),
};

const styled = withStyles(styles)(Payment);
const translated = translate("Payment")(styled);

export default connect((state) => ({ dictionary: state.payment }))(translated);
