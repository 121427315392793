import {
  createClaim,
  storeClaimValue,
  uploadDocumentAttach,
  setWizardStates,
} from "../actions/claim";

const filterFiles = (files) =>
  files.filter(
    (file) => !file.message && !file.serverMessage && file.attachId && file.link
  );

export default async (
  templateId,
  ownership,
  additionData = {},
  additionProperties = [],
  passPhrase,
  properties
) => {
  const claim = await createClaim(templateId, {
    ...ownership,
    ...additionData,
  });
  const attachDefault = claim.attaches;
  if (claim.message || claim.serverMessage || passPhrase) {
    return claim;
  }

  try {
    const files = ownership && ownership.files;

    if (!!additionProperties) {
      properties = additionProperties;
      properties.push({
        path: "ownerShip",
        value: ownership,
      });
    } else {
      properties = [
        {
          path: "ownerShip",
          value: ownership,
        },
      ];
    }

    if (Array.isArray(files)) {
      // const attaches = await Promise.all(
      //   files.map((file) => uploadDocumentAttach(claim.id, file))
      // );

      // for (let item of claim.attaches) {
      //   attaches.push(item);
      // }
      const attaches = [];
      for (const file of files) {
        const attach = await uploadDocumentAttach(claim.id, file);
        attaches.push(attach);
      }

      attachDefault.forEach((attach) => {
        attaches.push(attach);
      });

      const filteredFiles = filterFiles(attaches);
      properties = [
        ...properties,
        {
          path: "attaches",
          value: [
            ...filteredFiles.map((attach) => ({
              ...attach,
              name: attach.fileName,
              userFileName: attach.userFileName || attach.fileName,
              mimeType: attach.contentType,
            })),
            // ...attachDefault, // Додаємо всі елементи з attachDefault
          ],
        },
      ];

      if (filteredFiles.length < files.length) {
        properties = [
          ...properties,
          {
            path: "uploadError",
            value: files.length - filteredFiles.length,
          },
        ];
        setWizardStates({ error: "ERROR_UPLOADING_FILE" });
      }
    }

    await storeClaimValue(claim.id, { properties });
  } catch (e) {
    console.log("CREATE_CLAIM_ERROR", e);
  }
  return claim;
};
