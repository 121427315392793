import promiseChain from "../../../helpers/promiseChain";
import claimCreate from "../../../helpers/createClaim";

import { updateDocumentCourt } from "../../../actions/claim";
// import { saveActiveCase } from "../../../actions/cases";

export function handleClaimCreate(templateId, template) {
  return () =>
    this.setState({ openCreateClaimDialog: false, blockScreen: true }, () => {
      // const { history, list, t, proceedingsList, owners } = this.props;
      // const { causeActionType } = template;
      // const {
      //   ownership,
      //   checked,
      //   procChecked,
      //   appealCourt,
      //   activeCase,
      //   activeProc,
      //   viewDocument,
      // } = this.state;
      // const {
      //   id: decisionDocumentId,
      //   proceedingId,
      //   courtId,
      // } = viewDocument || {};
      // const currentCase = Array.isArray(list)
      //   ? list.find((caseData) => caseData.id === checked) || null
      //   : null;
      // const foundProc = Array.isArray(proceedingsList)
      //   ? proceedingsList.find(({ id }) => id === procChecked)
      //   : {};
      // const claimCourtId =
      //   causeActionType === 1 || causeActionType === 2
      //     ? (appealCourt || {}).id
      //     : (activeProc || foundProc || {}).courtId || courtId;
      // delete template.text;
      // const data = Object.assign(
      //   {
      //     caseId: checked,
      //     proceedingId: procChecked || proceedingId,
      //     courtId: claimCourtId,
      //   },
      //   (causeActionType === 1 || causeActionType === 2) &&
      //     viewDocument && { decisionDocumentId }
      // );

      // if (ownership.representativeClaimant) {
      //   const permissions = owners || [];
      //   let permission = permissions.find(
      //     (item) =>
      //       item.allowedBy === ownership.claimentUserId &&
      //       item.addClaimPermission &&
      //       ((item.targetType === 0 && !item.targetId) ||
      //         (item.targetType === 1 && item.targetId === procChecked) ||
      //         (item.targetType === 2 && item.targetId === checked) ||
      //         (item.targetType === 3 &&
      //           activeProc &&
      //           parseInt(item.targetId) === activeProc.jurisdictionTypeId))
      //   );

      //   if (permission) {
      //     data.permissionId = permission.id;
      //   }
      // }

      // const properties = [
      //   { path: "court", value: { court: claimCourtId } },
      //   { path: "templateWhenCreate", value: template },
      //   { path: "courtWhenCreate", value: claimCourtId },
      //   {
      //     path: "proceedingId",
      //     value: procChecked || proceedingId,
      //   },
      //   { path: "caseCourt", value: (activeCase || {}).courtId },
      //   { path: "parentProc", value: activeProc || foundProc },
      // ];

      // return promiseChain([
      //   () =>
      //     saveActiveCase({
      //       ...(currentCase || activeCase),
      //       claimCourtId,
      //     }),
      //   () => claimCreate(templateId, ownership, data, properties),
      //   (claim) =>
      //     claim.message || claim.serverMessage
      //       ? this.setState({
      //           blockScreen: false,
      //           error: t(claim.message || claim.serverMessage),
      //         })
      //       : updateDocumentCourt(claim.id, claimCourtId).then(() =>
      //           history.push(`/cases_claims/${claim.id}`)
      //         ),
      // ]).catch((e) =>
      //   this.setState({
      //     blockScreen: false,
      //     error: t(e.message || e.serverMessage),
      //   })
      // );
      const { history, t, owners } = this.props;
      const { causeActionType } = template;
      const {
        ownership,
        checked,
        procChecked,
        appealCourt,
        activeCase,
        activeProc,
        viewDocument,
      } = this.state;
      const { id: decisionDocumentId, proceedingId } = viewDocument || {};

      const claimCourtId =
        causeActionType === 1 || causeActionType === 2
          ? appealCourt?.id || activeProc?.courtId || null
          : activeProc?.courtId || null;

      if (!claimCourtId) {
        this.setState({ error: t("COURT_ID_MISSING") });
        console.error("COURT_ID_MISSING");
        return;
      }

      const data = Object.assign(
        {
          caseId: checked,
          proceedingId: procChecked || proceedingId,
          courtId: claimCourtId,
        },
        (causeActionType === 1 || causeActionType === 2) &&
          viewDocument && { decisionDocumentId }
      );

      if (ownership.representativeClaimant) {
        const permissions = owners || [];
        let permission = permissions.find(
          (item) =>
            item.allowedBy === ownership.claimentUserId &&
            item.addClaimPermission &&
            ((item.targetType === 0 && !item.targetId) ||
              (item.targetType === 1 && item.targetId === activeProc.id) ||
              (item.targetType === 2 && item.targetId === activeCase.id) ||
              (item.targetType === 3 &&
                parseInt(item.targetId) === activeProc.jurisdictionTypeId))
        );

        if (permission) {
          data.permissionId = permission.id;
        }
      }

      const properties = [
        { path: "court", value: { court: claimCourtId } },
        { path: "templateWhenCreate", value: template },
        { path: "courtWhenCreate", value: claimCourtId },
        {
          path: "proceedingId",
          value: activeProc.id, //2189 << Id
        },
        { path: "caseCourt", value: activeCase.courtId },
        { path: "parentProc", value: activeProc },
      ];

      return promiseChain([
        () => claimCreate(templateId, ownership, data, properties),

        (claim) =>
          claim.message || claim.serverMessage
            ? this.setState({
                blockScreen: false,
                error: t(claim.message || claim.serverMessage),
              })
            : updateDocumentCourt(claim.id, claimCourtId)
                .then(() => {
                  history.push(`/cases_claims/${claim.id}`);
                })
                .catch((error) =>
                  this.setState({
                    blockScreen: false,
                    error: t(error.message || error.serverMessage),
                  })
                ),
      ]).catch((e) =>
        this.setState({
          blockScreen: false,
          error: t(e.message || e.serverMessage),
        })
      );
    });
}

export function changeOwnership(ownership) {
  let { appealCourt, appealCourtError } = this.state;
  if (ownership.toFirstInstance) {
    const {
      activeProc: { courtId },
    } = this.state;
    const { courts, t } = this.props;
    appealCourt = courts.find((court) => court.id === courtId);
    if (!appealCourt) {
      appealCourtError = t("APPEAL_COURT_NAME_ERROR", {
        courtName: "",
      });
    }
  }
  this.setState({
    appealCourt,
    appealCourtError,
    ownership,
  });
}
