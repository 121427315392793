import { requestPermissionsList } from "../../../actions/permissions";
import { requestClaimTemplates } from "../../../actions/claim";
import {
  requestActiveCase,
  requestCasesCourts,
  requestProceedingsCourts,
  clearCases,
  requestJudgeCasesCourts,
  requestDocument,
} from "../../../actions/cases";
import { setSplitPosition } from "../../../helpers/splitPosition";
import promiseChain from "../../../helpers/promiseChain";
import DataSource from "./dataSource";
import { initialState } from "./stateAndProps";
import { replaceHistory } from "../replaceHistory";
import { historyReturn } from "../../../helpers/history";

const {
  caseDataSource,
  caseDocDataSource,
  procDataSource,
  procDocDataSource,
  myProcDocDataSource,
  myMemberRolesDataSource,
  myCaseDocDataSource,
} = DataSource;

const getIdFromParam = (param) => (param ? param.split("=")[1] : "");

export default async function componentDidMount() {
  const {
    match: {
      params: { caseId, proceedingId, documentId },
    },
    page,
    role,
    t,
    templates,
  } = this.props;
  let { activeCase } = this.props;
  this.setState({ init: true });
  setSplitPosition("caseListSplit")("50%");
  caseDataSource.reset();
  // caseDataSource.setValue("id", "my");
  caseDataSource.setValue("id", role === "judge" ? "my_as_judge" : "my");
  myMemberRolesDataSource.baseUrl =
    role === "judge" ? "api/cases/my_judge_roles" : "api/cases/my_member_roles";

  const id = getIdFromParam(caseId);
  const procId = getIdFromParam(proceedingId);
  const docId = getIdFromParam(documentId);
  const searchParams = new URLSearchParams(window.location.search);

  const actions = [];
  if (!templates) actions.push(requestClaimTemplates());

  let isCurrentCase = false;

  if (id) {
    //Якщо задано id - то перехід зразу на сторінку справи або провадження
    // if ((activeCase?.id || "") !== id) {
    activeCase = await requestActiveCase(id);
    if (activeCase && !activeCase.message) {
      isCurrentCase = true;
      actions.push(requestProceedingsCourts(id));
    }
    // } else {
    //   isCurrentCase = true;
    // }
  } else {
    //Виклик списку справ
    actions.push(clearCases());
    actions.push(myMemberRolesDataSource.load());
    if (page === "cases") {
      actions.push(requestCasesCourts());
      actions.push(requestPermissionsList("?filter[isActive]=true"));
    } else {
      actions.push(requestJudgeCasesCourts());
    }
    // actions.push(dispatch(routerActions.replace(`/${page}`)));
  }

  this.setState({ ...initialState }, () => {
    Promise.all(actions).then(() => {
      // const isCurrentCase =
      //   id && activeCase && activeCase.id && activeCase.id === id;

      /*Если это список дел то isCurrentCase false, если true то значит мы зашли внутрь какого-то дела*/
      if (isCurrentCase) {
        const display =
          proceedingId === "documents" ? "documentCaseList" : "procList";

        let promisses = [
          () =>
            this.setState({
              ...initialState,
              activeCase,
              checked: id,
              display,
              error: "",
              init: true,
              loading: true,
              // history: [display]
            }),
          () => procDataSource.load(id),
          // () => myCaseDocDataSource.load(),
          // () => caseDocDataSource.load(),
        ];
        if (display === "documentCaseList") {
          myCaseDocDataSource.setFilter("case", id);
          myCaseDocDataSource.sort = { createdAt: "desc" };
          caseDocDataSource.setValue("params", { case_id: id });
          caseDocDataSource.sort = { docDate: "desc" };

          promisses.push(
            () => myCaseDocDataSource.load(),
            () => caseDocDataSource.load()
          );
        }
        let dataSource = procDataSource;

        /*Если procId значит мы внутри производства по делу*/
        if (procId) {
          const procDocDataSourceParams = { proceeding_id: procId };
          if (sessionStorage.getItem("claim_documentId")) {
            procDocDataSourceParams.documentId =
              sessionStorage.getItem("claim_documentId");
            sessionStorage.removeItem("claim_documentId");
          }
          procDocDataSource.setValue("params", procDocDataSourceParams);
          myProcDocDataSource.setFilter("proceeding", procId);

          dataSource = procDocDataSource;
          promisses = [
            ...promisses,
            async () => {
              const data = await procDocDataSource.load();
              const docID = procDocDataSourceParams.documentId;
              if (docID) {
                procDocDataSource.setValue("page", data.meta.pagination.page);
                delete procDocDataSourceParams.documentId;
                procDocDataSource.setValue("params", procDocDataSourceParams);
                if (!data.req.length) {
                  return procDocDataSource.load();
                }
                this.onViewDocument(docID)();
              }
              return data;
            },
            () => myProcDocDataSource.load(),
            () => {
              const { history, proceedingsList } = this.props;

              const activeProc = Array.isArray(proceedingsList)
                ? proceedingsList.find((proc) => procId === proc.id)
                : null;

              if (!activeProc) {
                const message = t("NEED_PROC_ACCESS");

                if (!historyReturn(history, { message })) {
                  return this.setState({
                    error: message,
                    init: false,
                    loading: false,
                  });
                }
              }

              return this.setState({
                display: "documentProcList",
                procChecked: procId,
                activeProc,
              });
            },
            () => {
              const viewDocId = searchParams.get("document");
              if (viewDocId) {
                this.onViewDocument(viewDocId)();
              }
            },
          ];
        }
        /*Если docId значит мы внутри производства по делу и открыт какой-то документ*/
        if (docId) {
          promisses = [
            ...promisses,
            () => requestDocument(docId),
            (viewDocument) =>
              this.setState({
                viewDocument,
                scanDocumentId: docId,
                display: "documentView",
              }),
          ];
        }
        promisses.push(() =>
          this.setState({
            dataSource,
            init: false,
            isMounted: true,
            loading: false,
          })
        );
        promiseChain(promisses);
      } else if (activeCase && activeCase.message) {
        const { history } = this.props;

        let { message } = activeCase;
        message =
          message === "User should have access to case."
            ? t("NEED_ACCESS")
            : t("UNKNOWN_ERROR", { error: message });

        if (!historyReturn(history, { message })) {
          this.setState({
            ...initialState,
            error: message,
            init: false,
            loading: false,
          });
        }
      } else {
        caseDataSource.load().then(() =>
          this.setState({
            history: [],
            init: false,
            isMounted: true,
            loading: false,
          })
        );
      }
    });
  });

  window.addEventListener(
    "beforeunload",
    () => replaceHistory(this.props.history),
    true
  );
}

export function setDefaultProps(callback) {
  const { page, templates } = this.props;
  const { error } = this.state;
  const actions = [clearCases(), myMemberRolesDataSource.load()];
  if (page === "cases") {
    actions.push(requestCasesCourts());
    actions.push(requestPermissionsList("?filter[isActive]=true"));
  } else {
    actions.push(requestJudgeCasesCourts());
  }
  if (!templates) actions.push(requestClaimTemplates());

  Promise.all(actions).then(() =>
    this.setState(
      {
        ...initialState,
        error,
      },
      () => {
        caseDataSource.reset();
        caseDataSource.load().then(
          () =>
            this.setState({
              history: [],
              init: false,
              isMounted: true,
              loading: false,
            }),
          () => callback
        );
      }
    )
  );
}
