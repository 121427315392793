import PropTypes from "prop-types";
import { compose } from "redux";
import { formatMoney } from "../helpers/formatMoney";
import { Link } from "react-router-dom";
import { fields } from "../data/executive.orders.table.fields";
import StatusChip from "../decorators/index";
import { humanDateTimeFormat } from "../../../helpers/humanDateFormat";
import humanDateFormat from "../../../helpers/humanDateFormat";
import { withRouter } from "react-router-dom";

import { Table } from "../../../components";
import { ReturnAndLocateInTableHOC } from "../../../components/ReturnAndLocateInTableHOC";

import { getRoleSimpleStr } from "../data/getRoleSimpleStr";
import { getRoleForArrStr } from "../data/getRoleForArrStr";

const ExecutiveDocsTable = ({
  classes,
  setId,
  t,
  changeCount,
  checked,
  dataSource,
  handleClick,
  list,
  onSelectAllClick,
  onCheckboxClick,
  createSortHandler,
  pagination,
  statuses,
  total,
  vpStatuses,
}) => {
  const getText = (item, key) => {
    const { myRole, vpStateId } = item;
    switch (key) {
      case "createdAt":
        return item[key] ? humanDateTimeFormat(item[key]) : "";
      case "docAcceptDate":
        return item[key] ? humanDateFormat(item[key]) : "";
      case "sumUah":
        return formatMoney(item[key]);
      case "vpStateId":
        const status =
          vpStatuses?.length && vpStatuses.find((_) => _?.id === vpStateId);
        return (
          <>
            {/* додала перевірку: якщо прийде статус, якого не має в довіднику - не показувати, щоб не ламалося */}
            {status ? (
              <StatusChip
                t={t}
                setId={setId}
                status={status?.id}
                statusName={status?.name ?? t("UNKNOWN_STATE")}
                tableChip={true}
                catStatuses={vpStatuses}
                statusColor={status.color}
                style={{ margin: "0" }}
              />
            ) : (
              ""
            )}
          </>
        );

      case "myRole":
        const array = myRole.split(",");
        if (!array?.length || !array?.[0]?.length) {
          return "";
        } else if (array?.length === 1) {
          return getRoleSimpleStr(myRole, t);
        } else {
          return getRoleForArrStr(array, t);
        }

      case "executiveId":
        return item[key] ? (
          <div
            title="Перейти до виконавчого документу"
            color="inherit"
            // onClick={() => history.push(`/executive/${item[key]}`)}
            style={{
              margin: "0 auto",
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className={classes.menuButton}
          >
            <Link to={`/executive/${item[key]}`}>
              <span style={{ textDecoration: "underline" }}>
                ВД
                {/* &#8230; */}
              </span>
            </Link>
          </div>
        ) : (
          ""
        );
      default:
        return item[key];
    }
  };

  return (
    <Table
      fields={fields}
      getText={getText}
      setId={setId}
      createSortHandler={createSortHandler}
      dataSource={dataSource}
      onCheckItem={handleClick}
      list={list}
      total={total}
      needFullData={true}
      pagination={pagination}
      changeCount={changeCount}
      t={t}
      onSelectAllClick={onSelectAllClick}
      onCheckboxClick={onCheckboxClick}
      checked={checked}
    />
  );
};

ExecutiveDocsTable.propTypes = {
  changeCount: PropTypes.func.isRequired,
  checked: PropTypes.array,
  classes: PropTypes.object.isRequired,
  dataSource: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  createSortHandler: PropTypes.func.isRequired,
  list: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  courts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  pagination: PropTypes.func.isRequired,
  returnObject: PropTypes.shape({
    getReturnObject: PropTypes.func.isRequired,
    setReturnObject: PropTypes.func.isRequired,
    removeReturnObject: PropTypes.func.isRequired,
    appendReturnObject: PropTypes.func.isRequired,
    popReturnObject: PropTypes.func.isRequired,
    hasReturnObject: PropTypes.func.isRequired,
    isForm: PropTypes.func.isRequired,
  }).isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ExecutiveDocsTable.defaultProps = {
  courts: null,
};

export default compose(
  withRouter,
  ReturnAndLocateInTableHOC
)(ExecutiveDocsTable);
