import { Component } from "react";

import { translate } from "react-translate";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import claimListStyles from "../../variables/styles/claimList";
import customInputStyle from "../../variables/styles/customInputStyle";
import { withRouter } from "react-router-dom";

import CaseLayout from "./components/CaseLayout";
import componentDidMount, { setDefaultProps } from "./decorators/init";
import {
  addNewBookmarkName,
  addToFavorites,
  deleteBookmark,
  deleteFromFavorite,
  dontAutoSaveState,
  saveBookmark,
  toggleMarkDialog,
  updateActiveBookmark,
  updateFromBookmark,
} from "./decorators/marks";
import {
  backToPrevDisplay,
  getSubTitle,
  nextDisplay,
  setDataSource,
} from "./decorators/changeDisplay";
import {
  closeScanDocument,
  handleDownload,
  loadCaseDoc,
} from "./decorators/documents";
import { changeOwnership, handleClaimCreate } from "./decorators/createClaim";
import {
  defaultProps,
  initialState,
  propTypes,
} from "./decorators/stateAndProps";
import {
  closeDialog,
  toggleCaseDialog,
  togglePermissionDialog,
  toggleTemplateDialog,
} from "./decorators/toggleDialogs";
import {
  changeCount,
  createSortHandler,
  onCheckboxClick,
  onCheckItem,
  onCheckProc,
  onProcCheckboxClick,
  onViewAttaches,
  onViewDocument,
  pagination,
  setActiveCase,
} from "./decorators/checkedAndPaggination";

import {
  expandedShare,
  handleSelectKey,
  handleShareClick,
  handleShareDelete,
  onCheckShare,
  setPdf,
  setPermission,
  toggleDialog,
  toggleSignModal,
} from "./decorators/addShare";
import { compose } from "redux";
import { ReturnAndLocateInTableHOC } from "../../components/ReturnAndLocateInTableHOC";
import { replaceHistory } from "./replaceHistory";

const styles = { ...customInputStyle, ...claimListStyles };

class CaseList extends Component {
  state = { ...initialState };
  // INIT
  // componentDidMount =
  //   this.props.role === "judge" &&
  //   this.props?.location?.state?.from.includes("/notifications")
  //     ? setRole("individual") && componentDidMount.bind(this)
  //     : componentDidMount.bind(this);
  componentDidMount = componentDidMount.bind(this);
  setDefaultProps = setDefaultProps.bind(this);

  // BOOKMARKS AND FAVORITES
  dontAutoSaveState = dontAutoSaveState;
  toggleMarkDialog = toggleMarkDialog;
  addNewBookmarkName = addNewBookmarkName;
  saveBookmark = saveBookmark;
  updateFromBookmark = updateFromBookmark;
  updateActiveBookmark = updateActiveBookmark;
  deleteBookmark = deleteBookmark;
  addToFavorites = addToFavorites;
  deleteFromFavorite = deleteFromFavorite;

  // CHANGE DISPLAY
  setDataSource = setDataSource;
  nextDisplay = nextDisplay;
  backToPrevDisplay = backToPrevDisplay;
  getSubTitle = getSubTitle;

  // DOCUMENTS
  handleDownload = handleDownload;
  loadCaseDoc = loadCaseDoc.bind(this);
  closeScanDocument = closeScanDocument;

  // CREATE CLAIM
  handleClaimCreate = handleClaimCreate;
  changeOwnership = changeOwnership;

  // TOGGLE DIALOGS
  togglePermissionDialog = togglePermissionDialog;
  closeDialog = closeDialog;
  toggleTemplateDialog = toggleTemplateDialog;
  toggleCaseDialog = toggleCaseDialog;

  // CHECKED AND PAGINATION
  createSortHandler = createSortHandler;
  onCheckboxClick = onCheckboxClick;
  onCheckItem = onCheckItem;
  onCheckProc = onCheckProc;
  onViewDocument = onViewDocument;
  onViewAttaches = onViewAttaches;
  pagination = pagination;
  changeCount = changeCount;
  onProcCheckboxClick = onProcCheckboxClick;
  setActiveCase = setActiveCase.bind(this);

  // SHARE/PERMISSIONS
  setPdf = setPdf.bind(this);
  toggleSignModal = toggleSignModal.bind(this);
  setPermission = setPermission.bind(this);
  handleSelectKey = handleSelectKey.bind(this);
  handleShareDelete = handleShareDelete.bind(this);
  onCheckShare = onCheckShare.bind(this);
  handleShareClick = handleShareClick.bind(this);
  toggleDialog = toggleDialog.bind(this);
  expandedShare = expandedShare.bind(this);

  closeAppealCourtErrorDialog = () => this.setState({ appealCourtError: "" });

  async componentDidUpdate(prevProps) {
    const {
      history,
      location: { pathname },
      role,
    } = this.props;

    if (window.amplitude && prevProps.location.pathname !== pathname) {
      if (role === "judge") {
        window.amplitude.track("Перегляд сторінки: Справи Судді");
        console.log("Amplitude: Відстеження перегляду сторінки /judge_cases");
      } else {
        window.amplitude.track("Перегляд сторінки: Справи");
        console.log("Amplitude: Відстеження перегляду сторінки /cases");
      }
    }
    if (prevProps.role !== role) {
      if (prevProps.role === "judge") {
        history.replace({ pathname: "/cases" });
      } else if (role === "judge") {
        history.replace({ pathname: "/judge_cases" });
      } else {
        this.setDefaultProps();
      }
    }
  }

  componentWillUnmount = () => {
    this.setState({ history: [], isMounted: false }, () =>
      window.removeEventListener(
        "beforeunload",
        () => replaceHistory(this.props.history),
        true
      )
    );
  };

  load = () =>
    this.setState({ blockScreen: true }, () => {
      this.state.dataSource
        .load(this.state.display === "procList" ? this.state.checked : "")
        .then(() =>
          this.setState({
            blockScreen: false,
            loading: false,
          })
        );
    });

  hideError = () => this.setState({ error: "" });

  render() {
    const { title, subTitle, myProcDocTitle, shareSubTitle, myCaseDocTitle } =
      this.getSubTitle();

    return (
      <CaseLayout
        {...this.props}
        {...this.state}
        hideError={this.hideError}
        myProcDocTitle={myProcDocTitle}
        title={title}
        subTitle={subTitle}
        toggleTemplateDialog={this.toggleTemplateDialog.bind(this)}
        handleClaimCreate={this.handleClaimCreate.bind(this)}
        onCheckItem={this.onCheckItem.bind(this)}
        createSortHandler={this.createSortHandler.bind(this)}
        onCheckboxClick={this.onCheckboxClick.bind(this)}
        toggleCaseDialog={this.toggleCaseDialog.bind(this)}
        onViewDocument={this.onViewDocument.bind(this)}
        onViewAttaches={this.onViewAttaches.bind(this)}
        handleDownload={this.handleDownload.bind(this)}
        pagination={this.pagination.bind(this)}
        permissions={this.state.activeCase?.permissions}
        changeCount={this.changeCount.bind(this)}
        onCheckProcItem={this.onCheckProc.bind(this)}
        backToPrevDisplay={this.backToPrevDisplay.bind(this)}
        loadData={this.load}
        saveBookmark={this.saveBookmark.bind(this)}
        updateActiveBookmark={this.updateActiveBookmark.bind(this)}
        toggleMarkDialog={this.toggleMarkDialog.bind(this)}
        addNewBookmarkName={this.addNewBookmarkName.bind(this)}
        deleteBookmark={this.deleteBookmark.bind(this)}
        dontAutoSaveState={this.dontAutoSaveState.bind(this)}
        togglePermissionDialog={this.togglePermissionDialog.bind(this)}
        closeDialog={this.closeDialog.bind(this)}
        changeOwnership={this.changeOwnership.bind(this)}
        addToFavorites={this.addToFavorites.bind(this)}
        deleteFromFavorite={this.deleteFromFavorite.bind(this)}
        closeScanDocument={this.closeScanDocument.bind(this)}
        closeSnackbar={this.closeSnackbar}
        toggleDialog={this.toggleDialog}
        setPdf={this.setPdf}
        toggleSignModal={this.toggleSignModal}
        handleSelectKey={this.handleSelectKey}
        handleShareDelete={this.handleShareDelete}
        onCheckShare={this.onCheckShare}
        handleShareClick={this.handleShareClick}
        shareSubTitle={shareSubTitle}
        onProcCheckboxClick={this.onProcCheckboxClick.bind(this)}
        expandedShare={this.expandedShare}
        myCaseDocTitle={myCaseDocTitle}
        closeAppealCourtErrorDialog={this.closeAppealCourtErrorDialog}
        noShowAttachPreview={this.state.noShowAttachPreview}
      />
    );
  }
}

CaseList.propTypes = propTypes;
CaseList.defaultProps = defaultProps;

const connectFun = ({
  claim: { templates },
  cases: {
    list,
    caseDocuments,
    procDocuments,
    myProcDocuments,
    activeCase,
    activeCase: { permissions: owners },
    procCourts,
    casesCourts,
    myCaseDocuments,
    savedDoc,
  },
  proceeding: { list: proceedingsList },
  permissions: {
    // list: owners,
    // listWithCasesShares: owners,
    itemShareList,
    loadingPermissions,
  },
  dictionary: {
    courts,
    memberRoles,
    judgeRoles,
    courtTypes,
    justiceTypes,
    casesStatuses: statuses,
    proceeding_statuses: procStatuses,
  },
  authorization: {
    role,
    info: { userId, isLegal },
  },
  usermeta: { userMetaData },
}) => ({
  statuses,
  list,
  proceedingsList,
  activeCase,
  owners,
  courts,
  memberRoles,
  judgeRoles,
  userId,
  caseDocuments,
  procDocuments,
  userMetaData,
  courtTypes,
  justiceTypes,
  myProcDocuments,
  procStatuses,
  isLegal,
  procCourts,
  casesCourts,
  itemShareList,
  myCaseDocuments,
  loadingPermissions,
  templates,
  savedDoc,
  role,
});

export default compose(
  connect(connectFun),
  withStyles(styles),
  translate("CaseList"),
  ReturnAndLocateInTableHOC,
  withRouter
)(CaseList);
