import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import { compose } from "redux";
import { connect } from "react-redux";
import Select from "../../../components/Select";

const mapRolesToOptions = (roles) => {
  return (roles || []).map((role) => ({
    id: role.id,
    name: role.description,
  }));
};

const MyCaseMemberStatus = ({
  t,
  role,
  onChange,
  myMemberRoles,
  checkedAdvocate,
}) => {
  let options = mapRolesToOptions(myMemberRoles);

  //из-за сложности реализации на беке для адвокатов добавляем этот пункт на клиенте
  if (checkedAdvocate && role !== "judge") {
    options.push({
      id: 113,
      name: "Представник",
    });
  }

  const [value, setValue] = useState();
  return (
    <Select
      multi={false}
      name="caseMemberRoleId"
      value={value}
      onChange={(e) => {
        const {
          target: { value },
        } = e;
        setValue(value);

        onChange && onChange(e);
      }}
      options={options}
      selectProps={{
        textFieldProps: { label: t("CASE_ROLE") },
      }}
    />
  );
};

const styles = {};

const mstp = ({
  authorization: { role: prevRole, roleInfo },
  cases: { myMemberRoles },
  personalData: { advocate },
}) => {
  return {
    role: roleInfo?.code || prevRole,
    myMemberRoles,
    checkedAdvocate: !!(advocate && advocate.isChecked),
  };
};

export default compose(withStyles(styles), connect(mstp))(MyCaseMemberStatus);
