// General
import { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../variables/styles/claimList";
import { checkError } from "./share-helpers/error.share";
import { shareState } from "./initial-share-data/general.share.data";
// Helpers
import humanDateFormat from "../../helpers/humanDateFormat";
import includeFieldsFromUserRole from "../../helpers/includeFieldsFromUserRole";
import blobToBase64 from "../../helpers/blobToBase64";
import promiseChain from "../../helpers/promiseChain";
import { signManifestVersions } from "../../services/eds/helpers/signManifest";
// Redux store
import { requestActiveCase, requestActiveProc } from "../../actions/cases";
import {
  // addPermission,
  deletePermission,
  editPermission,
  deleteEdrRecipe,
} from "../../actions/permissions";
import {
  commitDocument,
  requestSignData,
  signDocument,
} from "../../actions/claim";
import { checkLicense } from "../../actions/advocate";
import { checkCertificate } from "../../actions/user";
import { PermissionFilterStatus } from "../../reducers/permissionTypes";
// Components
import ShareLayout from "./components/ShareLayout";
import { PermissionType } from "./components/OrderDialog/types";
import { withAdvocate } from "../../components/withAdvocate";
// Data source
import DataSource from "../../helpers/dataSource";
// import { propTypes } from "../../components/CreateClaimDialog/init";
const dataSource = DataSource("api/permissions");

class Share extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...shareState,
      orderDialog: {
        // onClose: null,
        onClose: this.closeOrderDialog,
        readOnly: false,
        defaultMeta: null,
        createdAt: null,
        documentId: null,
      },
      path: null,
    };
  }

  getFormTitle = (title) => {
    if (title === "SHARE_CASE_LIST") {
      return title;
    }
    return this.props.isAdvocate ? "SHARE_LIST_AND_ORDERS" : title;
  };

  componentDidMount() {
    this.load();
    if (window.amplitude) {
      window.amplitude.track("Перегляд сторінки: Довіреності");
      console.log("Amplitude: Відстеження перегляду сторінки /share");
    }
  }

  load(clear = false) {
    let { changedShare } = this.state;
    let { targetType, loactionPath, targetId } = this.props;
    this.setState({ loading: true, blockScreen: true, subLoading: true });
    this.setActiveFilter();
    if (loactionPath === "case") {
      dataSource.filters.targetType = 2;
      dataSource.filters.targetType = targetType;
      dataSource.filters.targetId = targetId;
    }
    if (loactionPath === "provProceedings") {
      dataSource.filters.targetType = 1;
      dataSource.filters.targetType = targetType;
      dataSource.filters.targetId = targetId;
    }
    if (loactionPath === "share") {
      dataSource.clear();
    }
    dataSource.isNotDeleted = "off"; //filter 2531
    dataSource.filters.isActive = true;
    dataSource.load().then(() => {
      const { list } = this.props;
      if (clear) {
        changedShare = null;
      } else if (changedShare) {
        changedShare = list.find((item) => item.id === changedShare.id) || null;
      }
      // this.setChangedShare(changedShare);
      setTimeout(() => {
        this.setState({
          loading: false,
          blockScreen: false,
          subLoading: false,
        });
      }, 500);
    });
  }

  // -----------------------------------------------------------------------------------
  // Dialogs

  openOrderDialog = ({
    readOnly = false,
    meta = null,
    createdAt = null,
    cancelDate = null,
    documentId = null,
    id = "",
  } = {}) => {
    this.setState(
      (prevState) => ({
        orderDialog: {
          ...prevState.orderDialog,
          readOnly,
          defaultMeta: meta,
          createdAt,
          cancelDate,
          documentId,
          id,
        },
      }),
      this.toggleDialog("order")
    );
  };

  closeOrderDialog = (removeDisplay = true) =>
    this.setState((prevState) => ({
      display: removeDisplay ? "" : prevState.display,
      prevDisplay: "order",
      readOnly: false,
      defaultMeta: null,
      createdAt: null,
      documentId: null,
    }));

  setChangedShare = (changedShare) => {
    this.setState({
      loading: false,
      busy: true,
      blockScreen: false,
      subLoading: false,
    });
    changedShare =
      changedShare || (changedShare !== null ? this.state.changedShare : null);
    this.setState({
      error: "",
      busy: false,
      changedShare,
    });
  };

  toggleSignModal = () => {
    const {
      data: { documentId },
      showSignModal,
    } = this.state;
    this.setState({ busy: true }, () =>
      requestSignData(documentId).then((manifest) =>
        this.setState({
          busy: false,
          manifest,
          showSignModal: !showSignModal,
        })
      )
    );
  };

  toggleDialog =
    (displayName, load = false, data = {}) =>
    async () => {
      let { display } = this.state;
      this.setState({ prevDisplay: display });
      let { changedShare, shareSubTitle, link } = this.state;
      display = displayName === display ? "" : displayName;

      if (display === "addShare" && !data.documentId) {
        changedShare = null;
        shareSubTitle = "";
        link = "";
      } else if (display !== "addResharePermissions") {
        this.oneSelectToChanged(changedShare);
      }
      if (
        data.documentId &&
        changedShare &&
        data.documentId !== changedShare.documentId
      ) {
        changedShare.documentId = data.documentId;
      }
      this.setState(
        {
          display,
          data,
          changedShare,
          shareSubTitle,
          link,
        },
        () => {
          let newChangedShare = changedShare;
          if (load && changedShare) {
            const { list } = this.props;
            newChangedShare =
              list.find((item) => item.id === changedShare.id) || null;
          }
          this.setState({ changedShare: newChangedShare });
        }
      );
    };

  // -----------------------------------------------------------------------------------
  // Filters

  handleChangeFilter = (key, value) => {
    if (key !== "permissionStatus") {
      return;
    }

    dataSource.isNotDeleted = "off"; //filter 2531

    this.setState({ loading: true, blockScreen: true, subLoading: true });
    this.setActiveFilter(value);

    dataSource
      .load()
      .then(() =>
        this.setState({ loading: false, blockScreen: false, subLoading: false })
      );

    // this.resetPagination();
  };

  setActiveFilter = (value = PermissionFilterStatus.ACTIVE) => {
    if (value === 1) {
      dataSource.filters.isActive = true;
    }
    if (value === PermissionFilterStatus.ALL) {
      delete dataSource.filters.isActive;
    } else {
      dataSource.filters.isActive = !!value;
    }
  };

  // -----------------------------------------------------------------------------------
  // Lifecycle

  UNSAFE_componentWillReceiveProps({ list, user }) {
    // clear modal display
    if (this.props.list && !this.props.list.length && list && !!list.length) {
      this.setState({ display: "" });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.role !== this.props.user.role) {
      this.resetPagination();
    }
    if (prevProps.globalRole !== this.props.globalRole) {
      this.resetPagination();
    }
  }

  // -----------------------------------------------------------------------------------
  // Selectation

  oneSelectToChanged = (changedShare = null) => {
    const { checked } = this.state;
    const { list } = this.props;

    if (checked.length > 0) {
      changedShare =
        checked.length === 1
          ? list.find((item) => item.id === checked[0])
          : null;
    }

    this.setChangedShare(changedShare);
  };

  onCheckItem =
    (id, subTable = false) =>
    () => {
      const { checked } = this.state;

      if (checked.includes(id)) {
        checked.splice(checked.indexOf(id), 1);
        this.setState(
          { checked },
          () => !subTable && this.oneSelectToChanged()
        );
      } else {
        // this.setState({ checked: [] });
        this.setState(
          { checked: [id] },
          () => !subTable && this.oneSelectToChanged()
        );

        // checked.splice(0, checked.length).push(id);
        // checked.push(id);
      }
      // this.setState({ checked }, () => !subTable && this.oneSelectToChanged());
    };

  // -----------------------------------------------------------------------------------
  // Actions

  handleClick = (share) => async () => {
    const {
      isOwner,
      reSharePermission,
      targetType,
      targetId,
      meta,
      typeId,
      createdAt,
      documentId,
      id,
      cancelDate,
    } = share;

    const { t, jurisdiction_types } = this.props;
    if (typeId === PermissionType.Order) {
      return this.openOrderDialog({
        readOnly: true,
        meta,
        createdAt,
        documentId,
        id,
        cancelDate,
      });
    }

    let shareSubTitle = "";
    let link = "";
    this.setState({ busy: true });
    let display = isOwner ? "changePermission" : "viewOnly";
    if (!isOwner && reSharePermission) {
      display = "addReshare";
    }
    if (cancelDate) {
      display = "viewOnly";
    }
    if (targetType) {
      let proceeding = null;
      const metaData = typeof meta === "string" ? JSON.parse(meta) : meta || {};
      let { caseCreatedAt, caseId, caseNumber, procNumber } = metaData;
      // if (targetType === 1 && (!procNumber || !caseId)) {
      //   proceeding = await requestActiveProc(targetId);
      //   ({ number: procNumber, caseId } = proceeding);
      // }
      if (targetType !== 3 && (!caseNumber || !caseCreatedAt)) {
        const activeCase = await requestActiveCase(caseId || targetId);
        caseId = activeCase.id || caseId;
        ({ number: caseNumber, createdAt: caseCreatedAt } = activeCase || {});
      }
      if (targetType === 1) {
        if (!procNumber || !caseId) {
          proceeding = await requestActiveProc(targetId);
          ({ number: procNumber, caseId } = proceeding);
          if (!procNumber || !caseId) {
            proceeding = await requestActiveProc(targetId);
            ({ number: procNumber, caseId } = proceeding);
          }
        }
        link = caseId ? `/cases/case=${caseId}/proceeding=${targetId}` : "";
        shareSubTitle = t("SHARE_PROC_TITLE", {
          number: procNumber || t("NOT_SPECIFIED"),
          caseNumber: caseNumber || t("NOT_SPECIFIED"),
          createdAt: caseCreatedAt
            ? humanDateFormat(caseCreatedAt)
            : t("NOT_SPECIFIED"),
        });
      } else if (targetType === 2) {
        link = `/cases/case=${targetId}`;
        shareSubTitle = t("SHARE_CASE_TITLE", {
          caseNumber: caseNumber || t("NOT_SPECIFIED"),
          createdAt: caseCreatedAt
            ? humanDateFormat(caseCreatedAt)
            : t("NOT_SPECIFIED"),
        });
      } else if (targetType === 3) {
        const justiceTypeName = (
          jurisdiction_types.find((value) => {
            return value.id === parseInt(targetId);
          }) || {}
        ).nameGen;
        shareSubTitle = t("SHARE_JUSTICE_TITLE", {
          justiceTypeName: (
            justiceTypeName || t("NOT_SPECIFIED")
          ).toLowerCase(),
        });
      }
    }
    this.setState(
      {
        display,
        changedShare: share,
        shareSubTitle,
        link,
      },
      () => this.setChangedShare(share)
    );
  };

  handleAddOrderClick = () => {
    const { certbytype, raid, certnum, certbyid } = this.props.advocate;

    checkLicense({
      ...this.props.user,
      certbytype,
      raid,
      certnum,
      certbyid,
    }).then((response) => {
      if (!response || !response.advocate || response.occupation) {
        return this.showShareError(
          "Перевірку в ЄРАУ не пройдено. " +
            "Увійдіть в Мій профіль та перевірте правильність введення атрибутів свідоцтва адвоката"
        );
      }

      this.openOrderDialog();
    });
  };

  handleDelete = () => {
    const { checked } = this.state;
    this.setState({ busy: true });
    Promise.all(checked.map(deletePermission))
      .then((results) => {
        if (results.filter((result) => checkError(result)).length) {
          setTimeout(() => this.setState({ error: "" }), 3000);
        }
        return Promise.resolve();
      })
      .then(() => {
        this.setState(
          {
            busy: false,
            checked: [],
            display: "",
          }
          // () => this.load(true)
        );
      })
      .finally(() => {
        this.resetPagination();
      });
  };

  handleDelete_Edr_Recipe = async (id, deleteChildren = true) => {
    this.setState({ busy: true });
    deleteEdrRecipe(id, deleteChildren)
      .then((results) => {
        if (
          results?.length &&
          results.filter((result) => checkError(result)).length
        ) {
          setTimeout(() => this.setState({ error: "" }), 3000);
        }
        return Promise.resolve();
      })
      .then(() => {
        this.setState(
          {
            busy: false,
            checked: [],
            display: "",
          }
          // () => this.load(true)
        );
      })
      .finally(() => {
        this.resetPagination();
      });
    this.props.history?.go(0);
  };

  handleDelete_Edr_Recipe_Without_Refresh = async (
    id,
    deleteChildren = true
  ) => {
    this.setState({ busy: true, loading: true });
    deleteEdrRecipe(id, deleteChildren).then((results) => {
      if (
        results?.length &&
        results.filter((result) => checkError(result)).length
      ) {
        setTimeout(() => this.setState({ error: "" }), 3000);
      }
      return Promise.resolve();
    });
  };

  refreshAfterDeleteEdr = async () => {
    this.setState(
      {
        busy: false,
        checked: [],
        display: "",
        loading: false,
      }
      // () => this.load(true)
    );
    this.resetPagination();
  };

  createSortHandler = (property) => () => {
    let order = "desc";

    if (property in dataSource.sort && dataSource.sort[property] === "desc") {
      order = "asc";
    }

    dataSource.sort = { [property]: order };
  };

  backToPrevDisplay = () => this.setState({ display: this.state.prevDisplay });

  setPermission = (resp) => {
    if (checkError(resp)) {
      const { message: error } = resp;
      const { t } = this.props;
      const errorLabel =
        error &&
        error
          .match(/[\w\s]+/g)
          .join("")
          .split(" ")
          .join("_")
          .toUpperCase();
      const errorTranslate = t(errorLabel);
      const errorText = errorTranslate.includes("Share")
        ? t("UNKNOWN_ERROR", { error })
        : `${t("SERVER_ERROR")}: ${errorTranslate}`;
      this.setState({
        signError: errorText,
        showSignModal: false,
      });
    } else {
      this.setState(
        {
          display: "",
          changedShare: resp,
          error: "",
          signError: "",
          itIsUpdate: false,
        },
        () => this.load()
      );
    }
  };

  handleSelectKey = (encodedKey, signer, resetPrivateKey) => {
    const {
      data,
      data: { documentId },
      changedShare,
      itIsUpdate,
      manifest,
    } = this.state;
    promiseChain([
      () =>
        signer.execute("GetCertificate", encodedKey.issuer, encodedKey.serial),
      (certBuffer) => signer.execute("Base64Encode", certBuffer),
      (certBase64) => checkCertificate({ data: certBase64 }),
      async () => {
        let { signVersion, dataToSign } = manifest || {};

        if (Array.isArray(manifest)) {
          dataToSign = manifest;
          signVersion = 1;
        }

        const signManifestFunc = signManifestVersions[signVersion];
        if (!signManifestFunc) {
          throw new Error("Sign version unsupported");
        }

        return signManifestFunc(dataToSign, signer);
      },
      (signature) =>
        promiseChain([
          resetPrivateKey,
          () => signDocument(documentId, signature),
          (resp) => (checkError(resp) ? resp : commitDocument(documentId, {})),
          (resp) => {
            if (checkError(resp)) return resp;
            return itIsUpdate ? editPermission(changedShare.id, data) : null;
          },
          (resp) => this.setPermission(resp),
        ]),
    ]).catch((e) => this.setPermission(e));
  };

  closeSnackbar = () => this.setState({ signError: "" });
  closeShareError = () => this.setState({ shareError: "" });

  showShareError = (text) => {
    this.setState({ shareError: text }, () => {
      setTimeout(this.closeShareError, 4000);
    });
  };

  // -----------------------------------------------------------------------------------
  // Pagination

  pagination = (e, page) => {
    this.setState({ loading: true, blockScreen: true, subLoading: true });
    const { count } = dataSource;
    const start = page * count;
    dataSource.setValue("start", start);
    dataSource.setValue("page", page);
    dataSource
      .load()
      .then(() =>
        this.setState({ loading: false, blockScreen: false, subLoading: false })
      );
  };

  changeCount = ({ target: { value } }) => {
    this.setState({ loading: true, blockScreen: true, subLoading: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("count", value);
    dataSource.setValue("page", 0);
    dataSource
      .load()
      .then(() =>
        this.setState({ loading: false, blockScreen: false, subLoading: false })
      );
  };

  resetPagination = () => {
    this.setState({ loading: true, blockScreen: true, subLoading: true });
    dataSource.setValue("start", 0);
    dataSource.setValue("page", 0);
    dataSource.load().then(() => {
      setTimeout(() => {
        this.setState({
          loading: false,
          blockScreen: false,
          subLoading: false,
        });
      }, 500);
    });
  };

  // -----------------------------------------------------------------------------------
  // Pdf

  setPdf = (doc, data, documentId, itIsUpdate) => {
    this.setState({
      loading: true,
      itIsUpdate,
    });
    if (checkError(doc)) {
      this.setState({
        error: doc.message,
        pdf: null,
        doc: null,
        loading: false,
      });
    } else {
      blobToBase64(doc).then((pdf) =>
        this.setState(
          {
            error: "",
            pdf,
            doc,
            showSignModal: false,
          },
          () =>
            this.toggleDialog("signing", true, {
              ...data,
              documentId,
            })()
        )
      );
    }
  };

  render() {
    const { list, meta, targetType, loactionPath } = this.props;
    const ownerList = list?.length ? list.filter((item) => item.isOwner) : [];
    return (
      <ShareLayout
        {...this.props}
        {...this.state}
        title={this.getFormTitle(this.props.title)}
        orderButtonVisible={this.props.isAdvocate}
        onAddOrderClick={this.handleAddOrderClick}
        toggleDialog={this.toggleDialog}
        shareError={this.state.shareError}
        closeShareError={this.closeShareError}
        handleDelete={this.handleDelete}
        handleDelete_Edr_Recipe={this.handleDelete_Edr_Recipe}
        onSelectAllClick={this.onSelectAllClick}
        createSortHandler={this.createSortHandler}
        closeSnackbar={this.closeSnackbar}
        onCheckItem={this.onCheckItem}
        handleClick={this.handleClick}
        handleChangeFilter={this.handleChangeFilter}
        ownerList={ownerList}
        toggleSignModal={this.toggleSignModal}
        backToPrevDisplay={this.backToPrevDisplay}
        handleSelectKey={this.handleSelectKey}
        setPdf={this.setPdf}
        orderDialog={this.state.orderDialog}
        showShareError={this.showShareError}
        pagination={this.pagination}
        changeCount={this.changeCount}
        list={list}
        meta={meta}
        dataSource={dataSource}
        orderDialogFromPage={this.state.orderDialog}
        pdf={this.state.pdf}
        setShowError={this.setShowError}
        resetPagination={this.resetPagination}
        onAddEDRRecipeClick={this.onAddEDRRecipeClick}
        addEDRRecipeButtonVisible={this.props.user?.role === "legalEntityAgent"}
        noNeedAllCheckbox={true}
        handleDelete_Edr_Recipe_Without_Refresh={
          this.handleDelete_Edr_Recipe_Without_Refresh
        }
        refreshAfterDeleteEdr={this.refreshAfterDeleteEdr}
        targetType={targetType}
        loactionPath={loactionPath}
      />
    );
  }
}

Share.propTypes = {
  currentUserId: PropTypes.string.isRequired,
  list: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string.isRequired,
  isAdvocate: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  setId: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  targetType: PropTypes.number.isRequired,
  loactionPath: PropTypes.string.isRequired,
};

Share.defaultProps = {
  list: null,
  targetType: 0,
  loactionPath: "share",
};

const mapStateToProps = (state) => {
  let {
    permissions: { listWithCasesShares, list, meta },
    authorization: {
      role,
      info: { userId, middle_name, last_name, first_name },
      roleInfo,
    },
    dictionary: { jurisdiction_types },
  } = state;
  let globalRole = roleInfo?.code ?? role;
  const persimissions = includeFieldsFromUserRole("showCaseProcShare")
    ? listWithCasesShares
    : list;

  return {
    list: persimissions,
    meta: meta,
    currentUserId: userId,
    jurisdiction_types,
    user: {
      role,
      userId,
      surname: last_name,
      firstname: first_name,
      middlename: middle_name,
    },
    globalRole,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  withStyles(styles),
  translate("Share"),
  withAdvocate
)(Share);
