import React from "react";
import { withStyles } from "@material-ui/core";

// Dialog,
// DialogTitle,
// DialogContent,
// DialogActions,
// Typography,
// import { Button } from "../../../components";
import HelpIcon from "@material-ui/icons/Help";

const styles = (theme) => ({
  toolTipIcon: {
    cursor: "pointer",
    background: "#000",
    borderRadius: "100%",
    color: "#fff",
    fontSize: "15px",
    padding: "0",
  },
  // dialogContent: {
  //   // padding: theme.spacing(2),
  // },
  // statusTitle: {
  //   fontWeight: "bold",
  //   marginBottom: theme.spacing(1),
  // },
});

// const statuses = [
//   {
//     title: "До сплати",
//     description:
//       "Дозволяє боржнику здійснити онлайн сплату виконавчого документу.",
//   },
//   {
//     title: "До виконання. - Не може бути вручено боржнику в електронній формі",
//     description:
//       "Дозволяє Стягувачу подати виконавчий документ на виконання до АСВП. Статус також інформує про те, що ВД видано боржнику в електронній формі, але боржник не має зарежстрованого електронного кабінету.",
//   },
//   {
//     title: "Автоматична подача",
//     description:
//       "Стягувачем являється Державна судова адмніністрація (для судового зюдору) чи суд (для штрафу) та ВД буде подано до АСВП автоматично.",
//   },
// ];

class HelpStatusDialog extends React.Component {
  state = {
    open: false,
  };

  toggleDialog = () => {
    // this.setState((prevState) => ({ open: !prevState.open }));
    const url =
      "https://wiki.court.gov.ua/w/%D0%A1%D1%82%D0%B0%D1%82%D1%83%D1%81%D0%B8_%D0%B2%D0%B8%D0%BA%D0%BE%D0%BD%D0%B0%D0%B2%D1%87%D0%B8%D1%85_%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%96%D0%B2";

    // Відкриття посилання в новій вкладці
    window.open(url, "_blank");
  };

  render() {
    const { classes } = this.props;
    // const { open } = this.state;

    return (
      <>
        <HelpIcon
          className={classes.toolTipIcon}
          fontSize="small"
          onClick={this.toggleDialog}
        />
        {/* <Dialog open={open} onClose={this.toggleDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Пояснення статусів</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {statuses.map((status, index) => (
              <div key={index}>
                <Typography className={classes.statusTitle}>
                  {status.title}
                </Typography>
                <Typography>{status.description}</Typography>
                <br />
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleDialog}>Закрити</Button>
          </DialogActions>
        </Dialog> */}
      </>
    );
  }
}

export default withStyles(styles)(HelpStatusDialog);
