import React from "react";
import PropTypes from "prop-types";
// import {
//   ExpansionPanel,
//   ExpansionPanelDetails,
//   ExpansionPanelSummary,
//   Typography,
//   Icon,
//   DialogActions,
// } from "@material-ui/core";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import cx from "classnames";
// import { formatUserName } from "../../../helpers/userName";

import ShareDialogWrapper from "../../../components/ShareDialog/ShareDialogWrapper";
// import ShareDialog from "../../../components/ShareDialog";
// import { Button } from "../../../components";
import Share from "../../../pages/Share";

import { IconButton, Icon } from "@material-ui/core";

const CaseShareDialog = ({
  t,
  classes,
  userId,
  error,
  dataSource,
  createSortHandler,
  loading,
  dialog,
  toggleDialog,
  pdf,
  doc,
  setPdf,
  onCheckShare,
  handleShareClick,
  setId,

  changedShare,
  sharesChecked,
  targetType,
  targetId,
  shareSubTitle,
  currentShares,
  expandedShare,
  foundInMembers,
  activeCase,
  location,
}) => (
  <ShareDialogWrapper
    toggleDialog={toggleDialog}
    display={dialog}
    busy={loading}
    setId={setId}
    activeCase={activeCase}
  >
    {
      <IconButton
        onClick={toggleDialog("")}
        style={{
          color: "#fff",
          position: "absolute",
          width: "20px",
          height: "20px",
          right: "30px",
          top: "30px",
          zIndex: 20,
        }}
      >
        <Icon>close</Icon>
      </IconButton>
    }
    <Share
      title={targetType === 1 ? "SHARE_PROC_LIST" : "SHARE_CASE_LIST"}
      setId={() => {}}
      targetType={targetType}
      loactionPath={targetType === 1 ? "provProceedings" : "case"}
      targetId={targetId}
    />
  </ShareDialogWrapper>
);

CaseShareDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  error: PropTypes.string,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,

  createSortHandler: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  dialog: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  pdf: PropTypes.object,
  doc: PropTypes.object,
  setPdf: PropTypes.func.isRequired,
  onCheckShare: PropTypes.func.isRequired,
  handleShareClick: PropTypes.func.isRequired,
  changedShare: PropTypes.object,
  sharesChecked: PropTypes.array,
  targetType: PropTypes.number.isRequired,
  targetId: PropTypes.string.isRequired,
  shareSubTitle: PropTypes.string.isRequired,
  currentShares: PropTypes.array.isRequired,
  expandedShare: PropTypes.func.isRequired,
  foundInMembers: PropTypes.object,
};

CaseShareDialog.defaultProps = {
  error: "",
  pdf: null,
  doc: null,
  changedShare: null,
  sharesChecked: [],
  foundInMembers: null,
};

export default CaseShareDialog;
