import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-translate";

import { Chip } from "@material-ui/core";
import { compose } from "redux";
import { connect } from "react-redux";

const MyCaseMemberStatusChip = ({
  t,
  value,
  judgeRoles,
  checkRole,
  memberRoles,
  ...rest
}) => {
  const role = (
    checkRole === "judge" ? judgeRoles || [] : memberRoles || []
  ).find((item) => String(item.id) === String(value));
  return (
    <Chip label={`${t("CASE_ROLE")}: ${role && role.description}`} {...rest} />
  );
};

MyCaseMemberStatusChip.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

MyCaseMemberStatusChip.defaultProps = {
  value: "",
};

const mstp = ({
  authorization: { role: prevRole, roleInfo },
  dictionary: { memberRoles, judgeRoles },
}) => {
  return {
    checkRole: roleInfo?.code || prevRole,
    judgeRoles,
    memberRoles,
  };
};

export default compose(
  translate("ClaimList"),
  connect(mstp)
)(MyCaseMemberStatusChip);
