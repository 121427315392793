import { History } from "history";

export interface LocationState {
  from?: string;
  message?: string;
  [key: string]: any;
}

export const historyPush = (
  history: History<LocationState>,
  path: string,
  state?: LocationState
): void => {
  state = state || {};
  history.push({
    pathname: path,
    state: {
      ...state,
      from: history.location.pathname,
    },
  });
};

export const historyReturn = (
  history: History<LocationState>,
  state?: LocationState
): boolean | void => {
  const {
    location: { pathname, state: historyState },
  } = history;
  if (!historyState?.from) return false;

  const { from } = historyState;
  state = state || {};

  history.replace({
    pathname: from,
    state: {
      ...historyState,
      ...state,
      from: pathname,
    },
  });

  return true;
};
