// Ім'я екшену
const GET_ROLES = "GET_ROLES";

const initialState = [];

export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      return Array.isArray(action.payload) ? action.payload : state;

    default:
      return state;
  }
};

export const setRoles = (roles) => {
  return {
    type: GET_ROLES,
    payload: roles,
  };
};
