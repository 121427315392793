import { Fragment } from "react";
import PropTypes from "prop-types";
import { DialogActions, Dialog, DialogTitle } from "@material-ui/core";
import cx from "classnames";
import CreateClaimDialog from "../../../components/CreateClaimDialog";
import PermissionsDialog from "../../../components/PermissionsDialog";
import ShareSubscribeDialog from "../../../components/ShareDialog/ShareSubscribeDialog";
import ShareDeleteDialog from "../../../components/ShareDialog/ShareDeleteDialog";
import { Button } from "../../../components";

import CaseDialog from "./CaseDialog";
import MarkDialog from "./MarkDialog";
import CaseShareDialog from "./CaseShareDialog";

const getCourtId = (viewDocument, activeCase, page, activeProc) => {
  if (page !== "cases") {
    return undefined;
  }
  if (activeProc && activeProc.courtId) {
    return activeProc.courtId;
  }
  if (viewDocument && viewDocument.courtId) {
    return viewDocument.courtId;
  }
  return undefined;
};

const CaseDialogs = (props) => {
  const {
    closeSnackbar,
    t,
    classes,
    courts,
    memberRoles,
    judgeRoles,
    openCaseDialog,
    display,
    toggleCaseDialog,
    backToPrevDisplay,
    toggleMarkDialog,
    newBookmarkName,
    addNewBookmarkName,
    userMetaData,
    openMarkDialog,
    saveBookmark,
    updateActiveBookmark,
    activeMark,
    deleteBookmark,
    dontAutoSaveState,
    handleClaimCreate,
    toggleTemplateDialog,
    openCreateClaimDialog,
    openPermissionDialog,
    togglePermissionDialog,
    loading,
    ownership,
    changeOwnership,
    closeDialog,
    activeCase,
    page,
    dialog,
    toggleDialog,
    pdf,
    doc,
    showSignModal,
    signError,
    toggleSignModal,
    handleSelectKey,
    handleShareDelete,
    setId,
    filterOwners,
    appealCourt,
    foundInMembers,
    activeProc,
    causeActionTypes,
    closeAppealCourtErrorDialog,
    appealCourtError,
    viewDocument,
    currentList,
  } = props;

  const showSelectInctance =
    activeProc?.procCourtTypeId === 2 &&
    activeProc?.jurisdictionTypeId === 4 &&
    causeActionTypes?.includes(1);

  return (
    <Fragment>
      <CreateClaimDialog
        openCreateClaimDialog={openCreateClaimDialog}
        ownership={ownership}
        handleClaimCreate={handleClaimCreate}
        causeActionTypes={causeActionTypes}
        procCourtTypeId={(activeProc || {}).procCourtTypeId}
        jurisdictionTypeId={(activeProc || {}).jurisdictionTypeId}
        toggleTemplateDialog={toggleTemplateDialog}
        setId={(elmentName) => setId(`create-dialog-${elmentName}`)}
        docCategory={page === "cases" ? 6 : 8}
        courtId={getCourtId(viewDocument, activeCase, page, activeProc)}
        appealCourt={page === "cases" ? appealCourt : undefined}
      />
      {dialog && display !== "signing" && (
        <CaseShareDialog {...props} activeCase={activeCase} />
      )}
      {display === "signing" && (
        <ShareSubscribeDialog
          showSignModal={showSignModal}
          pdf={pdf}
          backToPrevDisplay={backToPrevDisplay}
          toggleSignModal={toggleSignModal}
          handleSelectKey={handleSelectKey}
          doc={doc}
          setId={setId}
          loading={loading}
          signError={signError}
          closeSnackbar={closeSnackbar}
        />
      )}
      {openPermissionDialog && (
        <PermissionsDialog
          open={openPermissionDialog}
          togglePermissionDialog={togglePermissionDialog(
            causeActionTypes,
            viewDocument
          )}
          busy={loading}
          owners={filterOwners}
          value={
            ownership.representativeClaimant === undefined
              ? {
                  ...ownership,
                  representativeClaimant: !foundInMembers,
                }
              : ownership
          }
          onChange={changeOwnership}
          closeDialog={closeDialog}
          disabledPersonally={!foundInMembers}
          setId={(elmentName) => setId(`permissions-dialog-${elmentName}`)}
          showSelectInctance={showSelectInctance}
        />
      )}
      {activeCase && (
        <CaseDialog
          t={t}
          openCaseDialog={openCaseDialog}
          toggleCaseDialog={toggleCaseDialog}
          currentList={currentList}
          currentCase={activeCase}
          courts={courts}
          memberRoles={memberRoles}
          judgeRoles={judgeRoles}
          classes={classes}
          setId={setId}
          display={display}
        />
      )}
      {appealCourtError && (
        <Dialog
          className={classes.dialog}
          open={!!appealCourtError}
          id={setId("court-not-found-dialog")}
          onClose={closeAppealCourtErrorDialog}
        >
          <DialogTitle
            className={cx(classes.dialogContentWrappers, classes.error)}
            id={setId("court-not-found-dialog")}
          >
            <span className={classes.error}>{appealCourtError}</span>
          </DialogTitle>
          <DialogActions
            className={cx(classes.actions, classes.dialogContentWrappers)}
            id={setId("dialog-actions")}
          >
            <Button
              color="white"
              onClick={closeAppealCourtErrorDialog}
              id={setId("close-button")}
              setId={(elementName) =>
                setId(`court-not-found-close-${elementName}`)
              }
            >
              {t("CLOSE")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <MarkDialog
        t={t}
        toggleMarkDialog={toggleMarkDialog}
        updateActiveBookmark={updateActiveBookmark}
        newBookmarkName={newBookmarkName}
        addNewBookmarkName={addNewBookmarkName}
        saveBookmark={saveBookmark}
        userMetaData={userMetaData}
        openMarkDialog={openMarkDialog}
        setId={(elementName) => setId(`mark-${elementName}`)}
        activeMark={activeMark}
        deleteBookmark={deleteBookmark}
        dontAutoSaveState={dontAutoSaveState}
      />
      <ShareDeleteDialog
        openDeleteDialog={dialog === "delete"}
        toggleDeleteDialog={toggleDialog("delete")}
        handleDelete={handleShareDelete}
        setId={setId}
      />
    </Fragment>
  );
};

CaseDialogs.propTypes = {
  userId: PropTypes.string.isRequired,
  error: PropTypes.string,
  backToPrevDisplay: PropTypes.func.isRequired,
  openCaseDialog: PropTypes.bool.isRequired,
  display: PropTypes.string.isRequired,
  setId: PropTypes.func.isRequired,
  dataSource: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,

  createSortHandler: PropTypes.func.isRequired,
  toggleCaseDialog: PropTypes.func.isRequired,

  // list:        PropTypes.array,
  courts: PropTypes.array.isRequired,
  memberRoles: PropTypes.array.isRequired,
  judgeRoles: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  toggleMarkDialog: PropTypes.func.isRequired,
  newBookmarkName: PropTypes.string.isRequired,
  addNewBookmarkName: PropTypes.func.isRequired,
  userMetaData: PropTypes.object,
  openMarkDialog: PropTypes.bool.isRequired,
  saveBookmark: PropTypes.func.isRequired,
  updateActiveBookmark: PropTypes.func.isRequired,
  activeMark: PropTypes.string.isRequired,
  deleteBookmark: PropTypes.func.isRequired,
  dontAutoSaveState: PropTypes.func.isRequired,
  handleClaimCreate: PropTypes.func.isRequired,
  toggleTemplateDialog: PropTypes.func.isRequired,
  openCreateClaimDialog: PropTypes.bool.isRequired,
  openPermissionDialog: PropTypes.bool.isRequired,
  togglePermissionDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  ownership: PropTypes.object.isRequired,
  changeOwnership: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  activeCase: PropTypes.object,
  closeSnackbar: PropTypes.func.isRequired,
  dialog: PropTypes.string.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  pdf: PropTypes.object,
  doc: PropTypes.object,
  showSignModal: PropTypes.bool.isRequired,
  signError: PropTypes.string.isRequired,
  setPdf: PropTypes.func.isRequired,
  toggleSignModal: PropTypes.func.isRequired,
  handleSelectKey: PropTypes.func.isRequired,
  handleShareDelete: PropTypes.func.isRequired,
  onCheckShare: PropTypes.func.isRequired,
  handleShareClick: PropTypes.func.isRequired,
  changedShare: PropTypes.object,
  sharesChecked: PropTypes.array,
  targetType: PropTypes.number.isRequired,
  targetId: PropTypes.string.isRequired,
  // showCreateButton: PropTypes.bool.isRequired,
  filterOwners: PropTypes.array.isRequired,
  shareSubTitle: PropTypes.string.isRequired,
  currentShares: PropTypes.array.isRequired,
  currentList: PropTypes.array.isRequired,
  expandedShare: PropTypes.func.isRequired,
  appealCourt: PropTypes.object,
  foundInMembers: PropTypes.object,
  activeProc: PropTypes.object,
  causeActionTypes: PropTypes.array,
  closeAppealCourtErrorDialog: PropTypes.func.isRequired,
  viewDocument: PropTypes.object,
  appealCourtError: PropTypes.string.isRequired,
};

CaseDialogs.defaultProps = {
  error: "",
  userMetaData: {},
  activeCase: null,
  // list: null,
  pdf: null,
  doc: null,
  changedShare: null,
  sharesChecked: [],
  appealCourt: null,
  foundInMembers: null,
  activeProc: {},
  causeActionTypes: [],
  viewDocument: null,
  currentList: [],
  currentShares: [],
};

export default CaseDialogs;
